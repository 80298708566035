import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Modal from 'react-bootstrap/Modal';
import { getOrderItemPipelineStat } from '../../../helpers/tracktrace/pipelineHelper';
import TrackTraceContainer from '../../tracktrace/container';
import TrackTraceViewModes from '../../tracktrace/view-modes';
import { useDispatch } from 'react-redux';
import { actions } from '@naadi/framework';
import { GROUPS } from '../../../helpers/order/progressHelper';

export const ProgressOperationItems = ({
  onCloseCb,
  operationItems,
  consolidationTypes,
  itemTypes,
}) => {
  const dispatch = useDispatch();
  const fetching = useRef(false);
  const [pendingItems, setPendingItems] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const populateOrderItems = useCallback(
    async (orderStat, operation) => {
      if (fetching.current === true) {
        return;
      }
      try {
        dispatch(actions.busy.add('PROGRESS_ORDER_ITEMS'));
        fetching.current = true;
        const pendingItemFilter = {
          label: orderStat.reference,
          pipeline_group: 'ITEMS',
          filter: {
            stat_type: 'total_pending',
          },
        };
        let group =
          orderStat.group2 &&
          orderStat.group2 !== 'NONE' &&
          orderStat.group2 !== 'undefined' &&
          orderStat.level <= 0
            ? orderStat.group2
            : orderStat.group;
        if (
          group &&
          group === orderStat.group2 &&
          orderStat.group2 !== 'NONE' &&
          orderStat.group2 !== ''
        ) {
          switch (group) {
            case GROUPS.ORDER_EXTERNAL_REFERENCE.key:
              if (!orderStat.ext_code || orderStat.ext_code === '') {
                group = orderStat.group;
              }
              break;
            case GROUPS.SECONDARY_CUSTOMER.key:
              if (
                !orderStat.secondary_customer ||
                orderStat.secondary_customer === ''
              ) {
                group = orderStat.group;
              }
              break;
            default:
          }
        }
        switch (group) {
          case 'ORDER':
            pendingItemFilter.filter.order_id = orderStat.order.uuid;
            break;
          case 'PARENT_ORDER':
            pendingItemFilter.filter.parent_order_id = orderStat.order.uuid;
            break;
          case 'CUSTOMER':
            pendingItemFilter.filter.entity_id = orderStat.order.entity_id;
            break;
          case 'ORDER_EXTERNAL_REFERENCE':
            pendingItemFilter.filter.ext_code = orderStat.order.ext_code;
            break;
          case 'SECONDARY_CUSTOMER':
            pendingItemFilter.filter.secondary_customer =
              orderStat.order.secondary_customer;
            break;
          default:
            console.log('Not Supported Yet');
            return;
        }
        const orderItemStatsResp = await getOrderItemPipelineStat(
          'ITEMS',
          [operation.operation_code],
          pendingItemFilter,
          itemTypes || [],
        );
        if (orderItemStatsResp.status !== 200) {
          console.log('Unable to Fetcht he Order Items');
          return;
        }
        const orderItems = orderItemStatsResp.payload;
        setPendingItems(orderItems);
        setOrderItems(orderItems.map(val => val.order_item));
      } finally {
        dispatch(actions.busy.remove('PROGRESS_ORDER_ITEMS'));
        fetching.current = false;
      }
    },
    [dispatch, setPendingItems, setOrderItems, itemTypes],
  );
  useEffect(() => {
    const orderStat = operationItems.order_stat;
    const operation = operationItems.operation;
    populateOrderItems(orderStat, operation);
  }, [operationItems, populateOrderItems]);
  return (
    <Fragment>
      <Modal
        centered={true}
        size={'xl'}
        show={true}
        onHide={onCloseCb}
        dialogClassName='track-trace-dialog'
      >
        <Modal.Header
          className='px-32'
          style={{ borderBottom: '0px' }}
          closeButton
        >
          {operationItems.order_stat.reference} -{' '}
          {operationItems.operation.name} - Pending Items
        </Modal.Header>
        <Modal.Body className='px-16 py-16'>
          <div>
            <TrackTraceViewModes pipelineMode={true} orderItems={orderItems} />
            <TrackTraceContainer
              scannedItems={pendingItems}
              showOrderItemDetailCB={() => {}}
              setScanStateCb={() => {}}
              appendScannedItemsCb={() => {}}
              onScanWrapper={() => {}}
              syncCb={() => {}}
              pipelineMode={true}
            />
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
