import { createAction, createReducer } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import Update from 'immutability-helper';
export function actionTypeName(context, type, verb) {
  return `${context}_${type}_${verb}`;
}

const getActionName = (type, verb) =>
  actionTypeName('TRACKTRACE_STATELESS_STATE', type, verb);
export const TYPES = {
  LOADING: 'LOADING',
  CHALLENGE: 'CHALLENGE',
  PROCESSED_ORDER_ITEM: 'PROCESSED_ORDER_ITEM',
};
const busyAdd = createAction(getActionName(TYPES.LOADING, 'ADD'));
const busyRemove = createAction(getActionName(TYPES.LOADING, 'REMOVE'));
export const addChallenge = createAction(getActionName(TYPES.CHALLENGE, 'ADD'));
export const removeChallenge = createAction(
  getActionName(TYPES.CHALLENGE, 'REMOVE'),
);
export const setProcessedOrderItems = createAction(
  getActionName(TYPES.PROCESSED_ORDER_ITEM, 'SET'),
);

export const appendProcessedOrderItem = createAction(
  getActionName(TYPES.PROCESSED_ORDER_ITEM, 'APPEND'),
);

export const trackTraceBusy = {
  add: busyAdd,
  remove: busyRemove,
};
export const DEFAULT = {
  busy: [],
  challenge: {},
  processed_order_items: [],
};

const reducer = createReducer(DEFAULT, builder => {
  builder.addCase(busyAdd, (state, { payload }) => {
    const busy = cloneDeep(state.busy || []);
    busy.push(payload);
    return Update(state, {
      busy: { $set: busy },
    });
  });
  builder.addCase(busyRemove, (state, { payload }) => {
    const busy = cloneDeep(state.busy || []);
    const index = busy.indexOf(payload);
    if (index >= 0) {
      busy.splice(index, 1);
    }
    return Update(state, {
      busy: { $set: busy },
    });
  });
  builder.addCase(addChallenge, (state, { payload }) => {
    return Update(state, {
      challenge: { $set: payload },
    });
  });
  builder.addCase(removeChallenge, state => {
    return Update(state, {
      challenge: { $set: {} },
    });
  });
  builder.addCase(appendProcessedOrderItem, (state, { payload }) => {
    let orderItems = state.processed_order_items || [];
    if (payload && payload.length > 0) {
      payload.forEach(val => {
        if (orderItems.indexOf(val) < 0) {
          orderItems = [...orderItems, val];
        }
      });
    }

    return Update(state, {
      processed_order_items: { $set: orderItems },
    });
  });
  builder.addCase(setProcessedOrderItems, (state, { payload }) => {
    return Update(state, {
      processed_order_items: { $set: payload },
    });
  });
});

export default reducer;
