import moment from 'moment/moment';
import { getOrderProgress } from '../../services/order-operation-stat';
import { getAllOperations } from '../../services/items';
import { getOrgConfig, getOrgUserConfig } from '../../services/org';
import { ResponseCodes } from '@naadi/framework';
import * as XLSX from 'xlsx';
import { refreshOrderOperationStat } from '../../services/order';

export const GROUPS = {
  NONE: {
    key: 'NONE',
    label: 'Select',
  },
  PARENT_ORDER: {
    key: 'PARENT_ORDER',
    label: 'Parent Order',
  },
  ORDER: {
    key: 'ORDER',
    label: 'Order',
  },
  CUSTOMER: {
    key: 'CUSTOMER',
    label: 'Customer',
  },
  ORDER_EXTERNAL_REFERENCE: {
    key: 'ORDER_EXTERNAL_REFERENCE',
    label: 'External Reference',
  },
  SECONDARY_CUSTOMER: {
    key: 'SECONDARY_CUSTOMER',
    label: 'Secondary Customer',
  },
};

export const getRecentOrdersList = async (
  group,
  group2,
  group3,
  itemType,
  term,
) => {
  const from = moment().subtract(3, 'months').toDate();
  const resp = await getOrderProgress(
    group,
    group2,
    group3,
    from,
    term,
    false,
    itemType,
    300,
  );
  if (resp.status === 200) {
    const payload = resp.payload;
    payload.forEach(orderStat => {
      orderStat.reference = '';
      orderStat.reference2 = '';
      if (orderStat.group === 'ORDER' || orderStat.group === 'PARENT_ORDER') {
        orderStat.reference = orderStat.order.ref_code;
      } else if (orderStat.group === 'ORDER_EXTERNAL_REFERENCE') {
        orderStat.reference = orderStat?.order?.ext_code;
      } else if (orderStat.group === 'CUSTOMER') {
        orderStat.reference = orderStat?.order?.entity?.name;
      } else if (orderStat.group === 'SECONDARY_CUSTOMER') {
        orderStat.reference = orderStat?.order?.secondary_customer;
      } else if (orderStat.group === 'ORDER_ITEM') {
        orderStat.reference = orderStat?.label;
      }
      if (orderStat.group2 === 'ORDER' || orderStat.group2 === 'PARENT_ORDER') {
        orderStat.reference2 = orderStat.order.ref_code;
      } else if (orderStat.group2 === 'ORDER_EXTERNAL_REFERENCE') {
        orderStat.reference2 = orderStat?.order?.ext_code;
      } else if (orderStat.group2 === 'CUSTOMER') {
        orderStat.reference2 = orderStat?.order?.entity?.name;
      } else if (orderStat.group2 === 'SECONDARY_CUSTOMER') {
        orderStat.reference2 = orderStat?.order?.secondary_customer;
      } else if (orderStat.group2 === 'ORDER_ITEM') {
        orderStat.reference2 = orderStat?.label;
      }
    });
  }
  return new Promise((resolve, reject) => {
    resolve(resp);
  });
};
/* eslint-disable sonarjs/cognitive-complexity */
export const getOperationsInitData = async () => {
  const allOperationsResp = await getAllOperations();
  if (allOperationsResp.status !== 200) {
    return null;
  }
  const _operations = allOperationsResp.payload;
  const orgUserConfigResp = await getOrgUserConfig(
    'USER_CONFIG',
    'STATION_SEQUENCE',
  );
  const operationSequence = [];
  if (orgUserConfigResp.status === 200) {
    const stationSequence = orgUserConfigResp.payload;
    if (stationSequence.val && stationSequence.val.length > 0) {
      stationSequence.val.forEach(val => {
        operationSequence.push(val);
      });
    }
  } else if (
    orgUserConfigResp.status === ResponseCodes.RECORD_NOT_FOUND_EXCEPTION
  ) {
    const orgConfigResp = await getOrgConfig('STATION_SEQUENCE');
    if (orgConfigResp.status === 200) {
      const stationSequence = orgConfigResp.payload;
      if (
        stationSequence.value &&
        stationSequence.value.val &&
        stationSequence.value.val.length > 0
      ) {
        stationSequence.value.val.forEach(val => {
          operationSequence.push(val);
        });
      }
    } else if (
      orgConfigResp.status !== ResponseCodes.RECORD_NOT_FOUND_EXCEPTION &&
      orgConfigResp.status !== 200
    ) {
      return null;
    }
  } else if (orgUserConfigResp.status !== 200) {
    return null;
  }

  return _operations
    .map(operation => {
      if (
        operationSequence.length > 0 &&
        operationSequence.indexOf(operation.operation_code) === -1
      ) {
        operation.visible = false;
      } else {
        operation.visible = true;
      }
      return operation;
    })
    .sort((a, b) => {
      let aIndex = operationSequence.indexOf(a.operation_code);
      let bIndex = operationSequence.indexOf(b.operation_code);
      aIndex = aIndex === -1 ? operationSequence.length : aIndex;
      bIndex = bIndex === -1 ? operationSequence.length : bIndex;
      return aIndex - bIndex;
    });
};
/* eslint-enable sonarjs/cognitive-complexity */

export const getProgressHeaderName = (group, defaultName) => {
  switch (group) {
    case GROUPS.ORDER.key:
      return GROUPS.ORDER.label;
    case GROUPS.PARENT_ORDER.key:
      return GROUPS.PARENT_ORDER.label;
    case GROUPS.ORDER_EXTERNAL_REFERENCE.key:
      return GROUPS.ORDER_EXTERNAL_REFERENCE.label;
    case GROUPS.SECONDARY_CUSTOMER.key:
      return GROUPS.SECONDARY_CUSTOMER.label;
    case GROUPS.CUSTOMER.key:
      return GROUPS.CUSTOMER.label;
    default:
      return defaultName;
  }
};

export const getProgressRowGroupVal = (group, orderStat) => {
  switch (group) {
    case GROUPS.ORDER.key:
    case GROUPS.PARENT_ORDER.key:
      return orderStat.order.ref_code;
    case GROUPS.ORDER_EXTERNAL_REFERENCE.key:
      return orderStat?.order?.ext_code;
    case GROUPS.SECONDARY_CUSTOMER.key:
      return orderStat?.order?.secondary_customer;
    case GROUPS.CUSTOMER.key:
      return orderStat?.order?.entity?.name;
    case 'ORDER_ITEM':
      return orderStat?.label;
    default:
      return '';
  }
};

export const getProgressRowRefreshGroup = (group1, group2, group3) => {
  if (
    group1 === GROUPS.PARENT_ORDER.key ||
    group2 === GROUPS.PARENT_ORDER.key ||
    group3 === GROUPS.PARENT_ORDER.key
  ) {
    return GROUPS.PARENT_ORDER.key;
  } else if (group1 === GROUPS.SECONDARY_CUSTOMER.key) {
    return GROUPS.SECONDARY_CUSTOMER.key;
  } else if (group1 === GROUPS.ORDER_EXTERNAL_REFERENCE.key) {
    return GROUPS.SECONDARY_CUSTOMER.key;
  } else if (group2 === GROUPS.SECONDARY_CUSTOMER.key) {
    return GROUPS.SECONDARY_CUSTOMER.key;
  } else if (group2 === GROUPS.ORDER_EXTERNAL_REFERENCE.key) {
    return GROUPS.ORDER_EXTERNAL_REFERENCE.key;
  } else if (group3 === GROUPS.SECONDARY_CUSTOMER.key) {
    return GROUPS.SECONDARY_CUSTOMER.key;
  } else if (group3 === GROUPS.ORDER_EXTERNAL_REFERENCE.key) {
    return GROUPS.ORDER_EXTERNAL_REFERENCE.key;
  } else if (
    group1 === GROUPS.ORDER.key ||
    group2 === GROUPS.ORDER.key ||
    group3 === GROUPS.ORDER.key
  ) {
    return GROUPS.ORDER.key;
  } else if (
    group1 === GROUPS.CUSTOMER.key ||
    group2 === GROUPS.CUSTOMER.key ||
    group3 === GROUPS.CUSTOMER.key
  ) {
    return GROUPS.CUSTOMER.key;
  } else {
    return '';
  }
};
export const refreshOrderProgressPipeline = async progress => {
  const orderIds = [];
  if (progress && progress.order && progress.order.uuid) {
    orderIds.push(progress.order.uuid);
  }
  if (
    progress &&
    progress.order &&
    progress.order &&
    progress.order.child_orders
  ) {
    progress.order.child_orders.forEach(val => {
      orderIds.push(val.uuid);
    });
  }
  if (orderIds.length > 0) {
    for (let i = 0; i < orderIds.length; i++) {
      const operationStatResult = await refreshOrderOperationStat(
        orderIds[`${i}`],
      );
      if (operationStatResult.status !== 200) {
        return {
          err: operationStatResult.err
            ? operationStatResult.err
            : 'Unable to refresh',
          success: false,
        };
      }
    }
  }
  return {
    success: true,
  };
};
export const downloadProgress = ({
  group,
  group2,
  group3,
  progressList,
  operations,
}) => {
  const visibleOperations = operations.filter(op => op.visible === true);
  const header1 = getProgressHeaderName(group);
  const header2 = getProgressHeaderName(group2);
  const header3 = getProgressHeaderName(group3);
  const operationHeaders = [];
  visibleOperations.forEach(operation => {
    const pending = 'Pending Qty ' + operation.name;
    const completed = 'Completed Qty ' + operation.name;
    const total = 'Qty ' + operation.name;
    operationHeaders.push(pending);
    operationHeaders.push(completed);
    operationHeaders.push(total);
  });
  const headerCols = [
    header1,
    header2,
    header3,
    'Completion %',
    ...operationHeaders,
  ];
  const rows = [];
  progressList.forEach(orderStat => {
    const ref1 = getProgressRowGroupVal(orderStat.group, orderStat);
    const ref2 = getProgressRowGroupVal(orderStat.group2, orderStat);
    const ref3 = getProgressRowGroupVal(orderStat.group3, orderStat);
    const operationValues = [];
    visibleOperations.forEach(operation => {
      const stat =
        orderStat.operation_stats[`${operation.operation_code}`] || {};
      const totalPipeline = stat.total_pipeline || 0;
      const totalPending = stat.total_pending_qty || 0;
      const totalCompleted = totalPipeline - totalPending;
      const _totalPending = Math.round(totalPending * 10) / 10.0;
      const _totalCompleted = Math.round(totalCompleted * 10) / 10.0;
      const _totalPipeline = Math.round(totalPipeline * 10) / 10.0;
      operationValues.push(_totalPending);
      operationValues.push(_totalCompleted);
      operationValues.push(_totalPipeline);
    });
    const row = [ref1, ref2, ref3, orderStat.completion, ...operationValues];
    rows.push(row);
  });
  const worksheet = XLSX.utils.aoa_to_sheet([headerCols], {
    type: 'string',
  });
  XLSX.utils.sheet_add_aoa(worksheet, rows, {
    origin: 'A2',
  });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Progress');

  // eslint-disable-next-line
  XLSX.writeFile(workbook, 'Progress.xlsx');
};
