import React, { Fragment, useEffect, useState } from 'react';
import { ProgressOperationItems } from './progress-operation-items';
import { TrackTraceItemFieldSelectedIcon } from '../../../assets/icons/icon-tracktrace';
import {
  getProgressHeaderName,
  getProgressRowGroupVal,
  getProgressRowRefreshGroup,
  refreshOrderProgressPipeline,
} from '../../../helpers/order/progressHelper';
import { RefreshIcon } from '../../../assets/icons/nav-icons';
import {
  toastError,
  toastSuccess,
} from '../../../helpers/packing/packingHelper';
import { useDispatch } from 'react-redux';
import { actions } from '@naadi/framework';

const ProgressHeader = ({
  group,
  group2,
  group3,
  operations,
  operationWidth,
  referenceWidth,
}) => {
  const [header1, setHeader1] = useState('Reference');
  const [header2, setHeader2] = useState('Reference2');
  const [header3, setHeader3] = useState('Reference3');
  useEffect(() => {
    setHeader1(getProgressHeaderName(group, 'Reference'));
  }, [group, setHeader1]);
  useEffect(() => {
    setHeader2(getProgressHeaderName(group2, 'Reference 2'));
  }, [group2, setHeader2]);
  useEffect(() => {
    setHeader3(getProgressHeaderName(group3, 'Reference 3'));
  }, [group3, setHeader3]);
  return (
    <Fragment>
      <div
        className='rubik-font d-flex bg-white pt-2 pb-3'
        style={{
          zIndex: '1',
          position: 'sticky',
          top: '0px',
          borderBottom: '1px solid #027aff',
          fontWeight: 'bold',
        }}
      >
        <div
          className='bg-white'
          style={{
            width: referenceWidth + 'px',
            position: 'sticky',
            left: '0px',
          }}
        >
          {header1}
        </div>
        {group2 !== 'NONE' && group2 !== '' && (
          <div
            className='bg-white'
            style={{
              width: referenceWidth + 'px',
            }}
          >
            {header2}
          </div>
        )}
        {group3 !== 'NONE' && group3 !== '' && (
          <div
            className='bg-white'
            style={{
              width: referenceWidth + 'px',
            }}
          >
            {header3}
          </div>
        )}
        <div
          className=' d-flex justify-content-end'
          style={{
            width: operationWidth + 'px',
          }}
        >
          Completion %
        </div>
        {operations.map(operation => {
          return (
            <div
              key={operation.operation_code}
              style={{ width: operationWidth + 'px', paddingLeft: '20px' }}
              className='d-flex justify-content-end'
            >
              {operation.name}
            </div>
          );
        })}
        <div
          className=' d-flex justify-content-end'
          style={{
            width: '60px',
          }}
        ></div>
      </div>
    </Fragment>
  );
};
const ProgressLine = ({
  group,
  group2,
  group3,
  order_progress_completed,
  onDetail,
  operations,
  orderStat,
  operationWidth,
  referenceWidth,
  setOperationItems,
  onRefresh,
}) => {
  const dispatch = useDispatch();
  const [reference, setReference] = useState('');
  const [reference2, setReference2] = useState('');
  const [reference3, setReference3] = useState('');
  const [refreshGroup, setRefreshGroup] = useState('');
  useEffect(() => {
    setReference(getProgressRowGroupVal(orderStat.group, orderStat));
    setReference2(getProgressRowGroupVal(orderStat.group2, orderStat));
    setReference3(getProgressRowGroupVal(orderStat.group3, orderStat));
    setRefreshGroup(
      getProgressRowRefreshGroup(
        orderStat.group,
        orderStat.group2,
        orderStat.group3,
      ),
    );
  }, [orderStat, setReference, setReference2, setReference3, setRefreshGroup]);
  return (
    <Fragment>
      <div className='d-flex bg-white pt-1 pb-1'>
        <div
          className='bg-white rubik-font bold-link cursor-pointer d-flex align-items-center'
          style={{
            width: referenceWidth + 'px',
            position: 'sticky',
            left: '0px',
            paddingLeft: orderStat.level * 24 + 'px',
          }}
          onClick={() => {
            if (onDetail) {
              onDetail(orderStat);
            }
          }}
        >
          {orderStat.pinned && (
            <div className='p-0'>
              <TrackTraceItemFieldSelectedIcon
                selected={orderStat.pinned}
                small={true}
              />
            </div>
          )}
          <div className='ps-1'>{reference}</div>
        </div>
        {group2 !== '' && group2 !== 'NONE' && (
          <div
            className='rubik-font d-flex align-items-center'
            style={{
              width: referenceWidth + 'px',
            }}
          >
            <div className='ps-1'>{reference2}</div>
          </div>
        )}
        {group3 !== '' && group3 !== 'NONE' && (
          <div
            className='rubik-font d-flex align-items-center'
            style={{
              width: referenceWidth + 'px',
            }}
          >
            <div className='ps-1'>{reference3}</div>
          </div>
        )}
        <div
          className='rubik-font d-flex align-items-center  justify-content-end'
          style={{
            width: operationWidth + 'px',
          }}
        >
          <div
            className={`ps-1 ${
              orderStat.completion >= 100
                ? 'success'
                : orderStat.completion > 0
                ? 'warning'
                : ''
            }`}
          >
            {orderStat.completion}
          </div>
        </div>
        {operations.map(operation => {
          const stat =
            orderStat.operation_stats[`${operation.operation_code}`] || {};
          const totalPipeline = stat.total_pipeline || 0;
          const totalPending = stat.total_pending_qty || 0;
          const totalCompleted = totalPipeline - totalPending;
          let displayClass = '';
          if (totalCompleted >= totalPipeline && totalCompleted > 0) {
            displayClass = 'success';
          } else if (totalCompleted > 0) {
            displayClass = 'warning';
          }
          return (
            <div
              key={operation.operation_code + '-' + orderStat.key}
              style={{ width: operationWidth + 'px' }}
              className={`d-flex align-items-center justify-content-end ${displayClass}`}
              onClick={() => {
                setOperationItems({
                  show: true,
                  operation: operation,
                  order_stat: orderStat,
                });
              }}
            >
              {Math.round(
                (order_progress_completed ? totalCompleted : totalPending) * 10,
              ) / 10.0}{' '}
              / {Math.round(totalPipeline * 10) / 10.0}
            </div>
          );
        })}
        <div
          className='rubik-font d-flex align-items-center  justify-content-end'
          style={{
            width: '60px',
          }}
        >
          {refreshGroup && refreshGroup !== '' && (
            <div
              className='cursor-pointer'
              onClick={async () => {
                try {
                  dispatch(actions.busy.add('REFRESH_PIPELINE'));
                  const stat = await refreshOrderProgressPipeline(orderStat);
                  if (stat.success) {
                    toastSuccess('Order Stat Refreshed');
                    onRefresh();
                  } else {
                    toastError(
                      stat.err && stat.err.err
                        ? stat.err.err
                        : stat.err
                        ? stat.err
                        : 'Unable to refresh',
                    );
                  }
                } finally {
                  dispatch(actions.busy.remove('REFRESH_PIPELINE'));
                }
              }}
            >
              <RefreshIcon />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export const ProgressContainer = ({
  group,
  group2,
  group3,
  order_progress_completed,
  progressList,
  operations,
  onDetail,
  onRefresh,
  itemTypes,
}) => {
  const [referenceWidth] = useState(200);
  const [operationWidth] = useState(200);
  const [minWidth, setMinWidth] = useState(referenceWidth);
  const [operationItems, setOperationItems] = useState({ show: false });
  const [visibleOperations, setVisibleOperations] = useState([]);
  useEffect(() => {
    setVisibleOperations(operations.filter(op => op.visible === true));
  }, [operations, setVisibleOperations]);
  useEffect(() => {
    setMinWidth(referenceWidth + visibleOperations.length * operationWidth);
  }, [visibleOperations, referenceWidth, operationWidth]);
  return (
    <Fragment>
      <div
        className=''
        style={{
          paddingTop: '0px !important',
          width: '100%',
          position: 'relative',
          height: 'calc(100vh - 200px)',
          overflow: 'scroll',
        }}
      >
        <div
          className='font-weight-bold pt-3 table'
          style={{ minWidth: minWidth }}
        >
          <ProgressHeader
            group={group}
            group2={group2}
            group3={group3}
            operations={visibleOperations}
            referenceWidth={referenceWidth}
            operationWidth={operationWidth}
          />
          {progressList.map(orderStat => {
            const key =
              orderStat.key +
              '::' +
              getProgressRowGroupVal(orderStat.group2, orderStat);
            return (
              <Fragment key={key}>
                <ProgressLine
                  operations={visibleOperations}
                  order_progress_completed={order_progress_completed}
                  orderStat={orderStat}
                  group={group}
                  group2={group2}
                  group3={group3}
                  referenceWidth={referenceWidth}
                  operationWidth={operationWidth}
                  onDetail={onDetail}
                  setOperationItems={setOperationItems}
                  onRefresh={onRefresh}
                />
              </Fragment>
            );
          })}
        </div>
      </div>
      {operationItems.show && (
        <ProgressOperationItems
          operationItems={operationItems}
          onCloseCb={() => {
            setOperationItems({ show: false });
          }}
          itemTypes={itemTypes}
        />
      )}
    </Fragment>
  );
};
