import useMobile from '../../hooks/useMobile';
import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { CarousalNext, CarousalPrev } from '../../assets/icons/common-icons';
import {
  TrackTraceImageAddIcon,
  TrackTraceImageRemoveIcon,
} from '../../assets/icons/icon-tracktrace';
import { FileUploader } from 'react-drag-drop-files';
/* eslint-disable sonarjs/cognitive-complexity */
export const ImageListModal = memo(
  ({
    images,
    onCloseCb,
    onImageUpdate,
    onConfirmCb,
    onNewImage,
    onImageUploadClick,
  }) => {
    const [isMobile, isTab] = useMobile();
    const [currentImage, setCurrentImage] = useState(0);

    const getThumbnailObject = useCallback(
      leftIndex => {
        const showCount = isMobile ? 2 : isTab ? 3 : 4;
        if (leftIndex >= images.length) {
          leftIndex = images.length - 1;
        } else if (leftIndex < 0) {
          leftIndex = 0;
        }

        const to = {
          leftIndex: leftIndex,
          images: [],
          show: showCount,
        };
        for (let i = 0; i < images.length; i++) {
          if (i < leftIndex || i >= leftIndex + showCount) {
            continue;
          }
          to.images.push({
            index: i,
            more: 0,
          });
        }
        if (images.length > to.images.length && to.images.length > 1) {
          const first = to.images[0];
          const last = to.images[to.images.length - 1];
          first.more = first.index;
          last.more = images.length - first.index - showCount;
        }
        return to;
      },
      [isMobile, isTab, images],
    );
    useEffect(() => {
      setCurrentImage(ci => {
        if (ci >= images.length) {
          return Math.max(0, images.length - 1);
        }
        return ci;
      });
    }, [images]);
    const [thumbnail, setThumbnail] = useState(getThumbnailObject(0));
    const nextImage = useCallback(
      shift => {
        setCurrentImage(index => {
          const next = index + shift;
          if (next >= images.length) {
            return 0;
          } else if (next < 0) {
            return images.length - 1;
          }
          return next;
        });
      },
      [images, setCurrentImage],
    );
    useEffect(() => {
      setThumbnail(to => {
        return getThumbnailObject(to.leftIndex);
      });
    }, [getThumbnailObject, setThumbnail]);
    const onImageSelect = useCallback(
      (image, index) => {
        setCurrentImage(image.index);
        if (image.more > 0 && index === 0 && image.index > 0) {
          setThumbnail(tn => {
            return getThumbnailObject(tn.leftIndex - 1);
          });
        } else if (
          image.more > 0 &&
          index > 0 &&
          image.index < images.length - 1
        ) {
          setThumbnail(tn => {
            return getThumbnailObject(tn.leftIndex + 1);
          });
        }
      },
      [setThumbnail, getThumbnailObject, images],
    );
    const handleFileDrop = useCallback(
      async file => {
        const readFileData = async file => {
          return await new Promise(resolve => {
            const reader = new FileReader();
            reader.onload = evt => {
              const baseURL = reader.result;
              resolve(baseURL);
            };
            reader.readAsDataURL(file);
          });
        };
        if (
          ['jpeg', 'png', 'jpg'].filter(val => file.type.indexOf(val) >= 0)
            .length === 0
        ) {
          return;
        }
        const imageData = await readFileData(file);
        onImageUpdate([
          ...images,
          { data: imageData, name: file.name, type: file.type },
        ]);
      },
      [images, onImageUpdate],
    );
    return (
      <Fragment>
        <div>
          <Modal
            centered={!isMobile}
            size={'xl'}
            show={true}
            onHide={onCloseCb}
            dialogClassName='track-trace-dialog'
          >
            <Modal.Header
              className='px-32'
              style={{ borderBottom: '0px' }}
              closeButton
            ></Modal.Header>
            <Modal.Body className='px-16 py-16'>
              <div className='text-center'>
                {(!images || images.length === 0) && (
                  <div className='px-16'>
                    <div className='bulk-upload-import-content '>
                      <FileUploader
                        name={'fileupload'}
                        handleChange={handleFileDrop}
                        onDrop={handleFileDrop}
                        multiple={false}
                        types={['png', 'jpeg', 'jpg']}
                        classes='w-100'
                      >
                        <div className='w-100'>
                          <div className='w-100 text-center pt-4'>
                            <svg
                              width='83'
                              height='59'
                              viewBox='0 0 83 59'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                opacity='0.1'
                                d='M18.8125 58.125C8.5 58.125 0.25 49.875 0.25 39.5625C0.25 31.5703 5.40625 24.6094 12.625 22.1602C12.625 21.7734 12.625 21.3867 12.625 21C12.625 9.65625 21.7773 0.375 33.25 0.375C40.8555 0.375 47.5586 4.62891 51.0391 10.8164C52.9727 9.39844 55.4219 8.625 58 8.625C64.832 8.625 70.375 14.168 70.375 21C70.375 22.6758 69.9883 24.0938 69.4727 25.5117C77.0781 27.0586 82.75 33.7617 82.75 41.625C82.75 50.7773 75.2734 58.125 66.25 58.125H18.8125ZM28.9961 30.1523C27.707 31.4414 27.707 33.375 28.9961 34.5352C30.1562 35.8242 32.0898 35.8242 33.25 34.5352L38.4062 29.5078V46.7812C38.4062 48.5859 39.6953 49.875 41.5 49.875C43.1758 49.875 44.5938 48.5859 44.5938 46.7812V29.5078L49.6211 34.5352C50.7812 35.8242 52.7148 35.8242 53.875 34.5352C55.1641 33.375 55.1641 31.4414 53.875 30.1523L43.5625 19.8398C42.4023 18.6797 40.4688 18.6797 39.3086 19.8398L28.9961 30.1523Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <div className='w-100 text-center bulk-upload-drag-drop-text'>
                            <div>Drag and Drop Files Here</div>
                          </div>
                          <div className='bulk-upload-upload-separator'>
                            <div className='row'>
                              <div className='col d-flex align-items-center'>
                                <div></div>
                              </div>
                              <div className='or'>or</div>
                              <div className='col d-flex align-items-center'>
                                <div></div>
                              </div>
                            </div>
                          </div>
                          <div className='bulk-upload-browse-file cursor-pointer'>
                            <div
                              className='text-center'
                              onClick={() => {
                                if (onImageUploadClick) {
                                  onImageUploadClick();
                                }
                              }}
                            >
                              <span className={`'me-2'`}>Browse File</span>

                              <span className=''>
                                <svg
                                  width='19'
                                  height='14'
                                  viewBox='0 0 19 14'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M4.625 5H15V3.5C15 2.6875 14.3125 2 13.5 2H8.5L6.5 0H1.5C0.6875 0 0 0.6875 0 1.5V11.75L2.84375 6.09375C3.15625 5.4375 3.84375 5 4.625 5ZM17 6H4.625C4.25 6 3.90625 6.21875 3.71875 6.5625L0 14H14C14.375 14 14.7188 13.7812 14.9062 13.4375L17.9062 7.4375C18.2188 6.78125 17.75 6 17 6Z'
                                    fill='#006EED'
                                  />
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </FileUploader>
                    </div>
                  </div>
                )}
                {images && images.length > 0 && (
                  <div className='row'>
                    <div
                      className='d-flex align-self-center cursor-pointer ps-16'
                      style={{ width: '48px' }}
                      onClick={() => {
                        nextImage(-1);
                      }}
                    >
                      {images && images.length > 1 && <CarousalPrev />}
                    </div>
                    <div className='col'>
                      <div
                        className='d-flex justify-content-center position-relative'
                        style={{ height: 'calc(100vh - 300px)' }}
                      >
                        <img
                          style={{
                            maxWidth: '100%',
                            objectFit: 'contain',
                            backgroundColor: '#000',
                          }}
                          src={
                            images[`${currentImage}`]
                              ? images[`${currentImage}`].data
                              : null
                          }
                          alt='img'
                        />
                      </div>
                    </div>
                    <div
                      className='d-flex align-self-center cursor-pointer pe-16'
                      style={{ width: '48px' }}
                      onClick={() => {
                        nextImage(1);
                      }}
                    >
                      {images && images.length > 1 && <CarousalNext />}
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer
              className='position-relative '
              style={{ borderTop: '0px' }}
            >
              <div className='row w-100'>
                <div className='d-flex' style={{ width: '40%' }}>
                  {onNewImage && (
                    <div className='me-3'>
                      <div
                        className='d-flex align-items-center position-relative justify-content-center cursor-pointer'
                        style={{
                          width: '56px',
                          height: '56px',
                          backgroundColor: 'rgba(2, 122, 255,0.1)',
                        }}
                        onClick={onNewImage}
                      >
                        <TrackTraceImageAddIcon />
                      </div>
                    </div>
                  )}
                  {thumbnail &&
                    thumbnail.images &&
                    thumbnail.images.length > 0 &&
                    thumbnail.images.map((image, index) => {
                      let text = '';
                      let showMore = false;
                      if (index === 0 && image.more > 0) {
                        text = '-' + image.more;
                        showMore = true;
                      }
                      if (
                        index === thumbnail.images.length - 1 &&
                        image.more > 0
                      ) {
                        text = '+' + image.more;
                        showMore = true;
                      }
                      return (
                        <div className='me-3' key={image.index}>
                          <div
                            className='position-relative justify-content-center cursor-pointer'
                            style={{
                              width: '56px',
                              height: '56px',
                              backgroundColor: '#000',
                            }}
                            onClick={() => {
                              onImageSelect(image, index);
                            }}
                          >
                            <img
                              style={{
                                objectFit: 'contain',
                                width: '100%',
                                height: '100%',
                              }}
                              src={
                                images[`${image.index}`]
                                  ? images[`${image.index}`].data
                                  : null
                              }
                              alt={` ${image.index}`}
                            />
                            {showMore && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '18px',
                                  left: '14px',
                                  fontSize: '18px',
                                  fontFamily: 'Rubik',
                                }}
                              >
                                {text}
                              </div>
                            )}
                            {!showMore && onImageUpdate && (
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '-10px',
                                  right: '-12px',
                                }}
                              >
                                <TrackTraceImageRemoveIcon
                                  onClick={() => {
                                    const _images = [...images];
                                    _images.splice(image.index, 1);
                                    onImageUpdate(_images);
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className='col'>
                  <div className='d-flex justify-content-end pe-16'>
                    {onNewImage && (
                      <>
                        <div className='ms-16 pt-8'>
                          <div
                            className='btn btn-outline-primary'
                            onClick={() => {
                              if (onCloseCb) {
                                onCloseCb();
                              }
                            }}
                          >
                            Cancel
                          </div>
                        </div>
                        <div className='ms-16 pt-8'>
                          <div
                            className='btn btn-primary'
                            onClick={() => {
                              onConfirmCb(images);
                              if (onCloseCb) {
                                onCloseCb();
                              }
                            }}
                          >
                            Confirm
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </Fragment>
    );
  },
);
/* eslint-enable sonarjs/cognitive-complexity */
export default ImageListModal;
