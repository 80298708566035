/* eslint-disable security/detect-object-injection */
import React, { useState, useEffect, useCallback } from 'react';
import '../../scss/components/_dropdown.scss';
import { useLocation, useHistory } from 'react-router-dom';
import BotInput from './botinput';
import BotInputCNC from './botinput-cnc';
import Mapping from './mapping';
import BotInputPrinter from './botinput-printer';
import BotInputBeamsaw from './botinput-beamsaw';
import { ToastContainer } from 'react-toastify';
import UploadHeader from './botsheader';
import ScreenLoader from '../../components/common/screen-loader';
import useMobile from '../../hooks/useMobile';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@naadi/framework';
import { toastError } from '../../helpers/packing/packingHelper';
import { fetchStations } from '../../services/station';
import { fetchOperations } from '../../services/operation';
import {
  createDesignBot,
  createCNCBot,
  createPrinterBot,
  getBots,
  getCNCBots,
  getPrinterBots,
} from '../../services/bot';
import { cloneDeep } from 'lodash';
import { getLinkWorkstation } from '../../services/operation';
import yaml from 'json-to-pretty-yaml';
function exportBotInfo(botInfo, bottype) {
  const data = yaml.stringify(botInfo);
  const blob = new Blob([data], { type: 'yaml' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  if (bottype === 'DESIGN_TOOL') {
    link.download = 'Design Bot Config.yaml';
  } else if (bottype === 'CNC_CLIENT') {
    link.download = 'CNC Bot Config.yaml';
  } else if (bottype === 'PRINT_INTERCEPT_CLIENT') {
    link.download = 'BeamSaw Bot Config.yaml';
  } else if (bottype === 'SCAN_CLIENT') {
    link.download = 'Printer Bot Config.yaml';
  }
  link.href = url;
  link.click();
}

const NewBot = () => {
  const [botnameFocus, setBotNameFocus] = useState(false);
  const [batchuploadFocus, setBatchuploadFocus] = useState(false);
  const [queryFocus, setQueryFocus] = useState(false);
  const [botnameError, setBotnameError] = useState(false);
  const [batchuploadError, setBatchuploadError] = useState(false);
  const [queryError, setQueryError] = useState(false);
  const [selectedBotCode, setSelectedBotCode] = useState();
  const [selectedId, setSelectedId] = useState();
  const [addParameterClick, setAddParameterClick] = useState([]);
  const [selectStationGroup, setSelectStationGroup] = useState();
  const [selectStation, setSelectStation] = useState();
  const [selectWorkstation, setSelectWorkstation] = useState();
  const [selectOperation, setSelectOperation] = useState();
  const [selPrefFormat, setSelPrefFormat] = useState();
  const [featOpMapping, setFeatOpMapping] = useState();
  const [botsList, setBotsList] = useState([]);
  const [stationgroupDrawer, setStationgroupDrawer] = useState();
  const [stationDrawer, setStationDrawer] = useState();
  const [workstationDrawer, setWorkstationDrawer] = useState();
  const [operationDrawer, setOperationDrawer] = useState();
  const [searchText] = useState('');
  const [errors] = useState({});
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [branchId, setBranchId] = useState(
    user && user.branch && user.branch.branch_id ? user.branch.branch_id : '',
  );

  const [form, setForm] = useState({
    botname: '',
    query: '',
    batch_upload: '',
  });
  const [mapform, setMapForm] = useState({
    trace: '',
    curl: '',
    database: '',
    username: '',
    password: '',
    port: '',
    host: '',
    image_path: '',
    program_path: '',
    query_template: '',
    image_template: '',
    program_file_ext: '',
    batchupload: true,
  });
  const [isMobile, isTab] = useMobile();
  const onChange = useCallback(e => {
    const target = e.target;
    setForm(o => ({ ...o, [target.getAttribute('name')]: target.value }));
  }, []);

  const onMapChange = useCallback(e => {
    const target = e.target;
    setMapForm(o => ({ ...o, [target.getAttribute('name')]: target.value }));
    setFeatOpMapping(prevState => {
      const op_code = target.getAttribute('name').slice(0, -1);
      const ind = parseInt(
        target
          .getAttribute('name')
          .charAt(target.getAttribute('name').length - 1),
        10,
      );

      if (prevState && prevState[op_code]) {
        const updatedOpCode = [...prevState[op_code]];
        updatedOpCode[ind] = target.value;

        return {
          ...prevState,
          [op_code]: updatedOpCode,
        };
      }

      return prevState;
    });
  }, []);

  const addParameter = useCallback(
    e => {
      const aP = cloneDeep(addParameterClick);
      const parameter = {
        param_type: '',
        type: '',
        name: '',
        template: '',
        value: null,
      };
      aP.push(parameter);
      setAddParameterClick(aP);
    },
    [addParameterClick, setAddParameterClick],
  );

  useEffect(() => {
    const search = location.search;
    const urlSearchParams = new URLSearchParams(search);
    const _params = Object.fromEntries(urlSearchParams.entries());

    setSelectedBotCode(
      _params.id && _params.id.includes('?type=')
        ? _params.id.split('?type=', 2)[1]
        : _params.type,
    );
    setSelectedId(
      _params.id && _params.id.includes('?type=')
        ? _params.id.split('?type=', 2)[0]
        : _params.id,
    );
  }, [location, selectedBotCode, selectedId]);

  const fetchStationGroups = useCallback(
    async tid => {
      const stationgroupBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (searchText)
        stationgroupBody.req_payload.term = searchText.toUpperCase();
      try {
        dispatch(actions.busy.add('FETCH_STATIONGROUP'));
        const getStationgroup = await getLinkWorkstation(stationgroupBody);

        if (getStationgroup && getStationgroup.status !== 200) {
          return;
        }

        setStationgroupDrawer(getStationgroup.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_STATIONGROUP'));
      }
    },
    [dispatch, searchText],
  );
  useEffect(() => {
    fetchStationGroups();
  }, [fetchStationGroups]);

  const FetchStations = useCallback(
    async tid => {
      const stationBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (searchText) stationBody.req_payload.term = searchText.toUpperCase();
      try {
        dispatch(actions.busy.add('FETCH_STATION'));
        const getStation = await fetchStations(stationBody);

        if (getStation && getStation.status !== 200) {
          return;
        }

        setStationDrawer(getStation.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_STATION'));
      }
    },
    [dispatch, searchText],
  );
  useEffect(() => {
    FetchStations();
  }, [FetchStations]);

  const fetchWorkstations = useCallback(
    async tid => {
      const workstationBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (searchText)
        workstationBody.req_payload.term = searchText.toUpperCase();
      try {
        dispatch(actions.busy.add('FETCH_STATION'));
        const getWorkstation = await getLinkWorkstation(workstationBody);

        if (getWorkstation && getWorkstation.status !== 200) {
          return;
        }

        setWorkstationDrawer(getWorkstation.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_STATION'));
      }
    },
    [dispatch, searchText],
  );
  useEffect(() => {
    fetchWorkstations();
  }, [fetchWorkstations]);
  const FetchOperations = useCallback(
    async tid => {
      const operationBody = {
        req_payload: {
          active: [true],
          deleted: [false],
        },
      };
      if (searchText) operationBody.req_payload.term = searchText.toUpperCase();
      try {
        dispatch(actions.busy.add('FETCH_OPERATION'));
        const getOperation = await fetchOperations(operationBody);

        if (getOperation && getOperation.status !== 200) {
          return;
        }

        setOperationDrawer(getOperation.payload);
      } finally {
        dispatch(actions.busy.remove('FETCH_OPERATION'));
      }
    },
    [dispatch, searchText],
  );
  useEffect(() => {
    FetchOperations();
  }, [FetchOperations]);

  const FetchBot = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async tid => {
      const req = {
        page: 0,
        size: 100,
        active: true,
        approved: true,
        req_payload: {
          deleted: [false],
          uuid: [selectedId],
          branch_id: [branchId],
        },
      };
      try {
        dispatch(actions.busy.add('FETCH_BOT'));
        let fetchBot;
        if (selectedBotCode === 'DESIGN_TOOL') {
          fetchBot = await getBots(req);
        } else if (
          selectedBotCode === 'CNC_CLIENT' ||
          selectedBotCode === 'PRINT_INTERCEPT_CLIENT'
        ) {
          fetchBot = await getCNCBots(req);
        } else if (selectedBotCode === 'SCAN_CLIENT') {
          fetchBot = await getPrinterBots(req);
        }
        if (fetchBot && fetchBot.status !== 200) {
          return;
        }
        if (fetchBot && fetchBot.payload.length > 0) {
          if (selectedBotCode === 'DESIGN_TOOL') {
            setForm({
              bottype: fetchBot.payload[0].client_type,
              botname: fetchBot.payload[0].client_name,
              batchupload: fetchBot.payload[0].client_config.batch_upload,
              query: fetchBot.payload[0].client_config.query,
              enabletrace: fetchBot.payload[0].client_config.enable_trace,
              enablecurl: fetchBot.payload[0].client_config.enable_curl,
              database: fetchBot.payload[0].client_config.database,
              username: fetchBot.payload[0].client_config.db_user_name,
              password: fetchBot.payload[0].client_config.db_password,
              port: fetchBot.payload[0].client_config.db_port,
              host: fetchBot.payload[0].client_config.db_host,
              image_path: fetchBot.payload[0].client_config.image_path,
              program_path: fetchBot.payload[0].client_config.program_path,
              query_template: fetchBot.payload[0].client_config.query_template,
              image_template: fetchBot.payload[0].client_config.image_template,
              program_file_ext:
                fetchBot.payload[0].client_config.program_file_ext,
            });
            setBotsList(fetchBot.payload[0]);
            setAddParameterClick(
              cloneDeep(fetchBot.payload[0].client_config.parameter),
            );
          } else if (selectedBotCode === 'CNC_CLIENT') {
            if (workstationDrawer && workstationDrawer.length > 0) {
              setSelectWorkstation(
                workstationDrawer.find(
                  f => f.uuid === fetchBot.payload[0].client_pair_id,
                ),
              );
            }
            setForm({
              botname: fetchBot.payload[0].client_name,
              enabletrace: fetchBot.payload[0].client_config.enable_trace,
              enablecurl: fetchBot.payload[0].client_config.enable_curl,
              machine_type: fetchBot.payload[0].client_config.machine_type,
              machine_name: fetchBot.payload[0].client_config.machine_name,
              serial_port_in: fetchBot.payload[0].client_config.serial_port_in,
              serial_port_out:
                fetchBot.payload[0].client_config.serial_port_out,
              baudrate: fetchBot.payload[0].client_config.boudrate,
              read_timeout_ms:
                fetchBot.payload[0].client_config.read_timeout_ms,
              queue_folder: fetchBot.payload[0].client_config.queue_folder,
              program_suffix_length:
                fetchBot.payload[0].client_config.program_suffix_length,
              feature_operation_mapping:
                fetchBot.payload[0].client_config.feature_operation_mapping,
              barcodr_split_token:
                fetchBot.payload[0].client_config.barcodr_split_token,
              program_path: fetchBot.payload[0].client_config.program_path,
              program_target_path:
                fetchBot.payload[0].client_config.program_target_path,
              program_file_ext:
                fetchBot.payload[0].client_config.program_file_ext,
            });
            setBotsList(fetchBot.payload[0]);
            setFeatOpMapping(
              fetchBot.payload[0].client_config.feature_operation_mapping,
            );
          } else if (selectedBotCode === 'PRINT_INTERCEPT_CLIENT') {
            if (stationgroupDrawer) {
              setSelectStationGroup(
                stationgroupDrawer.find(
                  f =>
                    f.uuid === fetchBot.payload[0].client_config.station_group,
                ),
              );
            }
            if (stationDrawer) {
              setSelectStation(
                stationDrawer.find(
                  f =>
                    f.uuid === fetchBot.payload[0].client_config.station_uuid,
                ),
              );
            }
            if (workstationDrawer && workstationDrawer.length > 0) {
              setSelectWorkstation(
                workstationDrawer.find(
                  f =>
                    f.uuid === fetchBot.payload[0].client_config.client_pair_id,
                ),
              );
            }
            if (operationDrawer) {
              setSelectOperation(
                operationDrawer.find(
                  f =>
                    f.uuid === fetchBot.payload[0].client_config.operation_uuid,
                ),
              );
            }
            setForm({
              enabletrace: fetchBot.payload[0].client_config.enable_trace,
              enablecurl: fetchBot.payload[0].client_config.enable_curl,
              printername: fetchBot.payload[0].client_name,
              printer_type: fetchBot.payload[0].printer_type,
              port: fetchBot.payload[0].client_config.port,
              out_folder: fetchBot.payload[0].client_config.out_folder,
              queue_folder: fetchBot.payload[0].client_config.queue_folder,
              sync_label: fetchBot.payload[0].client_config.sync_label,
              offline_mode: fetchBot.payload[0].client_config.offline_mode,
              label_retension:
                fetchBot.payload[0].client_config.label_retension,
              dpi: fetchBot.payload[0].client_config.dpi,
              label_crop_left:
                fetchBot.payload[0].client_config.label_crop_left,
              label_crop_right:
                fetchBot.payload[0].client_config.label_crop_right,
              label_crop_top: fetchBot.payload[0].client_config.label_crop_top,
              label_crop_bottom:
                fetchBot.payload[0].client_config.label_crop_bottom,
              qr_code_crop_x0:
                fetchBot.payload[0].client_config.qr_code_crop_x0,
              qr_code_crop_y0:
                fetchBot.payload[0].client_config.qr_code_crop_y0,
              qr_code_crop_x1:
                fetchBot.payload[0].client_config.qr_code_crop_x1,
              qr_code_crop_y1:
                fetchBot.payload[0].client_config.qr_code_crop_y1,
            });
            setBotsList(fetchBot.payload[0]);
          } else if (selectedBotCode === 'SCAN_CLIENT') {
            setForm({
              enabletrace: fetchBot.payload[0].client_config.enable_trace,
              enablecurl: fetchBot.payload[0].client_config.enable_curl,
              name: fetchBot.payload[0].name,
              pref_format: fetchBot.payload[0].preferred_export_format,
              print_exe_path: fetchBot.payload[0].client_config.print_exe_path,
              printer_type: fetchBot.payload[0].client_config.printer_type,
              printer_argument:
                fetchBot.payload[0].client_config.printer_argument,
            });
            setBotsList(fetchBot.payload[0]);
          }
        }
      } finally {
        dispatch(actions.busy.remove('FETCH_BOT'));
      }
    },
    [
      dispatch,
      selectedId,
      selectedBotCode,
      stationgroupDrawer,
      stationDrawer,
      workstationDrawer,
      operationDrawer,
      branchId,
    ],
  );
  useEffect(() => {
    FetchBot();
  }, [FetchBot]);
  useEffect(() => {
    const _branchId =
      user && user.branch && user.branch.branch_id ? user.branch.branch_id : '';
    if (_branchId !== branchId) {
      setBranchId(_branchId);
      FetchBot();
    }
  }, [user, branchId, setBranchId, FetchBot]);
  const downloadChanges = useCallback(
    async e => {
      const NAADI_BASE_WS_URL = 'wss://api.prod.naadi.io/naadi-app';
      const NAADI_BASE_URL = 'https://api.prod.naadi.io/naadi-app';
      const KEYCLOAK_OPENID_URL =
        'https://keycloak.naadi.io/realms/naadi/.well-known/openid-configuration';
      const designInfo = {
        NAADI_BASE_WS_URL: NAADI_BASE_WS_URL,
        NAADI_BASE_URL: NAADI_BASE_URL,
        KEYCLOAK_OPENID_URL: KEYCLOAK_OPENID_URL,
        org_id: botsList.org_id,
        branch_id: botsList.branch_id ? botsList.branch_id : branchId,
        ENABLE_TRACE: form.enabletrace,
        ENABLE_CURL: form.enablecurl,
        design_tool_config: {
          client_id: selectedId,
          client_type: selectedBotCode,
          DATABASE: form.database,
          USERNAME: form.username,
          PASSWORD: form.password,
          PORT: form.port,
          HOST: form.host,
          image_path: form.image_path,
          program_path: form.program_path,
        },
      };

      const CNCInfo = {
        NAADI_BASE_WS_URL: NAADI_BASE_WS_URL,
        NAADI_BASE_URL: NAADI_BASE_URL,
        KEYCLOAK_OPENID_URL: KEYCLOAK_OPENID_URL,
        org_id: botsList.org_id,
        branch_id: botsList.branch_id ? botsList.branch_id : branchId,
        ENABLE_TRACE: form.enabletrace,
        ENABLE_CURL: form.enablecurl,
        cnc_client_config: {
          client_id: selectedId,
          machine_type: form.machine_type,
          machine_name: form.machine_name,
          serial_port_in: form.serial_port_in,
          serial_port_out: form.serial_port_out,
          baudrate: form.baudrate,
          read_timeout_ms: form.read_timeout_ms,
          queue_folder: form.queue_folder,
          program_suffix_length: form.program_suffix_length,
          feature_operation_mapping: featOpMapping,
          barcode_split_token: form.barcodr_split_token,
          program_path: form.program_path,
          program_target_path: form.program_target_path,
          program_file_extn: form.program_file_ext,
        },
        station_config: {
          pod: selectWorkstation ? selectWorkstation.uuid : '',
        },
      };

      const BeamSawInfo = {
        NAADI_BASE_WS_URL: NAADI_BASE_WS_URL.replace('"'),
        NAADI_BASE_URL: NAADI_BASE_URL,
        KEYCLOAK_OPENID_URL: KEYCLOAK_OPENID_URL,
        org_id: botsList.org_id,
        branch_id: botsList.branch_id ? botsList.branch_id : branchId,
        ENABLE_TRACE: form.enabletrace,
        ENABLE_CURL: form.enablecurl,
        print_intercept_server: {
          client_id: selectedId,
          port: form.port,
          out_folder: form.out_folder,
          printer_name: form.printername,
          queue_folder: form.queue_folder,
          sync_label: form.sync_label,
          offline_mode: form.offline_mode,
          label_retention_days: form.label_retension,
          crop_left: form.label_crop_left,
          crop_right: form.label_crop_right,
          crop_top: form.label_crop_top,
          crop_bottom: form.label_crop_bottom,
          ps2png_dpi: form.dpi,
          qrcode_clips: {
            x0: form.qr_code_crop_x0,
            x1: form.qr_code_crop_x1,
            y0: form.qr_code_crop_y0,
            y1: form.qr_code_crop_y1,
          },
        },
        station_config: {
          station_group: selectStationGroup
            ? selectStationGroup.station_group
            : '',
          station: selectStation ? selectStation.station_code : '',
          pod: selectWorkstation ? selectWorkstation.uuid : '',
          operations: selectOperation ? [selectOperation.operation_code] : '',
        },
      };
      const PrinterInfo = {
        NAADI_BASE_WS_URL: NAADI_BASE_WS_URL,
        NAADI_BASE_URL: NAADI_BASE_URL,
        KEYCLOAK_OPENID_URL: KEYCLOAK_OPENID_URL,
        org_id: botsList.org_id,
        branch_id: botsList.branch_id ? botsList.branch_id : branchId,
        ENABLE_TRACE: form.enabletrace,
        ENABLE_CURL: form.enablecurl,
        printclient: {
          PRINTER_ID: botsList.printer_id,
          printer_name: form.name,
          preferred_format: form.pref_format,
          printer_type: form.printer_type,
          print_exe_path: form.print_exe_path,
          print_exe_args: form.printer_argument,
        },
      };

      if (selectedBotCode === 'DESIGN_TOOL') {
        exportBotInfo(designInfo, selectedBotCode);
      } else if (selectedBotCode === 'CNC_CLIENT') {
        exportBotInfo(CNCInfo, selectedBotCode);
      } else if (selectedBotCode === 'PRINT_INTERCEPT_CLIENT') {
        exportBotInfo(BeamSawInfo, selectedBotCode);
      } else if (selectedBotCode === 'SCAN_CLIENT') {
        exportBotInfo(PrinterInfo, selectedBotCode);
      }
    },
    [
      form,
      selectedBotCode,
      selectedId,
      botsList,
      selectStationGroup,
      selectStation,
      selectWorkstation,
      selectOperation,
      branchId,
      featOpMapping,
    ],
  );
  const saveChanges = useCallback(
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async e => {
      const dataExtn = {
        page: 0,
        size: 1,
        active: true,
        approved: true,
        deleted: false,
        req_payload: {
          client_config: {},
        },
      };
      dataExtn.req_payload.branch_id = branchId;
      dataExtn.req_payload.uuid = selectedId;
      dataExtn.req_payload.client_type = selectedBotCode;
      if (selectedBotCode === 'DESIGN_TOOL') {
        dataExtn.req_payload.client_name = form.botname;
        dataExtn.req_payload.client_config.query = form.query;
        dataExtn.req_payload.client_config.batch_upload = form.batchupload
          ? form.batchupload
          : false;

        for (let i = 0; i < addParameterClick.length; i++) {
          addParameterClick[i].type =
            form[`type${i}`] || addParameterClick[i].type;
          addParameterClick[i].name =
            form[`name${i}`] || addParameterClick[i].name;
          addParameterClick[i].value =
            form[`value${i}`] || addParameterClick[i].value;
          addParameterClick[i].template =
            form[`template${i}`] || addParameterClick[i].template;
          addParameterClick[i].param_type =
            form[`param_type${i}`] || addParameterClick[i].param_type;
        }

        dataExtn.req_payload.client_config.parameter = addParameterClick;
        dataExtn.req_payload.client_config.enable_trace = form.enabletrace
          ? form.enabletrace
          : false;
        dataExtn.req_payload.client_config.enable_curl = form.enablecurl
          ? form.enablecurl
          : false;
        dataExtn.req_payload.client_config.database = form.database;
        dataExtn.req_payload.client_config.db_user_name = form.username;
        dataExtn.req_payload.client_config.db_password = form.password;
        dataExtn.req_payload.client_config.db_port = form.port;
        dataExtn.req_payload.client_config.db_host = form.host;
        dataExtn.req_payload.client_config.image_path = form.image_path;
        dataExtn.req_payload.client_config.program_path = form.program_path;
        dataExtn.req_payload.client_config.query_template = form.query_template;
        dataExtn.req_payload.client_config.image_template = form.image_template;
        dataExtn.req_payload.client_config.program_file_ext =
          form.program_file_ext;
        try {
          dispatch(actions.busy.add('CREATE_BOT'));
          const createBot = await createDesignBot(dataExtn);
          if (createBot.status !== 200) {
            toastError(
              createBot.err && createBot.err.err
                ? createBot.err.err
                : 'Unable to create the Design Tool Bot',
            );
            return;
          }
        } finally {
          //history.goBack();
          dispatch(actions.busy.remove('CREATE_BOT'));
        }
      } else if (
        selectedBotCode === 'CNC_CLIENT' ||
        selectedBotCode === 'PRINT_INTERCEPT_CLIENT'
      ) {
        dataExtn.req_payload.client_name = form.botname;
        dataExtn.req_payload.client_pair_id = selectWorkstation.uuid;
        dataExtn.req_payload.client_config.client_pair_id =
          selectWorkstation.uuid;
        dataExtn.req_payload.client_config.enable_trace = form.enabletrace
          ? form.enabletrace
          : false;
        dataExtn.req_payload.client_config.enable_curl = form.enablecurl
          ? form.enablecurl
          : false;
        if (selectedBotCode === 'CNC_CLIENT') {
          dataExtn.req_payload.client_config.machine_type = form.machine_type;
          dataExtn.req_payload.client_config.machine_name = form.machine_name;
          dataExtn.req_payload.client_config.serial_port_in =
            form.serial_port_in;
          dataExtn.req_payload.client_config.serial_port_out =
            form.serial_port_out;
          dataExtn.req_payload.client_config.boudrate = form.baudrate;
          dataExtn.req_payload.client_config.read_timeout_ms =
            form.read_timeout_ms;
          dataExtn.req_payload.client_config.queue_folder = form.queue_folder;
          dataExtn.req_payload.client_config.program_suffix_length =
            form.program_suffix_length;
          dataExtn.req_payload.client_config.barcodr_split_token =
            form.barcodr_split_token;
          dataExtn.req_payload.client_config.program_path = form.program_path;
          dataExtn.req_payload.client_config.program_target_path =
            form.program_target_path;
          dataExtn.req_payload.client_config.program_file_ext =
            form.program_file_ext;
          dataExtn.req_payload.client_config.feature_operation_mapping =
            featOpMapping;
        } else if (selectedBotCode === 'PRINT_INTERCEPT_CLIENT') {
          dataExtn.req_payload.client_name = form.printername;
          dataExtn.req_payload.printer_type = form.printer_type;
          dataExtn.req_payload.client_config.port = form.port;
          dataExtn.req_payload.client_config.out_folder = form.out_folder;
          dataExtn.req_payload.client_config.queue_folder = form.queue_folder;
          dataExtn.req_payload.client_config.sync_label = form.sync_label;
          dataExtn.req_payload.client_config.offline_mode = form.offline_mode;
          dataExtn.req_payload.client_config.label_retension =
            form.label_retension;
          dataExtn.req_payload.client_config.dpi = form.dpi;
          dataExtn.req_payload.client_config.label_crop_left =
            form.label_crop_left;
          dataExtn.req_payload.client_config.label_crop_right =
            form.label_crop_right;
          dataExtn.req_payload.client_config.label_crop_top =
            form.label_crop_top;
          dataExtn.req_payload.client_config.label_crop_bottom =
            form.label_crop_bottom;
          dataExtn.req_payload.client_config.qr_code_crop_x0 =
            form.qr_code_crop_x0;
          dataExtn.req_payload.client_config.qr_code_crop_y0 =
            form.qr_code_crop_y0;
          dataExtn.req_payload.client_config.qr_code_crop_x1 =
            form.qr_code_crop_x1;
          dataExtn.req_payload.client_config.qr_code_crop_y1 =
            form.qr_code_crop_y1;
          dataExtn.req_payload.client_config.station_group =
            selectStationGroup.uuid;
          dataExtn.req_payload.client_config.station_uuid = selectStation.uuid;
          dataExtn.req_payload.client_config.operation_uuid =
            selectOperation.uuid;
        }
        try {
          dispatch(actions.busy.add('CREATE_BOT'));
          const createBot = await createCNCBot(dataExtn);
          if (createBot.status !== 200) {
            toastError(
              createBot.err && createBot.err.err
                ? createBot.err.err
                : 'Unable to create the Bot',
            );
            return;
          }
        } finally {
          //history.goBack();
          dispatch(actions.busy.remove('CREATE_BOT'));
        }
      } else if (selectedBotCode === 'SCAN_CLIENT') {
        dataExtn.req_payload.name = form.name;
        // dataExtn.req_payload.printer_type = form.printer_type;
        dataExtn.req_payload.client_config.enable_trace = form.enabletrace
          ? form.enabletrace
          : false;
        dataExtn.req_payload.client_config.enable_curl = form.enablecurl
          ? form.enablecurl
          : false;
        dataExtn.req_payload.preferred_export_format = selPrefFormat;
        dataExtn.req_payload.client_config.print_exe_path = form.print_exe_path;
        dataExtn.req_payload.client_config.printer_argument =
          form.printer_argument;
        try {
          dispatch(actions.busy.add('CREATE_BOT'));
          const createBot = await createPrinterBot(dataExtn);
          if (createBot.status !== 200) {
            toastError(
              createBot.err && createBot.err.err
                ? createBot.err.err
                : 'Unable to create the Printer Bot',
            );
            return;
          }
        } finally {
          //history.goBack();
          dispatch(actions.busy.remove('CREATE_BOT'));
        }
      }
    },
    [
      dispatch,
      branchId,
      form,
      //mapform,
      selectedBotCode,
      addParameterClick,
      selectedId,
      selectStationGroup,
      selectStation,
      selectWorkstation,
      selectOperation,
      featOpMapping,
      selPrefFormat,
    ],
  );

  return (
    <div
      className='width-100-percent bg-white'
      style={{ height: '100vh', overflowX: 'hidden' }}
    >
      <div style={{ display: 'block', overflow: 'hidden' }}>
        <ScreenLoader />
      </div>
      <ToastContainer />

      <div>
        <div className='container-fluid position-relative px-0'>
          <UploadHeader />
          {selectedBotCode === 'DESIGN_TOOL' && (
            <BotInput
              bottype={selectedBotCode}
              addParameterClick={addParameterClick}
              setAddParameterClick={setAddParameterClick}
              botnameFocus={botnameFocus}
              setBotNameFocus={setBotNameFocus}
              batchuploadFocus={batchuploadFocus}
              setBatchuploadFocus={setBatchuploadFocus}
              queryFocus={queryFocus}
              setQueryFocus={setQueryFocus}
              botnameError={botnameError}
              setBotNameError={setBotnameError}
              batchuploadError={batchuploadError}
              setBatchuploadError={setBatchuploadError}
              queryError={queryError}
              setQueryError={setQueryError}
              form={form}
              setForm={setForm}
              onChange={onChange}
              errors={errors}
              isMobile={isMobile}
              isTab={isTab}
              addParameter={addParameter}
            />
          )}
          {selectedBotCode === 'CNC_CLIENT' && (
            <div>
              <BotInputCNC
                bottype={selectedBotCode}
                botnameFocus={botnameFocus}
                setBotNameFocus={setBotNameFocus}
                batchuploadFocus={batchuploadFocus}
                botnameError={botnameError}
                setBotNameError={setBotnameError}
                selectWorkstation={selectWorkstation}
                setSelectWorkstation={setSelectWorkstation}
                form={form}
                setForm={setForm}
                onChange={onChange}
                mapform={mapform}
                setMapForm={setMapForm}
                onMapChange={onMapChange}
                errors={errors}
                isMobile={isMobile}
                isTab={isTab}
                workstationDrawer={workstationDrawer}
              />
              <Mapping
                bottype={selectedBotCode}
                selectWorkstation={selectWorkstation}
                mapform={mapform}
                setMapForm={setMapForm}
                onMapChange={onMapChange}
                isMobile={isMobile}
                isTab={isTab}
                featOpMapping={featOpMapping}
                setFeatOpMapping={setFeatOpMapping}
              />
            </div>
          )}
          {selectedBotCode === 'SCAN_CLIENT' && (
            <BotInputPrinter
              bottype={selectedBotCode}
              botnameFocus={botnameFocus}
              setBotNameFocus={setBotNameFocus}
              selPrefFormat={selPrefFormat}
              setSelPrefFormat={setSelPrefFormat}
              botnameError={botnameError}
              setBotNameError={setBotnameError}
              form={form}
              setForm={setForm}
              onChange={onChange}
              errors={errors}
              isMobile={isMobile}
              isTab={isTab}
            />
          )}
          {selectedBotCode === 'PRINT_INTERCEPT_CLIENT' && (
            <BotInputBeamsaw
              bottype={selectedBotCode}
              selectStationGroup={selectStationGroup}
              setSelectStationGroup={setSelectStationGroup}
              selectStation={selectStation}
              setSelectStation={setSelectStation}
              selectWorkstation={selectWorkstation}
              setSelectWorkstation={setSelectWorkstation}
              selectOperation={selectOperation}
              setSelectOperation={setSelectOperation}
              form={form}
              setForm={setForm}
              onChange={onChange}
              errors={errors}
              isMobile={isMobile}
              isTab={isTab}
              stationgroupDrawer={stationgroupDrawer}
              stationDrawer={stationDrawer}
              workstationDrawer={workstationDrawer}
              operationDrawer={operationDrawer}
            />
          )}
          <div style={{ height: '100px' }}></div>
          <div className='d-flex'>
            <div className='floating-div' style={{ paddingRight: '90px' }}>
              <button
                className='btn btn-outline-primary btn-md btn-save-changes'
                style={{ marginRight: '12px', background: 'white' }}
                onClick={() => history.goBack()}
              >
                Cancel
              </button>
              {selectedId && (
                <button
                  className='btn btn-outline-primary btn-md btn-save-changes'
                  style={{ marginRight: '12px', background: 'white' }}
                  onClick={() => downloadChanges()}
                >
                  Download
                </button>
              )}
              <button
                className='btn btn-primary btn-save-changes'
                style={{ marginRight: '12px' }}
                onClick={() => saveChanges()}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBot;
