import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import useMobile from '../../hooks/useMobile';
import { cloneDeep } from 'lodash';
import { CameraIcon } from '../../assets/icons/common-icons';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import CloseIcon from '../../assets/icons/icon-cancel.svg';
import ImageListModal from './image-list-modal';
import { RefreshIcon } from '../../assets/icons/nav-icons';
import moment from 'moment/moment';

const ScanHistoryDetail = memo(({ scanRecord }) => {
  return (
    <div className='row rubik-font ' style={{ fontWeight: 'normal' }}>
      <div className={'d-flex track-trace-scanned-on'}>
        <div>Last Scanned on</div>
        <div className='font-bold px-1' style={{ fontWeight: 'bold' }}>
          {moment(scanRecord.scanned_on).format('DD MMM hh:mm a')}
        </div>
        <div className='px-1'> By </div>
        <div className='font-bold ' style={{ fontWeight: 'bold' }}>
          {scanRecord.user_name}
        </div>
        <div className='px-1'> @ </div>
        <div className='track-trace-scan-status'>
          {scanRecord.pod_name ? scanRecord.pod_name : scanRecord.scan_station}
        </div>
      </div>
    </div>
  );
});

const RejectReasonSelection = memo(
  ({ reason, setRejectReasonState, rejectReasonState, groupKey }) => {
    const [currentState, setCurrentState] = useState({});
    useEffect(() => {
      const blameOperationMap = {};
      (reason.blame_operations || []).forEach(bo => {
        blameOperationMap[`${bo}`] = true;
      });
      const reworkOperationMap = {};
      (reason.rework_operations || []).forEach(bo => {
        reworkOperationMap[`${bo}`] = false;
      });
      const extraFieldsMap = {};
      (reason.extra_fields || []).forEach((ef, index) => {
        extraFieldsMap[`${index}`] = '';
      });
      const cs = rejectReasonState[`${groupKey}`] || {
        checked: false,
        blame_operation_map: blameOperationMap,
        blame_operations: reason.blame_operations || [],
        rework_operation_map: reworkOperationMap,
        rework_operations: reason.rework_operations || [],
        extra_field_value_map: extraFieldsMap,
        extra_fields: reason.extra_fields || [],
      };

      setCurrentState(cs);
    }, [setCurrentState, rejectReasonState, reason, groupKey]);
    return (
      <Fragment>
        <div className='pt-2 pb-2 ps-4 '>
          <div className='d-flex'>
            <div>
              <input
                type='checkbox'
                className='main-box-checkbox cursor-pointer'
                onChange={e => {
                  const _currentState = cloneDeep(currentState);
                  _currentState.checked = !_currentState.checked;

                  setRejectReasonState(rS => {
                    const _rS = cloneDeep(rS);
                    _rS[`${groupKey}`] = _currentState;
                    return _rS;
                  });
                }}
                value={true}
                checked={currentState.checked}
              />
            </div>
            <div className='ps-2'>{reason.reason}</div>
          </div>
          {currentState.checked &&
            currentState.blame_operations &&
            currentState.blame_operations.length > 0 && (
              <div className='ps-4'>
                <div style={{ fontSize: '12px' }}>
                  Select Responsible Operation:
                </div>
                <div className='row'>
                  {currentState.blame_operations.map(blameOp => {
                    const currentBlameOp =
                      currentState.blame_operation_map[`${blameOp}`];
                    return (
                      <div className='col-6 d-flex' key={`${blameOp}`}>
                        <div>
                          <input
                            type='checkbox'
                            className='main-box-checkbox cursor-pointer'
                            onChange={e => {
                              const _currentState = cloneDeep(currentState);
                              _currentState.blame_operation_map[`${blameOp}`] =
                                !currentBlameOp;
                              setRejectReasonState(rS => {
                                const _rS = cloneDeep(rS);
                                _rS[`${groupKey}`] = _currentState;
                                return _rS;
                              });
                            }}
                            value={true}
                            checked={currentBlameOp}
                          />
                        </div>
                        <div className='ps-2'>{blameOp}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          {currentState.checked &&
            currentState.extra_fields &&
            currentState.extra_fields.length > 0 && (
              <div className='ps-4 row'>
                {currentState.extra_fields.map((ef, efI) => {
                  const currentVal =
                    currentState.extra_field_value_map[`${efI}`] || '';
                  const required =
                    currentState.checked &&
                    ef.mandatory &&
                    currentVal.length === 0;
                  return (
                    <div className='col-6' key={efI}>
                      <div className='input-container'>
                        <label
                          className={`mb-0 formLabel ${
                            required ? 'error' : ''
                          }`}
                        >
                          {ef.display}
                        </label>
                        <input
                          id='rejectReasonComment'
                          name='comment'
                          type='text'
                          className='input-fields'
                          value={currentVal}
                          onChange={e => {
                            const _currentState = cloneDeep(currentState);
                            _currentState.extra_field_value_map[`${efI}`] =
                              e.target.value;
                            setRejectReasonState(rS => {
                              const _rS = cloneDeep(rS);
                              _rS[`${groupKey}`] = _currentState;
                              return _rS;
                            });
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
        </div>
      </Fragment>
    );
  },
);

const RejectReasonListModal = memo(
  ({
    scanCode,
    isMobile,
    showModal,
    onCloseCb,
    setShowImageListModal,
    setShowRejectReason,
    setShowImageSelectionModal,
    rejectReasons,
    setRejectReasonState,
    rejectReasonState,
    images,
    comment,
    setComment,
    onConfirmCb,
    lastScan,
  }) => {
    return (
      <div>
        <Modal
          centered={!isMobile}
          size={'lg'}
          show={showModal}
          onHide={onCloseCb}
          dialogClassName='track-trace-dialog'
        >
          <Modal.Header className='px-32' style={{ borderBottom: '0px' }}>
            <Modal.Title>
              <div>Rejection of {scanCode}</div>{' '}
              {lastScan && lastScan.uuid && (
                <ScanHistoryDetail scanRecord={lastScan} />
              )}
            </Modal.Title>
            <div className='track-trace-reject-camara-link'>
              {images.length > 0 && (
                <div
                  className='pe-2'
                  onClick={() => {
                    setShowImageListModal(true);
                  }}
                >
                  Image ({images.length})
                </div>
              )}
              <CameraIcon
                style={{ marginTop: '-1px' }}
                onClick={() => {
                  setShowRejectReason(false);
                  setShowImageSelectionModal(true);
                }}
              />
            </div>
          </Modal.Header>
          <Modal.Body className='px-32 py-16'>
            <div className='row rubik-font '>
              {(rejectReasons || []).map(reasonGroup => {
                return (
                  <Fragment key={reasonGroup.group}>
                    <div className='col-12 pt-2 pb-2 ps-4 '>
                      <div className='font-bold'>{reasonGroup.group} : </div>
                      <div className='row'>
                        {(reasonGroup.reasons || []).map(reason => {
                          const groupKey = `${reason.group}|${reason.reason}`;
                          return (
                            <RejectReasonSelection
                              key={groupKey}
                              reason={reason}
                              setRejectReasonState={setRejectReasonState}
                              rejectReasonState={rejectReasonState}
                              groupKey={groupKey}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
            <div className='input-container'>
              <label className='mb-0 formLabel'>Comment</label>
              <input
                id='rejectReasonComment'
                name='comment'
                type='text'
                className='input-fields'
                value={comment}
                onChange={e => setComment(e.target.value)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer style={{ borderTop: '0px' }}>
            <div className='d-flex w-100'>
              <div className='flex-1 text-right pe-2 ps-6'>
                <Button
                  variant='outline-primary'
                  size='lg '
                  className='w-100'
                  onClick={e => {
                    setShowRejectReason(true);
                    onCloseCb();
                  }}
                >
                  Cancel
                </Button>
              </div>
              <div className='flex-1 text-left w-100 ps-2 pe-6'>
                <Button
                  type='submit'
                  className='ms-2 w-100'
                  size='lg'
                  onClick={e => {
                    const reasons = [];
                    rejectReasons.forEach(group => {
                      group.reasons.forEach(reason => {
                        const rReason =
                          rejectReasonState[`${reason.group}|${reason.reason}`];
                        if (rReason && rReason.checked === true) {
                          reasons.push({
                            group: reason.group,
                            reason: reason.reason,
                            confirm_checklist: reason.confirm_checklist,
                            blame_operations: rReason.blame_operations.filter(
                              val =>
                                rReason.blame_operation_map[`${val}`] === true,
                            ),
                            rework_operations: rReason.rework_operations.filter(
                              val =>
                                rReason.rework_operation_map[`${val}`] === true,
                            ),
                            fields: rReason.extra_fields.map((field, i) => {
                              const mappedVal =
                                rReason.extra_field_value_map[`${i}`] || '';
                              return {
                                field: field.field,
                                val: mappedVal,
                              };
                            }),
                          });
                        }
                      });
                    });
                    onConfirmCb(reasons, comment, images);
                  }}
                >
                  Reject
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  },
);

export const RejectReasonModal = memo(
  ({
    showModal,
    onCloseCb,
    onConfirmCb,
    rejectReasons,
    scanCode,
    lastScan,
  }) => {
    const [isMobile] = useMobile();
    const [rejectReasonState, setRejectReasonState] = useState({});
    const [comment, setComment] = useState('');
    const [showRejectReason, setShowRejectReason] = useState(true);
    const [showImageSelectionModal, setShowImageSelectionModal] =
      useState(false);
    const [showImageListModal, setShowImageListModal] = useState(false);
    const [showCamera, setShowCamera] = useState(false);
    const [cameraImageData, setCameraImageData] = useState('');
    const [images, setImages] = useState([]);
    const [localImages, setLocalImages] = useState([]);
    const hiddenFileInput = React.useRef(null);
    const [cameraFacingMode, setCameraFacingMode] = useState('environment');
    const [cameraModeStyle] = useState({
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      position: 'fixed',
      zIndex: '100000',
      height: '100%',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.8)',
    });

    const handleUploadInputChange = useCallback(
      async event => {
        const files = event.target.files;
        const readFileData = async file => {
          return await new Promise(resolve => {
            const reader = new FileReader();
            reader.onload = evt => {
              const baseURL = reader.result;
              resolve(baseURL);
            };
            reader.readAsDataURL(file);
          });
        };
        const newImages = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[`${i}`];
          if (
            ['jpeg', 'png', 'jpg'].filter(val => file.type.indexOf(val) >= 0)
              .length === 0
          ) {
            continue;
          }
          const imageData = await readFileData(file);
          newImages.push({
            data: imageData,
            name: file.name,
            type: file.type,
          });
        }
        if (newImages.length > 0) {
          setLocalImages(i => {
            return [...i, ...newImages];
          });
          setShowImageListModal(true);
          setShowImageSelectionModal(false);
        }
      },
      [setLocalImages, setShowImageListModal, setShowImageSelectionModal],
    );
    return (
      <Fragment>
        {showImageListModal && (
          <>
            <ImageListModal
              images={localImages}
              onImageUploadClick={() => {
                hiddenFileInput.current.click();
                setShowImageSelectionModal(false);
              }}
              onImageUpdate={imageList => {
                setLocalImages(imageList);
              }}
              onNewImage={() => {
                setShowImageSelectionModal(true);
                setShowImageListModal(false);
              }}
              onConfirmCb={() => {
                setImages(localImages);
              }}
              onCloseCb={() => {
                setShowImageSelectionModal(false);
                setShowImageListModal(false);
                setShowRejectReason(true);
              }}
            />
            <input
              type='file'
              accept='image/png, image/gif, image/jpeg'
              ref={hiddenFileInput}
              onChange={handleUploadInputChange}
              style={{ display: 'none' }}
              multiple
            />
          </>
        )}
        {showCamera && cameraImageData !== '' && (
          <div
            className='p-32 track-trace-camera-image'
            style={cameraModeStyle}
          >
            <div className='w-100 text-center'>
              <img
                style={{
                  height: 'calc(100vh - 100px)',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                }}
                src={cameraImageData}
                alt='camera'
              />
              <div className='d-flex justify-content-end track-trace-camera-image-action'>
                <div className='px-16 py-16'>
                  <div
                    className='btn btn-outline-primary'
                    onClick={() => {
                      setCameraImageData('');
                    }}
                  >
                    Delete
                  </div>
                </div>
                <div className='px-16 py-16'>
                  <div
                    className='btn btn-primary'
                    onClick={() => {
                      setLocalImages(imageList => {
                        return [
                          ...imageList,
                          {
                            data: cameraImageData,
                            name: 'Image ' + (imageList.length + 1),
                            type: 'image/png',
                          },
                        ];
                      });
                      setCameraImageData('');
                      setShowCamera(false);
                      setShowImageListModal(true);
                    }}
                  >
                    Accept
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showCamera && cameraImageData === '' && (
          <div className='d-flex' style={cameraModeStyle}>
            <div className='m-auto'>
              <Camera
                style={{ margin: 'auto' }}
                onTakePhoto={dataUri => {
                  setCameraImageData(dataUri);
                }}
                idealFacingMode={cameraFacingMode}
                isFullscreen={true}
              />
            </div>
            <div
              className='cursor-pointer'
              style={{
                position: 'absolute',
                right: '16px',
                top: '16px',
                padding: '8px',
                backgroundColor: 'rgba(0, 0, 0,0.2)',
                borderRadius: '32px',
              }}
            >
              <img
                src={CloseIcon}
                width='32px'
                alt='Close'
                onClick={() => {
                  setShowCamera(false);
                  setShowImageListModal(true);
                  setShowImageSelectionModal(false);
                }}
              />
            </div>
            <div
              className='cursor-pointer'
              style={{
                position: 'absolute',
                right: '48px',
                bottom: '48px',
                backgroundColor: 'rgba(255, 255, 255,0.3)',
              }}
            >
              <RefreshIcon
                onClick={() => {
                  setCameraFacingMode(val =>
                    val === 'environment' ? 'user' : 'environment',
                  );
                }}
              />
            </div>
          </div>
        )}
        {showImageSelectionModal && (
          <div>
            <Modal
              centered={!isMobile}
              size={'md'}
              show={showModal}
              onHide={() => {
                setShowCamera(false);
                setShowRejectReason(true);
              }}
              dialogClassName='track-trace-dialog'
            >
              <Modal.Header
                className='px-32'
                style={{ borderBottom: '0px' }}
                closeButton
              ></Modal.Header>
              <Modal.Body className='px-32 py-0'>
                <div
                  className='text-center'
                  style={{ fontSize: '24px', color: '#323232' }}
                >
                  Add Image
                </div>
                <div className='text-center p-32'>
                  <div className='pb-8'>
                    <div
                      className='btn btn-link'
                      onClick={() => {
                        //setShowImageModal(false);
                        setShowCamera(true);
                      }}
                    >
                      Open Camera
                    </div>
                  </div>
                  <div
                    className='pt-8'
                    style={{ borderTop: '1px #D8D8D8 solid' }}
                  >
                    <div
                      className='btn btn-link'
                      onClick={() => {
                        hiddenFileInput.current.click();
                      }}
                    >
                      Upload Image
                    </div>
                    <input
                      type='file'
                      accept='image/png, image/gif, image/jpeg'
                      ref={hiddenFileInput}
                      onChange={handleUploadInputChange}
                      style={{ display: 'none' }}
                      multiple
                    />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}

        <RejectReasonListModal
          scanCode={scanCode}
          isMobile={isMobile}
          showModal={showRejectReason}
          onCloseCb={onCloseCb}
          setShowImageListModal={setShowImageListModal}
          setShowRejectReason={setShowRejectReason}
          rejectReasons={rejectReasons}
          setRejectReasonState={setRejectReasonState}
          rejectReasonState={rejectReasonState}
          images={images}
          comment={comment}
          setComment={setComment}
          onConfirmCb={onConfirmCb}
          lastScan={lastScan}
          setShowImageSelectionModal={setShowImageSelectionModal}
        />
      </Fragment>
    );
  },
);

RejectReasonModal.propTypes = {
  scanCode: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  onCloseCb: PropTypes.func.isRequired,
  onConfirmCb: PropTypes.func.isRequired,
};

export default RejectReasonModal;
