import React, { useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { useKeycloak } from '@react-keycloak/web';

import { actions, Message } from '@naadi/framework';
import Routes from './routes/index';
import ScreenLoader from './components/common/screen-loader';
import 'react-toastify/dist/ReactToastify.css';
import useNetworkStatus from './components/common/netowrk-status';

const App = props => {
  const dispatch = useDispatch();

  //const [sync, setSync] = useState(false);

  const { locale, messages } = useSelector(o => o.lang);

  const { initialized } = useKeycloak();

  const { isOnline } = useNetworkStatus();

  useEffect(() => {
    dispatch(actions.lang.change('en'));
  }, [dispatch]);

  return (
    <React.StrictMode>
      {!isOnline || initialized ? (
        <IntlProvider locale={locale} messages={messages || ''}>
          <Suspense fallback={<ScreenLoader force={true} />}>
            <Routes />
          </Suspense>
        </IntlProvider>
      ) : (
        <Message heading='Loading'>
          Please wait, we are getting things ready for you!
        </Message>
      )}
    </React.StrictMode>
  );
};

export default App;
