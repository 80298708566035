import React, { Fragment, useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '../../../assets/icons';
import { setPsShowChart } from '../../../store/order/orderstate/orderstate';
import { useDispatch } from 'react-redux';
import {
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  BarChart,
  LabelList,
} from 'recharts';
import moment from 'moment';
const TABLE_ROW_BORDER = 'p-summary-table-row-border';
const PsSummaryHeader = ({ itemType, group, group2, showChart }) => {
  return (
    <Fragment>
      <div
        className='rubik-font d-flex bg-white pt-2 pb-0'
        style={{
          zIndex: '1',
          position: 'sticky',
          top: '0px',
          borderBottom: '1px solid #027aff',
          fontWeight: 'bold',
        }}
      >
        <div
          className='bg-white align-items-center'
          style={{
            width: '200px',
            minWidth: '200px',
            maxWidth: '200px',
            position: 'sticky',
            left: '0px',
          }}
        >
          Period
        </div>

        <div
          style={{
            width: '200px',
            maxWidth: '200px',
            textOverflow: 'ellipsis',
          }}
          className='d-flex align-items-center justify-content-start overflow-hidden'
        >
          ITEM CODE
        </div>

        <div
          style={{ width: '200px', textOverflow: 'ellipsis' }}
          className='d-flex align-items-center justify-content-start overflow-hidden'
        >
          {group}
        </div>

        <div
          style={{
            width: '200px',
            maxWidth: '200px',
            textOverflow: 'ellipsis',
          }}
          className='d-flex align-items-center justify-content-start overflow-hidden '
        >
          {group2}
        </div>

        <div
          style={{ minWidth: '100px', maxWidth: '100px' }}
          className='d-flex align-items-center justify-content-end'
        >
          Qty
        </div>
        <div
          style={{ minWidth: '100px', maxWidth: '100px' }}
          className='d-flex justify-content-end'
        >
          <div>Total</div>
        </div>
      </div>
    </Fragment>
  );
};

export const getGroupDesc = (ps, group) => {
  switch (group) {
    case 'ORDER':
      return ps.order_ref_code;
    case 'PARENT_ORDER':
      return ps.parent_order_ref_code;
    case 'EXT_REFERENCE':
      return ps.order_ext_ref_code;
    case 'SECONDARY_CUSTOMER':
      return ps.order_secondary_customer;
    case 'CUSTOMER':
      return ps.entity_name;
    case 'POD':
      return ps.pod_name;
    case 'ITEM_CODE':
      return ps.item_code;
    case 'MATERIAL':
      return ps.material;
    case 'EDGE':
      return ps.edge;
    default:
      return '';
  }
};
const PsGroupSummaryLine = ({ summary }) => {
  return (
    <Fragment>
      <div
        className={`d-flex bg-white pt-0 pb-0 ${TABLE_ROW_BORDER}`}
        style={{
          left: '0px',
        }}
      >
        <div
          className='bg-white d-flex '
          style={{
            width: '200px',
            minWidth: '200px',
            maxWidth: '200px',
            textOverflow: 'ellipsis',
          }}
        >
          <div className='w-100 pt-2 pb-3 p-summary-table-col-r-border'>
            <div>{summary.label}</div>
            <div>({summary.qty})</div>
          </div>
        </div>
        <div
          className='p-summary-table-col-l-border p-summary-table-col-r-border'
          style={{ width: '800px', maxWidth: '800px' }}
        >
          {summary?.list.map((itemTypeSummary, itemTypeSummaryIndex) => {
            const itemSummaryBC =
              itemTypeSummaryIndex < summary?.list.length - 1
                ? TABLE_ROW_BORDER
                : '';
            return (
              <div
                key={itemTypeSummary.key}
                className={`w-100 d-flex py-0 justify-content-lg-start ${itemSummaryBC}`}
              >
                <div
                  style={{ width: '200px', maxWidth: '200px' }}
                  className='d-flex justify-content-start '
                >
                  <div className='pb-2 pt-2 '>
                    <div>{itemTypeSummary.key}</div>
                    <div>({itemTypeSummary.qty})</div>
                  </div>
                </div>
                <div
                  className='p-summary-table-col-l-border'
                  style={{ width: '600px', maxWidth: '600px' }}
                >
                  {itemTypeSummary.list.map((groupSummary, gsIndex) => {
                    const gsBC =
                      gsIndex < itemTypeSummary?.list.length - 1
                        ? TABLE_ROW_BORDER
                        : '';
                    return (
                      <div
                        className={`w-100 py-0  d-flex ${gsBC}`}
                        key={groupSummary.key}
                      >
                        <div
                          style={{ width: '200px', maxWidth: '200px' }}
                          className='d-flex justify-content-start '
                        >
                          <div className='pt-2 pb-2 '>
                            {groupSummary.key ? groupSummary.key : '-'}
                          </div>
                        </div>
                        <div
                          className='p-summary-table-col-l-border'
                          style={{ width: '300px', maxWidth: '300px' }}
                        >
                          {groupSummary.list.map((group2Summary, gs2Index) => {
                            const gs2BC =
                              gs2Index < groupSummary?.list.length - 1
                                ? TABLE_ROW_BORDER
                                : '';
                            return (
                              <div
                                className={`w-100 d-flex ${gs2BC}`}
                                key={group2Summary.key}
                              >
                                <div
                                  style={{ width: '200px', maxWidth: '200px' }}
                                  className='d-flex py-0 justify-content-start '
                                >
                                  <div className='px-2 py-2 '>
                                    {group2Summary.key
                                      ? group2Summary.key
                                      : '-'}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    minWidth: '100px',
                                    maxWidth: '100px',
                                  }}
                                  className='d-flex justify-content-end py-2 '
                                >
                                  {group2Summary.qty}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div
                          style={{
                            minWidth: '100px',
                            maxWidth: '100px',
                          }}
                          className='d-flex p-summary-table-col-l-border  justify-content-end py-2 '
                        >
                          {groupSummary.qty}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

const ProductionSummaryChart = ({ showChart, summaries }) => {
  const dispatch = useDispatch();
  return (
    <div>
      <div className='d-flex justify-content-end'>
        <div
          onClick={() => {
            dispatch(setPsShowChart(showChart !== true));
          }}
        >
          {showChart === true ? (
            <ChevronUpIcon fill={'#006EED'} />
          ) : (
            <ChevronDownIcon fill={'#006EED'} />
          )}
        </div>
      </div>
      {showChart === true && (
        <div className='d-flex'>
          <ResponsiveContainer width='100%' minHeight='300px'>
            <BarChart
              width={500}
              height={300}
              data={summaries}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='desc' />
              <YAxis />
              <Tooltip />
              <Bar dataKey='qty' stackId='a' fill='#8884d8'>
                <LabelList dataKey='qty' position='top' />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};
export const ProductionSummaryContainer = ({
  group,
  group2,
  psSummaries,
  showChart,
  operationCode,
  onDetail,
  consolidationTypes,
  itemType,
}) => {
  const [graphSummary, setGraphSummary] = useState([]);
  const [groupSummary, setGroupSummary] = useState([]);
  useEffect(() => {
    const descMap = {};
    const groupSummaryMap = {};
    psSummaries.forEach(val => {
      if (!descMap[`${val.desc}`]) {
        descMap[`${val.desc}`] = {
          desc: val.desc,
          qty: 0,
        };
      }
      descMap[`${val.desc}`].qty += val.qty;
      if (!groupSummaryMap[`${val.period}`]) {
        groupSummaryMap[`${val.period}`] = {
          key: val.period,
          label: val.desc,
          qty: 0,
          list: {},
        };
      }

      const periodSummary = groupSummaryMap[`${val.period}`];
      periodSummary.qty += val.qty;
      const _itemType = itemType === 'ALL' ? itemType : val.item_code;
      if (!periodSummary.list[`${_itemType}`]) {
        periodSummary.list[`${_itemType}`] = {
          key: _itemType,
          qty: 0,
          list: {},
        };
      }
      const _group = group ? getGroupDesc(val, group) : 'NONE';
      const _group2 = group2 ? getGroupDesc(val, group2) : 'NONE';
      const itemTypeSummary = periodSummary.list[`${_itemType}`];
      itemTypeSummary.qty += val.qty;
      if (!itemTypeSummary.list[`${_group}`]) {
        itemTypeSummary.list[`${_group}`] = {
          key: _group,
          qty: 0,
          list: {},
        };
      }
      const groupSummary = itemTypeSummary.list[`${_group}`];
      groupSummary.qty += val.qty;
      if (!groupSummary.list[`${_group2}`]) {
        groupSummary.list[`${_group2}`] = {
          key: _group2,
          qty: 0,
          list: {},
        };
      }
      const groupSummary2 = groupSummary.list[`${_group2}`];
      groupSummary2.qty += val.qty;
    });
    const gSum = Object.values(descMap);
    setGraphSummary(gSum);
    const gSummary = Object.keys(groupSummaryMap).map(periodKey => {
      const periodSummary = groupSummaryMap[`${periodKey}`];
      const periodList = Object.keys(periodSummary.list).map(itemTypeKey => {
        const itemTypeSummary = periodSummary.list[`${itemTypeKey}`];
        const itemSummaryList = Object.keys(itemTypeSummary.list).map(
          groupSummaryKey => {
            const groupSummary = itemTypeSummary.list[`${groupSummaryKey}`];
            const groupSummaryList = Object.keys(groupSummary.list).map(
              group2SummaryKey => {
                const group2Summary = groupSummary.list[`${group2SummaryKey}`];
                return { ...group2Summary, list: [] };
              },
            );
            groupSummaryList.sort((a, b) => {
              return a.key.localeCompare(b.key);
            });
            return { ...groupSummary, list: groupSummaryList };
          },
        );
        itemSummaryList.sort((a, b) => {
          return a.key.localeCompare(b.key);
        });
        return { ...itemTypeSummary, list: itemSummaryList };
      });
      periodList.sort((a, b) => {
        return a.key.localeCompare(b.key);
      });
      return { ...periodSummary, list: periodList };
    });
    gSummary.sort((a, b) => {
      return moment(b.key).isAfter(moment(a.key));
    });
    setGroupSummary(gSummary);
  }, [psSummaries, setGraphSummary, itemType, group, group2, setGroupSummary]);
  return (
    <Fragment>
      <div
        className=''
        style={{
          paddingTop: '0px !important',
          width: '100%',
          position: 'relative',
          height: 'calc(100vh - 200px)',
          overflow: 'scroll',
        }}
      >
        <ProductionSummaryChart
          summaries={graphSummary}
          showChart={showChart}
        />
        <div
          className='font-weight-bold pt-3 table'
          style={{ minWidth: '1000px', height: '100%' }}
        >
          <PsSummaryHeader
            group={group}
            group2={group2}
            itemType={itemType}
            showChart={showChart}
          />
          {groupSummary.map(summary => {
            return (
              <Fragment key={summary.key}>
                <PsGroupSummaryLine
                  summary={summary}
                  group={group}
                  group2={group2}
                  itemType={itemType}
                  consolidationTypes={consolidationTypes}
                  onDetail={onDetail}
                />
              </Fragment>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};
