import { getAttachmentClaim } from '../../services/attachments';
import { ResponseCodes, actions } from '@naadi/framework';
import { addImageLinks } from '../../store/tracktrace/stationstate';
import { getState } from '../../store';
import { getOrgUserConfig, OrgUserConfigTypes } from '../../services/org';
import { getUuid, toastError, toastSuccess } from '../packing/packingHelper';
import {
  addOfflineScan,
  addOrderItems,
  removeOfflineScan,
  setItemFieldConfig,
  setOrderItemsList,
  setOrderItemSyncLock,
  setOrderItemSyncTime,
  setSelectedRecord,
} from '../../store/tracktrace/tracktrace';
import {
  addChallenge,
  setProcessedOrderItems,
} from '../../store/tracktrace/tracktracestateless';
import { union, cloneDeep, isEqual } from 'lodash';
import {
  addScanRecord,
  DEFAULT_ITEM_FEATURES,
  executeMachineSync,
  getItemsWithFilter,
  getItemsWithFilterChunked,
  getOperationRuleVerification,
  getOperationStatus,
  getRecentScans,
  getScanRecords,
  getTrackTraceCtxItems,
  trackTraceOfflineReq,
  updateBulkOperationStatus,
  updateOperationStatus,
} from '../../services/order';
import { getResponseErrorMessage } from '../common/helper';
import moment from 'moment';
import {
  createNewAttachmentService,
  uploadBase64File,
} from '../../services/upload';
import { trackTraceBusy } from '../../store/tracktrace/tracktracestateless';

export const getScanStateClass = scanState => {
  switch (scanState) {
    case 'error':
      return 'error-scan-background';
    case 'warn':
      return 'warning-scan-background';
    case 'success':
      return 'success-scan-background';
    default:
      return 'bg-white';
  }
};
export const getOrderItemImageLinks = async (orderItem, dispatch) => {
  const imageLinks = [];
  const image_links = getState().stationstate.image_links || {};
  if (
    !orderItem ||
    !orderItem.item_definition ||
    !orderItem.item_definition.definition ||
    !orderItem.item_definition.definition.IMAGES ||
    !orderItem.item_definition.definition.IMAGES.val
  ) {
    return imageLinks;
  }
  let images = orderItem.item_definition.definition.IMAGES.val;
  if (typeof images === 'string') {
    images = images.split('|');
  }
  const dispatchLinks = [];
  for (let i = 0; i < images.length; i++) {
    const image = images[`${i}`];
    if (!image) continue;
    if (image_links[`${image}`]) {
      const link = image_links[`${image}`];
      imageLinks.push(link['link']);
      continue;
    }
    let itemClaimResp = await getAttachmentClaim(
      'ITEM_LINKS',
      'item_service',
      'IMAGES',
      image,
    );
    const addEmptyImage = [];
    if (itemClaimResp.status === 200) {
      const payload = itemClaimResp.payload;
      imageLinks.push(payload.attachment.signed_url);
      dispatchLinks.push({
        attachment_id: payload.attachment.uuid,
        link: payload.attachment.signed_url,
      });
    } else if (itemClaimResp.status === 10002) {
      addEmptyImage.push({
        attachment_id: image,
        link: '',
      });
      /*dispatchLinks.push({
        attachment_id: image,
        link: '',
      });*/
    }
    itemClaimResp = await getAttachmentClaim(
      'ORDER_ITEM_IMAGE',
      'order_service',
      orderItem.order_src_ref_id,
      image,
    );
    if (itemClaimResp.status === 200) {
      const payload = itemClaimResp.payload;
      imageLinks.push(payload.attachment.signed_url);
      dispatchLinks.push({
        attachment_id: payload.attachment.uuid,
        link: payload.attachment.signed_url,
      });
    }
    if (addEmptyImage.length > 0 && dispatchLinks.length === 0) {
      dispatchLinks.push(addEmptyImage[0]);
    }
  }
  if (dispatchLinks.length > 0) {
    dispatch(addImageLinks(dispatchLinks));
  }
  return imageLinks;
};
export const getScannedRecordImageLinks = async (scanRecord, dispatch) => {
  if (!scanRecord.order_item) {
    return [];
  }
  return getOrderItemImageLinks(scanRecord.order_item, dispatch);
};
export const getItemFieldConfigKey = order_item => {
  return order_item.org_id + '::' + order_item.item_id;
};
export const getItemFieldConfig = (item_field_config, order_item) => {
  if (order_item.item_field_config) {
    return order_item.item_field_config;
  }
  if (!item_field_config || !order_item) {
    return {
      fields: [],
      display_fields: {},
    };
  }
  return (
    item_field_config[`${getItemFieldConfigKey(order_item || {})}`] || {
      fields: [],
      display_fields: {},
    }
  );
};
export const populateItemFieldConfig = async (orderItem, dispatch) => {
  const { item_field_config } = getState().tracktrace;
  if (orderItem === null) {
    return;
  }
  const key = getItemFieldConfigKey(orderItem);
  if (!item_field_config[`${key}`]) {
    const configKeyResp = await getOrgUserConfig(
      OrgUserConfigTypes.ITEM_DISPLAY_FIELDS,
      orderItem.item_id,
    );
    if (
      configKeyResp.status !== 200 &&
      configKeyResp.status !== ResponseCodes.RECORD_NOT_FOUND_EXCEPTION
    ) {
      return;
    }
    const fieldConfig =
      configKeyResp.status === 200
        ? configKeyResp.payload.config_val
        : {
            fields: [],
            display_fields: {},
          };
    dispatch(
      setItemFieldConfig({
        [key]: fieldConfig,
      }),
    );
  }
};
/* eslint-disable sonarjs/cognitive-complexity */
export const populateOrderItemStatus = async (
  dispatch,
  orderItems,
  metaData,
) => {
  if (!metaData.operation_status) {
    metaData.operation_status = {};
  }
  const orderItemMap = {};
  orderItems.map(val => {
    val.operation_status = {};
    val.operations = [];
    val.box_items = val.box_items || [];
    if (!val.operation_checklist) {
      val.operation_checklist = [];
    }
    orderItemMap[getItemFieldConfigKey(val)] = val;
    return val;
  });
  for (const key in orderItemMap) {
    await populateItemFieldConfig(orderItemMap[`${key}`], dispatch);
  }
  const operationStatusMap = metaData.operation_status;
  orderItems.forEach(val => {
    val.status = 'PENDING';
    val.processed_on = new Date(0);
    if (operationStatusMap[`${val.uuid}`]) {
      const _operations = operationStatusMap[`${val.uuid}`];
      const operations = _operations
        .filter(
          operationVal =>
            operationVal.operation_code !== 'TOP_DRILL' || hasTopDrill(val),
        )
        .filter(
          operationVal =>
            operationVal.operation_code !== 'BOTTOM_DRILL' ||
            hasBottomDrill(val),
        )
        .filter(operationVal => operationVal.skip_operation !== true);
      if (operations.length === 0) {
        val.status = 'NO_OPERATION';
        return val;
      }
      val.operations = operations;
      const operationStatus = {};
      const statusMap = {
        PENDING: 0,
        SUCCESS: 0,
        REJECTED: 0,
        NO_OPERATION: 0,
        processed_on: new Date(0),
      };
      let total = 0;
      operations.map(val => {
        statusMap[`${val.status}`]++;
        total++;
        if (moment(val.processed_on).isAfter(moment(statusMap.processed_on))) {
          statusMap.processed_on = val.processed_on;
        }
        operationStatus[`${val.operation_code}`] = val;
        return val;
      });
      val.operation_status = operationStatus;
      val.processed_on = statusMap.processed_on;
      if (statusMap.REJECTED > 0) {
        val.status = 'REJECTED';
      } else if (statusMap.SUCCESS === total) {
        val.status = 'SUCCESS';
      } else {
        val.status = 'PENDING';
      }
      return val;
    } else {
      val.status = 'NO_OPERATION';
    }
    return val;
  });
  return orderItems;
};
export const syncOrderItemCtx = async (
  dispatch,
  showLoader,
  appendScannedItemsCb,
) => {
  const { order_item_sync_lock, order_item_sync_ctx, order_item_sync_time } =
    getState().tracktrace;
  const { current_station } = getState().stationstate;
  if (order_item_sync_lock) {
    console.log('Sync is Locked..Hence skip Sync');
    return false;
  }
  if (
    !current_station ||
    !current_station.operations ||
    current_station.operations.length === 0
  ) {
    console.log('Skip Refresh as the station does not have any operations');
    return false;
  }
  try {
    dispatch(setOrderItemSyncLock(true));
    dispatch(trackTraceBusy.add('FETCH_ORDER_ITEMS'));
    if (showLoader) {
      dispatch(actions.busy.add('FETCH_ORDER_ITEMS'));
    }
    const _ctxKey = getTrackTraceCtxKey();
    if (
      !_ctxKey ||
      order_item_sync_ctx === null ||
      order_item_sync_ctx.key === null ||
      order_item_sync_ctx.key === ''
    ) {
      return false;
    }
    const filter = {
      req_payload: {
        deleted: [false],
        released: [true],
        fetch_order_item_status: true,
        fetch_box: true,
        feature: DEFAULT_ITEM_FEATURES,
        scan_status_operation_codes: current_station.operations,
        checklist_stations: current_station.operations,
        modified_after: new Date(order_item_sync_time || 0),
        item_type: current_station.supported_item_type || [],
      },
    };
    //let ctxVal = '';
    if (order_item_sync_ctx.type === 'ORDER') {
      filter.req_payload.order_id = [order_item_sync_ctx.key];
      //ctxVal = order_item_sync_ctx.key;
    } else if (order_item_sync_ctx.type === 'PARENT_ORDER') {
      filter.req_payload.parent_order_code = [order_item_sync_ctx.code];
      //ctxVal = order_item_sync_ctx.key;
    } else if (order_item_sync_ctx.type === 'CUSTOMER') {
      filter.req_payload.entity_id = [order_item_sync_ctx.key];
      //ctxVal = order_item_sync_ctx.key;
    } else if (order_item_sync_ctx.type === 'ORDER_EXTERNAL_REFERENCE') {
      filter.req_payload.ext_code = [order_item_sync_ctx.key];
      //ctxVal = order_item_sync_ctx.key;
    } else if (order_item_sync_ctx.type === 'SECONDARY_CUSTOMER') {
      filter.req_payload.secondary_customer = [order_item_sync_ctx.key];
      //ctxVal = order_item_sync_ctx.key;
    } else if (order_item_sync_ctx.type === 'WORK_ORDER') {
      filter.req_payload.order_id = [order_item_sync_ctx.key];
      filter.req_payload.feature = [
        ...DEFAULT_ITEM_FEATURES,
        'LENGTH',
        'WIDTH',
        'MATERIAL',
      ];
      //ctxVal = order_item_sync_ctx.key;
    } else {
      return false;
    }
    filter.page = 0;
    filter.size = 150;
    const chunk = 5;
    do {
      const orderItemsResp = await getItemsWithFilterChunked(filter, chunk);
      if (orderItemsResp.status !== 200) {
        console.log(orderItemsResp);
        return false;
      }
      if (orderItemsResp.payload.length === 0) {
        break;
      }
      const lastFetched = orderItemsResp.meta_data.last_modified;

      const orderItems = await populateOrderItemStatus(
        dispatch,
        orderItemsResp.payload,
        orderItemsResp.meta_data,
      );
      const scannedItems = orderItems.map(val => {
        return getScannedRecordFromOrderItem(val, val.status, val.processed_on);
      });
      const _order_item_sync_ctx = getState().tracktrace.order_item_sync_ctx;
      const ctxKey = getTrackTraceCtxKey();
      if (ctxKey !== _ctxKey) {
        return false;
      }
      if (
        _order_item_sync_ctx &&
        order_item_sync_ctx &&
        order_item_sync_ctx.key === _order_item_sync_ctx.key
      ) {
        dispatch(addOrderItems(orderItems));
        const syncTime = moment(lastFetched).toDate().getTime();
        dispatch(setOrderItemSyncTime(syncTime));
        appendScannedItemsCb(scannedItems);
      }
      filter.page += chunk;
    } while (true);
  } finally {
    dispatch(setOrderItemSyncLock(false));
    dispatch(trackTraceBusy.remove('FETCH_ORDER_ITEMS'));
    if (showLoader) {
      dispatch(actions.busy.remove('FETCH_ORDER_ITEMS'));
    }
  }
};
/* eslint-enable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/cognitive-complexity */
export const populateScanRecordOrderItemStatus = async (
  scanRecords,
  current_station,
  dispatch,
  operationStatusList,
) => {
  scanRecords.forEach(val => {
    if (!val.operation_status) {
      val.operation_status = {};
    }
    if (!val.operations) {
      val.operations = [];
    }
  });
  const orderItemIds = union(
    scanRecords
      .filter(val => val.order_item && val.order_item.uuid)
      .map(val => val.order_item.uuid),
    [],
  );
  if (
    current_station.operations &&
    current_station.operations.length > 0 &&
    orderItemIds.length > 0
  ) {
    let size = current_station.operations.length * orderItemIds.length;

    const operationStatusRequest = {
      size: size,
      page: 0,
      req_payload: {
        operation_code: current_station.operations,
        deleted: [false],
        order_item_id: orderItemIds,
      },
    };
    if (!operationStatusList) {
      const operationStatusResp = await getOperationStatus(
        operationStatusRequest,
      );
      if (operationStatusResp.status !== 200) {
        return {
          updated: false,
          scan_records: [],
        };
      }
      operationStatusList = operationStatusResp.payload;
    }

    const orderItemStatus = {};
    operationStatusList.forEach(val => {
      val.order_item_id = val.order_item.uuid;
      if (val.order_item && val.order_item.released === false) {
        return;
      }
      delete val.order_item;
      if (!orderItemStatus[`${val.order_item_id}`]) {
        orderItemStatus[`${val.order_item_id}`] = {};
      }
      orderItemStatus[`${val.order_item_id}`][`${val.operation_code}`] = val;
    });
    scanRecords.forEach(val => {
      if (val.order_item && val.order_item.status) {
        val.status = val.order_item.status;
      }
      if (
        val.order_item &&
        val.order_item.uuid &&
        orderItemStatus[`${val.order_item.uuid}`]
      ) {
        val.operation_status = orderItemStatus[`${val.order_item.uuid}`];
      }
    });
    const modifiedOrderItem = [];
    for (let i = 0; i < scanRecords.length; i++) {
      const scanRecord = scanRecords[`${i}`];
      const imageLinks = await getScannedRecordImageLinks(scanRecord, dispatch);
      scanRecord.images = imageLinks;
      const scanRecordOperationStatus = current_station.operations.map(
        operation => {
          if (
            scanRecord.operation_status &&
            scanRecord.operation_status[`${operation}`]
          ) {
            return scanRecord.operation_status[`${operation}`].status;
          }
          return 'UNKNOWN';
        },
      );
      const scanRecordOperationStatusSet = [
        ...new Set(scanRecordOperationStatus),
      ];
      if (
        scanRecordOperationStatusSet.length === 1 &&
        scanRecord.order_item.status !== scanRecordOperationStatusSet[0]
      ) {
        scanRecord.order_item = cloneDeep(scanRecord.order_item);
        scanRecord.order_item.status = scanRecordOperationStatusSet[0];
        let orderItem = getState().tracktrace.order_items.filter(
          val => val.uuid === scanRecord.order_item.uuid,
        )[0];
        if (orderItem && orderItem.status !== scanRecord.order_item.status) {
          orderItem = cloneDeep(orderItem);
          orderItem.status = scanRecord.order_item.status;
          modifiedOrderItem.push(orderItem);
        }
      }
    }
    if (modifiedOrderItem.length > 0) {
      dispatch(addOrderItems(modifiedOrderItem));
    }
  }
  return {
    updated: true,
    scan_records: scanRecords,
  };
};
/* eslint-enable sonarjs/cognitive-complexity */
export const getOrderItemDrillsStatus = scanRecord => {
  const status = {
    top_drill: {
      exists: false,
      status: 'PENDING',
      scan_code: null,
    },
    bottom_drill: {
      exists: false,
      status: 'PENDING',
      scan_code: null,
    },
  };
  if (!scanRecord) {
    return status;
  }
  if (!scanRecord.order_item) {
    return status;
  }
  if (
    !scanRecord.order_item.item_definition ||
    !scanRecord.order_item.item_definition.definition
  ) {
    return status;
  }
  const definition = scanRecord.order_item.item_definition.definition;
  if (
    definition.TOP_DRILL &&
    definition.TOP_DRILL.val &&
    definition.TOP_DRILL.val !== ''
  ) {
    status.top_drill.exists = true;
    status.top_drill.scan_code = definition.TOP_DRILL.val;
  }
  if (
    definition.BOTTOM_DRILL &&
    definition.BOTTOM_DRILL.val &&
    definition.BOTTOM_DRILL.val !== ''
  ) {
    status.bottom_drill.exists = true;
    status.bottom_drill.scan_code = definition.BOTTOM_DRILL.val;
  }
  if (
    status.top_drill.exists &&
    scanRecord.operation_status &&
    scanRecord.operation_status.TOP_DRILL
  ) {
    status.top_drill.status = scanRecord.operation_status.TOP_DRILL.status;
  }
  if (
    status.bottom_drill.exists &&
    scanRecord.operation_status &&
    scanRecord.operation_status.BOTTOM_DRILL
  ) {
    status.bottom_drill.status =
      scanRecord.operation_status.BOTTOM_DRILL.status;
  }
  return status;
};
export const hasCncOperation = (orgOrderItem, currentStation) => {
  let returnVal = false;
  if (!orgOrderItem) {
    returnVal = true;
  } else if (!currentStation || currentStation.station_code !== 'DRILLING') {
    returnVal = true;
  } else {
    const definition =
      orgOrderItem &&
      orgOrderItem.item_definition &&
      orgOrderItem.item_definition.definition
        ? orgOrderItem.item_definition.definition
        : {};
    const bottomDrill = definition.BOTTOM_DRILL ? definition.BOTTOM_DRILL : {};
    const topDrill = definition.TOP_DRILL ? definition.TOP_DRILL : {};
    if (
      (bottomDrill.val && bottomDrill.val.length > 0) ||
      (topDrill.val && topDrill.val.length > 0)
    ) {
      returnVal = true;
    }
  }

  return returnVal;
};
export const filterOrderItemsWithCncOperations = (
  orderItems,
  currentStation,
) => {
  if (!orderItems) {
    return [];
  }
  if (!currentStation || currentStation.station_code !== 'DRILLING') {
    return orderItems.filter(val => val.status !== 'NO_OPERATION');
  }
  return orderItems.filter(val => {
    return (
      val.status === 'REJECTED' ||
      (hasCncOperation(val, currentStation) && val.status !== 'NO_OPERATION')
    );
  });
};
export const getTopDrill = orgOrderItem => {
  if (
    orgOrderItem &&
    orgOrderItem.item_definition &&
    orgOrderItem.item_definition.definition
  ) {
    const topDrill = orgOrderItem.item_definition.definition.TOP_DRILL;
    if (topDrill && topDrill.val && topDrill.val.length > 0) {
      return topDrill.val;
    }
  }
  return null;
};
export const getTopDrillAlt = orgOrderItem => {
  const code = getTopDrill(orgOrderItem);
  if (code && code.length > 0) {
    const topDrill = orgOrderItem.item_definition.definition.TOP_DRILL_ALT;
    if (topDrill && topDrill.val && topDrill.val.length > 0) {
      return topDrill.val;
    }
  }
  return code;
};
export const hasTopDrill = orgOrderItem => {
  return getTopDrill(orgOrderItem) !== null;
};
export const getBottomDrill = orgOrderItem => {
  if (
    orgOrderItem &&
    orgOrderItem.item_definition &&
    orgOrderItem.item_definition.definition
  ) {
    const drill = orgOrderItem.item_definition.definition.BOTTOM_DRILL;
    if (drill && drill.val && drill.val.length > 0) {
      return drill.val;
    }
  }
  return null;
};
export const getBottomDrillAlt = orgOrderItem => {
  const code = getBottomDrill(orgOrderItem);
  if (code && code.length > 0) {
    const drill = orgOrderItem.item_definition.definition.BOTTOM_DRILL_ALT;
    if (drill && drill.val && drill.val.length > 0) {
      return drill.val;
    }
  }
  return code;
};
export const hasBottomDrill = orgOrderItem => {
  return getBottomDrill(orgOrderItem) !== null;
};
const filterCncOperationCodes = (scanCode, orgOrderItem, operationCodes) => {
  const { current_station } = getState().stationstate;
  if (current_station.station_code !== 'DRILLING') {
    return operationCodes;
  }
  const _operationCodes = operationCodes
    .filter(val => val !== 'TOP_DRILL')
    .filter(val => val !== 'BOTTOM_DRILL');
  if (
    orgOrderItem &&
    orgOrderItem.item_definition &&
    orgOrderItem.item_definition.definition
  ) {
    const bottomDrill = orgOrderItem.item_definition.definition.BOTTOM_DRILL;
    const topDrill = orgOrderItem.item_definition.definition.TOP_DRILL;
    if (
      bottomDrill &&
      bottomDrill.val &&
      (bottomDrill.val + '').length > 0 &&
      (bottomDrill.val === scanCode || current_station.auto_operation === true)
    ) {
      _operationCodes.push('BOTTOM_DRILL');
    }
    if (
      topDrill &&
      topDrill.val &&
      (topDrill.val + '').length > 0 &&
      (topDrill.val === scanCode || current_station.auto_operation === true)
    ) {
      _operationCodes.push('TOP_DRILL');
    }
  }
  return _operationCodes;
};
const matchOrderItemsFromResultToScanCode = (items, scanCode) => {
  if (!items || items.length === 0) return null;
  items.sort(function (a, b) {
    return b.id - a.id;
  });
  const codeMatch = items.filter(val => val.code === scanCode);
  if (codeMatch && codeMatch.length === 1) {
    return codeMatch[0];
  }
  const scanAlternatesMatch = items.filter(
    val => val.scan_alternates.indexOf(scanCode) >= 0,
  );
  if (scanAlternatesMatch && scanAlternatesMatch.length === 1) {
    return scanAlternatesMatch[0];
  }
  const uuidMatch = items.filter(val => val.uuid === scanCode);
  if (uuidMatch && uuidMatch.length === 1) {
    return uuidMatch[0];
  }
  if (codeMatch && codeMatch.length > 0) {
    return codeMatch[0];
  }
  if (scanAlternatesMatch && scanAlternatesMatch.length > 0) {
    return scanAlternatesMatch[0];
  }
  return null;
};

const matchOrderItemsFromResultToBoxScanCode = (items, scanCode) => {
  if (!items || items.length === 0) return [];
  return items
    .filter(val => val.box_items && val.box_items.length > 0)
    .filter(
      val =>
        val.box_items.filter(
          boxItem => boxItem.box.code.toLowerCase() === scanCode.toLowerCase(),
        ).length > 0,
    );
};
export const mergeRejectReasons = reasons => {
  const groups = [];
  const groupMap = {};
  reasons.forEach(val => {
    val.forEach(group => {
      if (groups.indexOf(group.group) === -1) {
        groups.push(group.group);
      }
      groupMap[`${group.group}`] = group.reasons;
    });
  });
  return groups.map(val => {
    return {
      group: val,
      reasons: groupMap[`${val}`],
    };
  });
};

export const filterScannedItem = (scanCode, orderItems) => {
  if (!orderItems || orderItems.length === 0) {
    return null;
  }
  if (!scanCode || scanCode === '') {
    return orderItems[0];
  }
  const matchCode = orderItems.filter(val => val.code === scanCode);
  if (matchCode.length > 0) {
    return matchCode[0];
  }
  return orderItems[0];
};

/* eslint-disable sonarjs/cognitive-complexity */

export const getRejectionReasons = async (
  scanCode,
  orderItemId,
  current_station,
  orderItem,
) => {
  if (!orderItem) {
    const orgItemFilter = {
      deleted: [false],
      released: [true],
      scan_code: scanCode,
      features: ['REJECTION_REASONS', 'REJECT_REASON_TEMPLATE'],
      fetch_order_item_status: true,
    };
    if (orderItemId && orderItemId.length > 0) {
      delete orgItemFilter.scan_code;
      orgItemFilter.uuid = [orderItemId];
    }
    const itemFilterResp = await getItemsWithFilter({
      req_payload: orgItemFilter,
    });
    if (itemFilterResp.status !== ResponseCodes.SUCCESS) {
      return {
        error: 'Unable to Fetch the Rejection Reasons',
      };
    }
    const orderItems = itemFilterResp.payload;
    if (orderItems.length === 0) {
      return { error: 'Unable to Fetch any Item for the Code ' + scanCode };
    }
    orderItem =
      orgItemFilter.uuid && orgItemFilter.uuid.length > 0
        ? orderItems[0]
        : filterScannedItem(scanCode, orderItems);
  } else {
    orderItem = cloneDeep(orderItem);
  }

  let reasons = [];

  if (
    orderItem &&
    orderItem.item_definition &&
    orderItem.item_definition.definition &&
    orderItem.item_definition.definition.REJECT_REASON_TEMPLATE &&
    orderItem.item_definition.definition.REJECT_REASON_TEMPLATE.value &&
    orderItem.item_definition.definition.REJECT_REASON_TEMPLATE.value.val
  ) {
    reasons =
      orderItem.item_definition.definition.REJECT_REASON_TEMPLATE.value.val;
  }

  if (
    (!reasons || reasons.length === 0) &&
    orderItem &&
    orderItem.item_definition &&
    orderItem.item_definition.definition &&
    orderItem.item_definition.definition.REJECTION_REASONS &&
    orderItem.item_definition.definition.REJECTION_REASONS.value &&
    orderItem.item_definition.definition.REJECTION_REASONS.value.val &&
    orderItem.item_definition.definition.REJECTION_REASONS.value.val.length > 0
  ) {
    orderItem.item_definition.definition.REJECTION_REASONS.value.val.forEach(
      val => {
        if (val.group) {
          if (val.reasons && val.reasons.length > 0) {
            val.reasons.forEach(reason => {
              reasons.push({
                group: val.group,
                reason: reason,
              });
            });
          }
        } else {
          reasons.push({
            group: 'Other',
            reason: val + '',
          });
        }
      },
    );
  }
  const reasonGroups = [];
  const reasonGroupsMap = {};
  let operationCodes =
    current_station && current_station.operations
      ? current_station.operations
      : [];
  reasons = reasons.filter(val => {
    if (!val.operations || val.operations.length === 0) {
      return true;
    }
    return (
      val.operations.filter(op => operationCodes.indexOf(op) >= 0).length > 0
    );
  });
  reasons.forEach(val => {
    if (reasonGroups.indexOf(val.group) === -1) {
      reasonGroups.push(val.group);
      reasonGroupsMap[`${val.group}`] = [];
    }
    if (!val.reason) {
      val.reason = 'Other';
    }
    if (!val.blame_operations) {
      val.blame_operations = [];
    }
    if (!val.confirm_checklist) {
      val.confirm_checklist = false;
    }
    if (!val.image_reqd) {
      val.image_reqd = false;
    }
    if (!val.operations) {
      val.operations = [];
    }
    if (!val.extra_fields) {
      val.extra_fields = [];
    }
    val.extra_fields = val.extra_fields.filter(
      ef => ef.type && ef.field && ef.display,
    );
    val.blame_history = [];
    reasonGroupsMap[`${val.group}`].push(val);
  });
  const _reasonGroups = reasonGroups.map(val => {
    return {
      group: val,
      reasons: reasonGroupsMap[`${val}`],
    };
  });
  let lastScan = {};
  if (orderItem) {
    let scanHistory = [];
    try {
      const scanRecordResp = await getScanRecords({
        page: 0,
        size: 200,
        req_payload: {
          order_item_id: [orderItem.uuid],
        },
        sort: [
          {
            column: 'updated_on',
            asc: false,
          },
        ],
      });
      if (scanRecordResp.status !== 200) {
        return {
          error: 'Unable to Fetch the item scan history for code ' + scanCode,
        };
      }
      scanHistory = [...scanRecordResp.payload];

      lastScan = scanHistory.filter(val => val.pod !== current_station.uuid)[0];
      if (!lastScan || !lastScan.uuid) {
        lastScan = scanHistory[0] || {};
      }
    } catch (err) {
      console.log(err);
    }

    _reasonGroups.forEach(g => {
      g.reasons.forEach(r => {
        if (r.blame_operations.length > 0) {
          r.blame_history = scanHistory.filter(val => {
            if (!val.operation_codes || val.operation_codes.length === 0) {
              return false;
            }
            const match = val.operation_codes.filter(
              o =>
                r.blame_operations.indexOf(o) >= 0 &&
                operationCodes.indexOf(o) === -1,
            );
            return match.length > 0;
          });
        }
        r.blame_operation = {};
        if (r.blame_history && r.blame_history.length > 0) {
          r.blame_operation = r.blame_history[0];
        }
      });
    });
  }

  return cloneDeep({
    lastScan: lastScan || {},
    reasons: _reasonGroups || [],
    error: null,
  });
};
/* eslint-enable sonarjs/cognitive-complexity */
export const checkIfScanCodeInContext = scanCode => {
  const { order_item_sync_ctx, order_items } = getState().tracktrace;
  if (
    !order_item_sync_ctx ||
    !order_item_sync_ctx.type ||
    !order_item_sync_ctx.key
  ) {
    return {
      match: true,
      type: 'ORDER_ITEM',
    };
  }
  let match = matchOrderItemsFromResultToScanCode([...order_items], scanCode);
  if (match) {
    return {
      match: true,
      type: 'ORDER_ITEM',
      match_item: match,
    };
  }
  const boxMatch = matchOrderItemsFromResultToBoxScanCode(
    [...order_items],
    scanCode,
  );
  return boxMatch && boxMatch.length > 0
    ? {
        match: true,
        type: 'BOX',
        match_item: boxMatch[0],
      }
    : {
        match: false,
      };
};

export const uploadScanImages = async (images, orderId) => {
  const imageAttachments = [];
  if (images && images.length > 0) {
    for (let i = 0; i < images.length; i++) {
      const image = images[`${i}`];
      const imageData = image.data.split(';base64,');
      if (imageData.length !== 2) {
        continue;
      }
      const attachmentResp = await createNewAttachmentService(
        image.name,
        'orders/scanrecords/images/' + orderId,
        image.type,
      );
      if (attachmentResp.status !== ResponseCodes.SUCCESS) {
        //toastError('Unable to Upload the Image. Please retry..');
        return {
          success: false,
          err: 'Unable to Upload the Image. Please retry..',
        };
      }
      const attachment = attachmentResp.payload;

      const uploadResp = await uploadBase64File(attachment.uuid, imageData[1]);
      if (uploadResp.status !== ResponseCodes.SUCCESS) {
        //toastError('Unable to Upload the Image. Please retry..');
        return {
          success: false,
          err: 'Unable to Upload the Image. Please retry..',
        };
      }
      imageAttachments.push(attachment.uuid);
    }
  }
  return {
    success: true,
    images: imageAttachments,
  };
};

export const getOperationChallenge = async (operationCodes, orderItem) => {
  if (!operationCodes || operationCodes.length === 0) {
    return {
      error: null,
      rules: [],
    };
  }
  const filterReq = {
    page: 0,
    size: 100,
    req_payload: {
      deleted: [false],
      released: [true],
      order_item_id: [orderItem.uuid],
      operation_code: operationCodes,
    },
  };
  const rulesResp = await getOperationRuleVerification(filterReq);
  if (rulesResp.status !== ResponseCodes.SUCCESS) {
    return {
      error: 'Unable to Fetch the Operation Rules',
      rules: [],
    };
  }
  const rules = rulesResp.payload;
  return {
    error: null,
    rules: rules,
  };
};
export const trackTraceMultiScan = async (
  matches,
  manualEntry,
  rejection,
  images,
  allOperations,
  dispatch,
  scannedItems,
  appendScannedItemsCb,
  syncCb,
) => {
  const { rejectmode, current_station, station_location } =
    getState().stationstate;
  const location =
    (station_location ? station_location[current_station.uuid] : '') || '';
  let operationCodes = current_station.operations
    ? current_station.operations
    : [];
  const rejected = rejection
    ? rejection.rejected === true
    : rejectmode === true;
  const reqList = matches.map(match => {
    return {
      scan_station: current_station.station_code,
      scan_id: getUuid(dispatch),
      scan_code: match.code,
      pod: current_station.uuid,
      pod_name: current_station.instance_name,
      manual_entry: manualEntry,
      scan_context_type: 'ORDER',
      scan_context_1: match.order_id,
      scan_context_2: match.uuid,
      scan_context_3: match.code,
      req_payload: {
        order_item_id: match.uuid,
        scan_record_id: match.uuid,
        status: rejected ? 'REJECTED' : 'SUCCESS',
        operation_codes: operationCodes,
        processed_on: new Date(),
        rejected: rejected,
        reject_reason:
          rejection && rejection.reasons
            ? rejection.reasons.map(v => v.reason)
            : [],
        comment: rejection && rejection.comment ? rejection.comment : '',
        location: location,
        images: [],
      },
    };
  });
  const operationStatusResp = await updateBulkOperationStatus({
    req_payload: reqList,
  });
  if (operationStatusResp.status !== 200) {
    return {
      error: getResponseErrorMessage(operationStatusResp, 'Unable to Update'),
      success: false,
    };
  }
  const operationStatusRespPayload = operationStatusResp.payload;
  const errorCodes = matches.filter(
    match =>
      !operationStatusRespPayload.success.some(
        success => success.scan_record.scan_code === match.code,
      ),
  );
  if (operationStatusRespPayload.errs.length > 0) {
    if (operationStatusRespPayload.success.length > 0) {
      toastSuccess(
        `Updated ${operationStatusRespPayload.success.length} items`,
      );
    }
    const errorMessages = operationStatusRespPayload.errs.map((err, i) => ({
      err: err.err || 'Failed to update the scan',
      code: errorCodes[`${i}`]?.code,
    }));
    return {
      error: errorMessages,
      success: false,
    };
  }

  if (syncCb) {
    await syncCb();
  }
  return {
    error: null,
    success: true,
    message: rejected ? 'Multi Reject Recorded' : 'Multi Scan Recorded',
    successCount: operationStatusRespPayload.success.length,
  };
};
/* eslint-disable sonarjs/cognitive-complexity */
export const trackTraceOfflineScan = async ({
  ctx,
  current_station,
  location,
  scanCode,
  manualEntry,
  rejectmode,
  rejection,
  images,
  dispatch,
  appendScannedItemsCb,
  syncCb,
}) => {
  const { user } = getState();
  const { org, branch } = user || {};
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  const branchName = branch && branch.branch_name ? branch.branch_name : '';
  const { order_items } = getState().tracktrace;
  const ctxType = ctx && ctx.type ? ctx.type.value || '' : '';
  const ctxVal = !ctx || !ctx.value || !ctx.value.key ? '' : ctx.value.key;
  let operationCodes = current_station.operations
    ? current_station.operations
    : [];
  const rejected = rejection
    ? rejection.rejected === true
    : rejectmode === true;
  if (
    rejectmode === true ||
    rejected === true ||
    operationCodes.length === 0 ||
    !current_station.allow_offline_scan
  ) {
    return {
      supported: false,
    };
  }
  let matchPayload = [];
  if (
    ctx &&
    ctx.type &&
    [
      'ORDER',
      'PARENT_ORDER',
      'ORDER_EXTERNAL_REFERENCE',
      'SECONDARY_CUSTOMER',
    ].indexOf(ctx.type.value) >= 0 &&
    ctx.value &&
    ctx.value.key &&
    order_items &&
    order_items.length > 0
  ) {
    matchPayload = [...order_items];
  }

  const offlineScanReqPayload = {
    scan_code: scanCode,
    station_instance: current_station.uuid,
    scan_ctx: ctxVal,
    scan_ctx_type: ctxType,
    operation_codes: operationCodes,
    manual_entry: manualEntry,
    scanned_on: new Date(),
    allow_ctx_mismatch: false,
  };
  const offlineScanData = { ...offlineScanReqPayload };
  const match = matchOrderItemsFromResultToScanCode(matchPayload, scanCode);
  if (match) {
    offlineScanData.item_code = match.code;
    offlineScanData.match_type = 'ORDER_ITEM';
    offlineScanData.order_ref_code = (match.order || {}).ref_code;
    offlineScanData.order_secondary_customer = (
      match.order || {}
    ).secondary_customer;
  } else {
    const matches = matchOrderItemsFromResultToBoxScanCode(
      matchPayload,
      scanCode,
    );
    if (matches && matches.length > 0) {
      const boxItemMatch = matches[0].box_items[0];
      offlineScanData.match_type = 'BOX';
      offlineScanData.box_index = boxItemMatch.box.box_index;
      offlineScanData.order_ref_code = (matches[0].order || {}).ref_code;
      offlineScanData.order_secondary_customer = (
        matches[0].order || {}
      ).secondary_customer;
    }
  }

  offlineScanData.branch_id = branchId;
  offlineScanData.org_id = org.uuid;
  offlineScanData.branch_name = branchName;
  offlineScanData.ctx_type =
    ctx && ctx.type && ctx.type.value ? ctx.type.value : '';
  offlineScanData.ctx_val =
    ctx && ctx.value && ctx.value.key ? ctx.type.key : '';
  offlineScanData.ctx_label =
    ctx && ctx.value && ctx.value.label
      ? ctx.type.label
      : offlineScanData.ctx_val;
  offlineScanData.is_reject = rejectmode === true || rejected === true;

  try {
    const resp = await trackTraceOfflineReq(offlineScanReqPayload);
    if (resp.status !== 200) {
      dispatch(addOfflineScan(offlineScanData));
      return {
        error: true,
        supported: true,
        err_msg: getResponseErrorMessage(
          resp,
          'Unable to scan the code at this time',
        ),
      };
      //add code to handle offline request
    }
    const payload = resp.payload;
    if (
      payload.retry_allowed === false ||
      !payload.branch_matched ||
      !payload.ctx_matched
    ) {
      dispatch(addOfflineScan(offlineScanData));
      return {
        retry_allowed: payload.retry_allowed === true,
        supported: true,
        error: true,
        branch_matched: payload.branch_matched,
        ctx_matched: payload.ctx_matched,
        err_msg: payload.err_msg,
        operation_status: [],
      };
    } else if (payload.success === false) {
      dispatch(addOfflineScan(offlineScanData));
      return {
        retry_allowed: true,
        supported: true,
        error: true,
        operation_status: [],
        err_msg: payload.err_msg
          ? payload.err_msg
          : 'Unable to Post. Added to offline queue',
      };
    }
    const operationStatusList = (payload.operation_status || []).map(val => {
      if (val.order_item && !val.order_item.operation_checklist) {
        val.order_item.operation_checklist = [];
      }
      return val;
    });
    return {
      retry_allowed: true,
      supported: true,
      success: true,
      operation_status: operationStatusList,
    };
  } catch (err) {
    dispatch(addOfflineScan(offlineScanData));
    return {
      retry_allowed: true,
      supported: true,
      success: false,
      err_msg: '' + err,
    };
  }
};
/* eslint-enable sonarjs/cognitive-complexity */
/* eslint-disable  sonarjs/cognitive-complexity */
export const trackTraceScan = async (
  scanCode,
  manualEntry,
  rejection,
  images,
  allOperations,
  dispatch,
  scannedItems,
  appendScannedItemsCb,
  syncCb,
) => {
  const { rejectmode, current_station, station_location, ctx } =
    getState().stationstate;
  const { order_items } = getState().tracktrace;
  const { org, branch } = getState().user;
  if (!current_station || !current_station.uuid) {
    return {
      error: 'Select the Station Before Scanning',
      success: false,
    };
  }
  if (scanCode) {
    scanCode = scanCode.trim();
  }
  const location =
    (station_location ? station_location[current_station.uuid] : '') || '';
  try {
    dispatch(actions.busy.add('TRACK_TRACE_SCAN'));
    const offlineScanResult = await trackTraceOfflineScan({
      ctx,
      current_station,
      location,
      scanCode,
      manualEntry,
      rejectmode,
      rejection,
      images,
      dispatch,
      appendScannedItemsCb,
      syncCb,
    });
    if (offlineScanResult.supported === true) {
      if (
        offlineScanResult.error === true ||
        offlineScanResult.success === false
      ) {
        return {
          error: offlineScanResult.err_msg || 'Unable to record the scan',
          success: false,
        };
      }
      if (syncCb) {
        const synced = await syncCb();
        if (!synced) {
          setTimeout(() => {
            syncCb();
          }, 2000);
        }
      }
      return {
        error: null,
        success: true,
        message: 'Scan Recorded',
      };
    }
    const rejected = rejection
      ? rejection.rejected === true
      : rejectmode === true;
    const orgItemFilter = {
      deleted: [false],
      released: [true],
      scan_code: scanCode,
      only_visible_features: true,
      fetch_order_item_status: true,
      features: DEFAULT_ITEM_FEATURES,
      scan_status_operation_codes: current_station.operations,
      checklist_stations: current_station.operations,
    };
    let payload = [];
    let fetchedFromSrc = false;
    if (
      ctx &&
      ctx.type &&
      [
        'ORDER',
        'PARENT_ORDER',
        'ORDER_EXTERNAL_REFERENCE',
        'SECONDARY_CUSTOMER',
      ].indexOf(ctx.type.value) >= 0 &&
      ctx.value &&
      ctx.value.key &&
      order_items &&
      order_items.length > 0
    ) {
      payload = [...order_items];
    } else {
      const itemFilterResp = await getItemsWithFilter({
        req_payload: orgItemFilter,
      });
      if (itemFilterResp.status !== 200) {
        return {
          error: getResponseErrorMessage(
            itemFilterResp,
            'Unable to Check the Item',
          ),
          success: false,
        };
      }
      if (itemFilterResp.payload.length === 0) {
        return {
          error: 'Item ' + scanCode + ' Does not Exist',
          success: false,
        };
      }
      payload = await populateOrderItemStatus(
        dispatch,
        itemFilterResp.payload,
        itemFilterResp.meta_data,
      );
      fetchedFromSrc = true;
      //payload = itemFilterResp.payload;
    }
    const match = matchOrderItemsFromResultToScanCode(payload, scanCode);

    await populateItemFieldConfig(match, dispatch);
    if (!match) {
      const matches = matchOrderItemsFromResultToBoxScanCode(payload, scanCode);
      if (matches && matches.length > 0) {
        return await trackTraceMultiScan(
          matches,
          manualEntry,
          rejection,
          images,
          allOperations,
          dispatch,
          scannedItems,
          appendScannedItemsCb,
          syncCb,
        );
      }
      if (fetchedFromSrc === false) {
        const itemFilterResp = await getItemsWithFilter({
          req_payload: orgItemFilter,
        });
        if (itemFilterResp.status !== 200) {
          return {
            error: getResponseErrorMessage(
              itemFilterResp,
              'Unable to Check the Item',
            ),
            success: false,
          };
        } else if (itemFilterResp.length > 0) {
          return {
            error:
              'Item ' +
              scanCode +
              ' Exists, but the order currently not enabled for scan',
            success: false,
          };
        }
      }
      return {
        error: 'Item ' + scanCode + ' Does not Exist',
        success: false,
      };
    }
    let isScanAllowed = true;
    let err = '';

    if (ctx.value && ctx.value.key) {
      if (ctx.type.value === 'ORDER') {
        if (ctx.value.key !== match.order_id) {
          isScanAllowed = false;
          err = 'The Scanned Item Does not Belong to the Order';
        }
      } else if (ctx.type.value === 'PARENT_ORDER') {
        if (
          ctx.value.key !== match.order_id &&
          (!match.order ||
            !match.order.parents ||
            match.order.parents.indexOf(ctx.value.code) === -1)
        ) {
          isScanAllowed = false;
          err = 'The Scanned Item Does not Belong to the Order Group';
        }
      } else if (ctx.type.value === 'CUSTOMER') {
        if (ctx.value.key !== match.order.entity_id) {
          isScanAllowed = false;
          err = 'The Scanned Item Does not Belong to the Customer';
        }
      } else if (ctx.type.value === 'ORDER_EXTERNAL_REFERENCE') {
        if (ctx.value.key !== match.order.ext_code) {
          isScanAllowed = false;
          err =
            'The Scanned Item Does not Belong to the Order External Reference';
        }
      } else if (ctx.type.value === 'SECONDARY_CUSTOMER') {
        if (ctx.value.key !== match.order.secondary_customer) {
          isScanAllowed = false;
          err =
            'The Scanned Item Does not Belong to the Order Secondary Customer';
        }
      } else if (ctx.type.value === 'WORK_ORDER') {
        if (ctx.value.key !== match.order_id) {
          isScanAllowed = false;
          err = 'The Scanned Item Does not Belong to the Work order';
        }
      } else {
        isScanAllowed = false;
        err = 'Group Not Supported Yet';
      }
    }
    let operationCodes = current_station.operations
      ? current_station.operations
      : [];
    if (!rejected) {
      //Added this check to ensure that if an operation is previously rejected we process it
      const operationsCount = operationCodes.length;
      operationCodes =
        manualEntry && allOperations === true
          ? operationCodes
          : filterCncOperationCodes(scanCode, match, operationCodes);
      if (
        match &&
        match.operations.length === 0 &&
        match.status === 'NO_OPERATION'
      ) {
        operationCodes = [];
      }
      if (operationCodes.length < operationsCount) {
        const operationStatusRequest = {
          size: 10,
          page: 0,
          req_payload: {
            operation_code: current_station.operations,
            deleted: [false],
            order_item_id: [match.uuid],
          },
        };
        const operationStatusResp = await getOperationStatus(
          operationStatusRequest,
        );
        if (operationStatusResp.status !== 200) {
          return {
            error: 'Unable to Get the Operation Status',
            success: true,
          };
        }
        const rejectedOperations = operationStatusResp.payload
          .filter(val => val.rejected)
          .filter(val => val.skip_operation === true)
          .map(val => val.operation_code);
        rejectedOperations.forEach(val => {
          if (operationCodes.indexOf(val) === -1) {
            operationCodes.push(val);
          }
        });
      }
    }

    const scanRecord = {
      branch_id: branch.branch_id,
      org: org.uuid,
      scan_station: current_station.station_code,
      pod: current_station.uuid,
      pod_name: current_station.instance_name,
      scan_code: scanCode,
      scan_item_id: match.uuid,
      scan_context_type: 'ORDER',
      scan_context_1: match.order_id,
      scan_context_2: match.uuid,
      scan_context_3: match.code,
      scan_status: 'ERROR',
      manual_entry: manualEntry,
      acknowledged: false,
      message: err,
      scanned_on: new Date(),
      location: location,
    };
    if (operationCodes.length === 0) {
      isScanAllowed = false;
      scanRecord.message =
        'There are no Operations for the Item(' +
        scanCode +
        ') in this Station';
    }
    if (!isScanAllowed) {
      const scanRecordResp = addScanRecord(scanRecord);
      toastError(scanRecord.message);
      if (scanRecordResp.status === 200) {
        const scannedItem = scanRecordResp.payload;
        scannedItem.order_item = match;
        await populateScanRecordOrderItemStatus(
          [scannedItem],
          current_station,
          dispatch,
        );
        appendScannedItemsCb([scannedItem]);
        //dispatch(appendScannedItems([scannedItem]));
        dispatch(setSelectedRecord(scannedItem.uuid));
      }
      return {
        error: scanRecord.message,
        success: false,
      };
    } else {
      const challengeResp = await getOperationChallenge(operationCodes, match);
      if (challengeResp.error != null) {
        return {
          error: challengeResp.error,
          success: false,
        };
      }
      const pendingChallenge = challengeResp.rules.filter(
        val => val.completed === false && val.mandatory === true,
      );
      if (pendingChallenge.length > 0) {
        dispatch(
          addChallenge({
            scanCode: scanCode,
            manualEntry: manualEntry,
            rejection: rejection,

            challenges: challengeResp.rules,
          }),
        );
        return {
          challenge: true,
        };
      }
      const imageAttachments = await uploadScanImages(images, match.order_id);
      if (!imageAttachments.success) {
        return {
          error: imageAttachments.err,
          success: false,
        };
      }

      const req = {
        scan_station: current_station.station_code,
        //scan_id: getUuid(dispatch),
        scan_code: scanCode,
        pod: current_station.uuid,
        pod_name: current_station.instance_name,
        manual_entry: manualEntry,
        scan_context_type: 'ORDER',
        scan_context_1: match.order_id,
        scan_context_2: match.uuid,
        scan_context_3: match.code,
        station_operation_codes: current_station.operations,
        req_payload: {
          order_item_id: match.uuid,
          scan_record_id: match.uuid,
          status: rejected ? 'REJECTED' : 'SUCCESS',
          operation_codes: operationCodes,
          processed_on: new Date(),
          rejected: rejected,
          reject_reason:
            rejection && rejection.reasons ? rejection.reasons : [],

          comment: rejection && rejection.comment ? rejection.comment : '',
          location: location,
          images: imageAttachments.images,
        },
        rejection_list:
          rejection && rejection.rejection_list ? rejection.rejection_list : [],
      };
      const operationStatusResp = await updateOperationStatus(req);
      if (operationStatusResp.status !== 200) {
        return {
          error: getResponseErrorMessage(
            operationStatusResp,
            'Unable to Update',
          ),
          success: false,
        };
      }

      const operationStatus = operationStatusResp.payload;
      //const additionalOperations = operationStatus.additional_operations || [];
      operationStatus.additional_operations = [];
      //const operationStatusList = [operationStatus, ...additionalOperations];
      const scannedItem = operationStatus.scan_record;
      if (!rejected && hasCncOperation(operationStatus.order_item)) {
        const topDrill = getTopDrill(operationStatus.order_item);
        const bottomDrill = getBottomDrill(operationStatus.order_item);
        let machineSynced = false;
        if (topDrill && operationCodes.indexOf('TOP_DRILL') >= 0) {
          machineSynced = true;
          const topDrillCode = getTopDrillAlt(operationStatus.order_item);
          executeMachineSync(
            current_station.uuid,
            operationStatus.order_item.uuid,
            topDrillCode,
          );
        }
        if (
          bottomDrill &&
          operationCodes.indexOf('BOTTOM_DRILL') >= 0 &&
          machineSynced === false
        ) {
          const bottomDrillCode = getBottomDrillAlt(operationStatus.order_item);
          executeMachineSync(
            current_station.uuid,
            operationStatus.order_item.uuid,
            bottomDrillCode,
          );
        }
      }
      const metaData = operationStatusResp.meta_data;
      const orderItem = operationStatus.order_item;
      orderItem.processed_on = operationStatus.processed_on;
      const orderItems = await populateOrderItemStatus(
        dispatch,
        [orderItem],
        metaData,
      );
      const scannedItems = orderItems.map(val => {
        return getScannedRecordFromOrderItem(val, val.status, val.processed_on);
      });
      dispatch(addOrderItems(orderItems));
      scannedItems[0].previous_scan_status = scannedItem.previous_scan_status;
      const scannedItemId = scannedItems[0].uuid;
      appendScannedItemsCb(scannedItems);
      if (scannedItemId) {
        dispatch(setSelectedRecord(scannedItemId));
      }
      return {
        error: null,
        success: true,
        previous_scan_status: scannedItem.previous_scan_status,
        message:
          scannedItem.previous_scan_status &&
          scannedItem.previous_scan_status === 'SUCCESS'
            ? 'Scan Recorded Again'
            : 'Scan Recorded',
      };
    }
  } finally {
    dispatch(actions.busy.remove('TRACK_TRACE_SCAN'));
  }
};
/* eslint-enable  sonarjs/cognitive-complexity */
export const getScanCodeFromScanRecord = record => {
  if (!record) {
    return '';
  }
  if (
    record.order_item &&
    record.order_item.scan_alternates &&
    record.order_item.scan_alternates.indexOf(record.scan_code) !== -1
  ) {
    return record.scan_code;
  }
  if (record.order_item && record.order_item.code) {
    return record.order_item.code;
  }
  return record.scan_code;
};
/* eslint-disable  sonarjs/cognitive-complexity */
export const getOrderItemSelectedFieldValues = (order_item, boxIndex) => {
  const { item_field_config } = getState().tracktrace;
  const keys = Object.keys(
    getItemFieldConfig(item_field_config, order_item).display_fields,
  );
  if (keys.length === 0) {
    keys.push('MATERIAL');
  }
  if (keys.indexOf('__LOCATION') === -1) {
    keys.push('__LOCATION');
  }
  if (keys.indexOf('__BOX') === -1) {
    keys.push('__BOX');
  }
  return keys
    .map(key => {
      switch (key) {
        case '__ITEM_CODE':
          return {
            key: key,
            label: 'Item Code',
            val: order_item.code,
          };
        case '__DEFINITION_CODE':
          return {
            key: key,
            label: 'Definition Code',
            val: order_item.item_definition.code,
          };
        case '__DEFINITION_NAME':
          return {
            key: key,
            label: 'Definition Name',
            val: order_item.item_definition.name,
          };
        case '__LOCATION':
          return {
            key: key,
            label: 'Current Location',
            val:
              order_item.order_item_status &&
              order_item.order_item_status.location
                ? order_item.order_item_status.location
                : 'DEFAULT',
          };
        case '__BOX':
          // const boxItems =
          //   order_item.box_items && order_item.box_items.length > 0
          //     ? [...order_item.box_items]
          //         .sort((a, b) => a.box.box_index - b.box.box_index)
          //         .map(boxItem => {
          //           return {
          //             box_id: boxItem.box.uuid,
          //             code: boxItem.box.code,
          //             box_index: boxItem.box.box_index,
          //           };
          //         })
          //     : [];

          // const boxes = uniqBy(boxItems, 'box_id');
          // const boxKeys = boxes.map(
          //   box => order_item.order.ref_code + '-' + box.box_index,
          // );
          return {
            key: key,
            label: 'Box',
            val: boxIndex ? boxIndex : 'NOT_PACKED',
            // additionalKeys: boxKeys,
          };
        default:
          if (
            !order_item ||
            !order_item.item_definition ||
            !order_item.item_definition.definition
          ) {
            return null;
          }
          const def = order_item.item_definition.definition[`${key}`];
          if (!def) {
            return null;
          }
          return {
            key: key,
            label: def.name,
            val: def.val,
          };
      }
    })
    .filter(val => val !== null);
};
/* eslint-enable  sonarjs/cognitive-complexity */

export const getTrackTraceCtxKey = (ctxVal, ctx, current_station) => {
  if (!ctx) {
    ctx = getState().stationstate.ctx;
    current_station = getState().stationstate.current_station;
  }

  if (!ctxVal && ctx) {
    ctxVal = ctx.value;
  }
  if (
    !current_station ||
    !current_station.uuid ||
    !ctx ||
    !ctx.type ||
    !ctx.type.value ||
    !ctxVal ||
    !ctxVal.key
  ) {
    return null;
  }
  return current_station.uuid + '|' + ctx.type.value + '|' + ctxVal.key;
};
export const checkIfOrderItemInScanCtx = orderItem => {
  const { ctx, current_station } = getState().stationstate;
  const { order_item_sync_ctx } = getState().tracktrace;
  const ctxVal = ctx.value;
  if (
    !current_station ||
    !current_station.uuid ||
    !ctx ||
    !ctx.type ||
    !ctx.type.value
  ) {
    return null;
  }
  if (!ctxVal || !ctxVal.key) {
    return '';
  }
  if (
    !order_item_sync_ctx ||
    !order_item_sync_ctx.type ||
    !orderItem ||
    !orderItem.uuid
  ) {
    return null;
  }

  let key = null;
  switch (order_item_sync_ctx.type) {
    case 'ORDER':
    case 'WORK_ORDER':
      if (orderItem.order.uuid !== order_item_sync_ctx.key) {
        return null;
      }
      key = orderItem.order.uuid;
      break;
    case 'PARENT_ORDER':
      if (orderItem.order.code === order_item_sync_ctx.code) {
        key = order_item_sync_ctx.key;
      } else if (
        orderItem.order.parents.indexOf(order_item_sync_ctx.code) !== -1
      ) {
        key = order_item_sync_ctx.key;
      } else {
        return null;
      }
      return key;
    case 'CUSTOMER':
      if (orderItem.order.entity_id === order_item_sync_ctx.key) {
        return order_item_sync_ctx.key;
      }
      return null;
    case 'ORDER_EXTERNAL_REFERENCE':
      if (orderItem.order.ext_code === order_item_sync_ctx.key) {
        return order_item_sync_ctx.key;
      }
      return null;
    case 'SECONDARY_CUSTOMER':
      if (orderItem.order.secondary_customer === order_item_sync_ctx.key) {
        return order_item_sync_ctx.key;
      }
      return null;
    default:
      return null;
  }
  return key;
};
export const populateCtxOrderItemsList = async (
  dispatch,
  ctxType,
  ctxVal,
  operations,
) => {
  const { order_items_list } = getState().tracktrace;
  if (
    (order_items_list !== null && order_items_list.length > 0) ||
    !ctxType ||
    !ctxVal
  ) {
    return [];
  }
  const _ctxKey = getTrackTraceCtxKey();
  const ctxItemsResp = await getTrackTraceCtxItems(ctxType, ctxVal, operations);
  if (ctxItemsResp.status !== ResponseCodes.SUCCESS) {
    toastError('Unable to Fetch the Order Items');
    return [];
  }
  const orderItemIds = ctxItemsResp.payload
    .map(val => val.order_item_id)
    .filter((val, index, self) => self.indexOf(val) === index && val);
  const ctxKey = getTrackTraceCtxKey();
  if (ctxKey !== _ctxKey) {
    return [];
  }
  dispatch(setOrderItemsList(orderItemIds));
  return orderItemIds;
};
export const getTrackTraceCtxVal = () => {
  const { order_item_sync_ctx } = getState().tracktrace;
  if (!order_item_sync_ctx) {
    return null;
  }
  if (order_item_sync_ctx.type === 'ORDER') {
    return order_item_sync_ctx.key;
  } else if (order_item_sync_ctx.type === 'PARENT_ORDER') {
    return order_item_sync_ctx.code;
  } else if (order_item_sync_ctx.type === 'CUSTOMER') {
    return order_item_sync_ctx.key;
  } else if (order_item_sync_ctx.type === 'ORDER_EXTERNAL_REFERENCE') {
    return order_item_sync_ctx.key;
  } else if (order_item_sync_ctx.type === 'SECONDARY_CUSTOMER') {
    return order_item_sync_ctx.key;
  } else if (order_item_sync_ctx.type === 'WORK_ORDER') {
    return order_item_sync_ctx.key;
  }
  return null;
};
export const getScannedRecordFromOrderItem = (
  orderItem,
  scanStatus,
  scannedOn,
) => {
  const ctxVal = getTrackTraceCtxVal();
  const { current_station } = getState().stationstate;
  const _orderItem = cloneDeep(orderItem);
  if (!_orderItem.operation_checklist) {
    _orderItem.operation_checklist = [];
  }
  return {
    ctx_item: true,
    uuid: 'dummy-' + orderItem.uuid,
    org_id: orderItem.org_id,
    branch_id: orderItem.order.branch_id,
    scan_station: current_station ? current_station.station_code : '',
    scan_code: orderItem.code,
    scan_item_id: orderItem.uuid,
    pod: current_station ? current_station.station_code : '',
    pod_name: current_station ? current_station.station_name : '',
    scan_ctx_type: 'ORDER',
    scan_context_1: ctxVal,
    scan_context_2: orderItem.uuid,
    scan_context_3: orderItem.code,
    scan_status: scanStatus ? scanStatus : 'PENDING',
    manual_entry: false,
    acknowledged: false,
    scanned_on: scannedOn ? scannedOn : new Date(0),
    updated_on: scannedOn ? scannedOn : new Date(0),
    reject_reason: [],
    operations: [],
    images: [],
    order_item: cloneDeep(orderItem),
    operation_status: { ...orderItem.operation_status },
    active: true,
    approved: true,
    deleted: false,
  };
};
export const populateCtxScanRecords = async (
  dispatch,
  showLoader,
  scannedItems,
  appendScannedItemsCb,
  operationStatusList,
) => {
  try {
    dispatch(trackTraceBusy.add('POPULATE_CTX_SCAN_RECORDS'));
    if (showLoader) {
      dispatch(actions.busy.add('POPULATE_CTX_SCAN_RECORDS'));
    }
    const { order_item_sync_ctx, order_items } = getState().tracktrace;
    const { current_station } = getState().stationstate;
    if (
      !order_item_sync_ctx ||
      !order_item_sync_ctx.key ||
      !order_item_sync_ctx.type
    ) {
      return;
    }
    const mappedOrderItems = scannedItems
      .map(val =>
        val.order_item && val.order_item.uuid ? val.order_item.uuid : null,
      )
      .filter(val => val);
    //const ctxVal = getTrackTraceCtxVal();
    const orderItemMap = {};
    order_items.forEach(val => {
      orderItemMap[`${val.uuid}`] = val;
    });
    const pendingScannedItems = order_items
      .filter(val => mappedOrderItems.indexOf(val.uuid) === -1)
      .map(val => {
        return getScannedRecordFromOrderItem(val, 'PENDING');
      });
    const _modifiedScannedItems = scannedItems
      .filter(
        val =>
          val.order_item &&
          mappedOrderItems.indexOf(val.order_item.uuid) !== -1 &&
          !isEqual(
            val.order_item.box_items || [],
            orderItemMap[`${val.order_item.uuid}`].box_items || [],
          ),
      )
      .map(val => {
        const sI = cloneDeep(val);
        sI.order_item.box_items = cloneDeep(
          orderItemMap[`${val.order_item.uuid}`].box_items || [],
        );
        return sI;
      });
    const modifiedScannedItems = [
      ...pendingScannedItems,
      ..._modifiedScannedItems,
    ];

    await populateScanRecordOrderItemStatus(
      modifiedScannedItems,
      current_station,
      dispatch,
      operationStatusList.length > 0 ? operationStatusList : null,
    );
    //dispatch(appendScannedItems(pendingScannedItems));
    appendScannedItemsCb(modifiedScannedItems);
  } finally {
    dispatch(trackTraceBusy.remove('POPULATE_CTX_SCAN_RECORDS'));
    if (showLoader) {
      dispatch(actions.busy.remove('POPULATE_CTX_SCAN_RECORDS'));
    }
  }
};

/* eslint-enable  sonarjs/cognitive-complexity */

/* eslint-disable  sonarjs/cognitive-complexity */
export const syncScannedRecords = async (
  showLoader,
  dispatch,
  appendScannedItemsCb,
) => {
  const { current_station } = getState().stationstate;
  const size = 10;
  if (!current_station || !current_station.uuid) {
    return;
  }
  const ctxVal = getTrackTraceCtxVal();
  if (ctxVal) {
    return;
  }
  try {
    dispatch(trackTraceBusy.add('FETCH_SCAN_RECORDS'));
    if (showLoader) {
      dispatch(actions.busy.add('FETCH_SCAN_RECORDS'));
    }
    if (
      current_station.station_code === 'PRESSING' ||
      current_station.station_code === 'PREP_BOARD'
    ) {
      return;
    }
    const filter = {
      modified_after: moment().startOf('day').toDate(),
      pod: [current_station.uuid],
      scan_station: [current_station.station_code],
      scan_status_operation_codes: current_station.operations,
      features: DEFAULT_ITEM_FEATURES,
    };

    const scanRecordsResp = await getScanRecords({
      page: 0,
      size: size,
      req_payload: filter,
      sort: [
        {
          column: 'updated_on',
          asc: false,
        },
      ],
    });
    if (scanRecordsResp.status !== 200) {
      console.log('Failed to Fetch the Scanned Records');
      if (showLoader) {
        toastError('Unable to Fetch the Scanned Records');
      }
      return;
    }
    if (scanRecordsResp.payload.length === 0) {
      return;
    }
    const metaData = scanRecordsResp.meta_data || { orders: {} };
    const orderItemMap = {};
    scanRecordsResp.payload.forEach(val => {
      if (val.order_item && !orderItemMap[`${val.order_item.uuid}`]) {
        if (metaData.orders[`${val.order_item.order_id}`]) {
          val.order_item.order = metaData.orders[`${val.order_item.order_id}`];
        }
        orderItemMap[`${val.order_item.uuid}`] = val;
      }
    });
    const scanRecords = Object.values(orderItemMap);
    const orderItems = await populateOrderItemStatus(
      dispatch,
      scanRecords.map(val => val.order_item),
      metaData,
    );
    orderItems.forEach(val => {
      orderItemMap[`${val.uuid}`] = val;
    });
    const scannedItems = orderItems.map(val => {
      const sr = getScannedRecordFromOrderItem(
        val,
        val.status,
        val.processed_on,
      );
      sr.ctx_item = false;
      return sr;
    });
    const operationCodes =
      current_station.parent_operations &&
      current_station.parent_operations.length > 0
        ? current_station.parent_operations
        : current_station.operations;
    const recentScansResp = await getRecentScans(
      current_station.uuid,
      operationCodes,
      ['SUCCESS'],
      filter.modified_after,
    );
    if (recentScansResp.status !== 200) {
      console.log('Failed to Fetch the Recent Scans');
      if (showLoader) {
        toastError('Unable to Fetch the Recent Scans');
      }
      return;
    }
    dispatch(setProcessedOrderItems(recentScansResp.payload));
    appendScannedItemsCb(scannedItems);
  } finally {
    dispatch(trackTraceBusy.remove('FETCH_SCAN_RECORDS'));
    if (showLoader) {
      dispatch(actions.busy.remove('FETCH_SCAN_RECORDS'));
    }
  }
};
/* eslint-enable  sonarjs/cognitive-complexity */

export const getTrackTraceContainerClass = image_view_mode => {
  switch (image_view_mode) {
    case 'SMALL':
      return 'col-md-6 col-lg-8';
    case 'LARGE':
      return 'col-md-5 col-lg-6';
    default:
      return '';
  }
};

export const getTrackTraceCardsWrpClass = image_view_mode => {
  switch (image_view_mode) {
    case 'SMALL':
      return 'col-md-12 col-lg-6 col-xl-4';
    case 'LARGE':
      return 'col-md-12 col-lg-6';
    default:
      return 'col-12 col-md-6 col-lg-6 col-xl-4';
  }
};

export const filterScanRecordByCode = (searchKey, scanRecord) => {
  if (!scanRecord) {
    return true;
  }
  const scanCode = getScanCodeFromScanRecord(scanRecord);
  if (
    scanCode &&
    scanCode !== '' &&
    scanCode.toLowerCase().indexOf(searchKey) !== -1
  ) {
    return true;
  }
  if (scanRecord.code.toLowerCase().indexOf(searchKey) >= 0) {
    return true;
  }
  const keys = getOrderItemSelectedFieldValues(scanRecord.order_item);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[`${i}`];
    if (
      key &&
      key.val &&
      ('' + key.val).toLowerCase().indexOf(searchKey) !== -1
    ) {
      return true;
    }
  }
  return false;
};
export const syncOfflineScan = async (scan, dispatch) => {
  const offlineScanReqPayload = {
    scan_code: scan.scan_code,
    station_instance: scan.station_instance,
    scan_ctx: scan.scan_ctx,
    scan_ctx_type: scan.scan_ctx_type,
    operation_codes: scan.operation_codes,
    manual_entry: scan.manual_entry,
    scanned_on: scan.scanned_on,
    allow_ctx_mismatch: scan.allow_ctx_mismatch,
  };
  const resp = await trackTraceOfflineReq(offlineScanReqPayload);
  if (resp.status !== 200) {
    return {
      success: false,
      err_msg:
        resp.err && resp.err.err ? resp.err.err : 'Unable to Post the request',
    };
  }
  const payload = resp.payload;
  if (payload.retry_allowed === false) {
    const scanUpdate = cloneDeep(scan);
    scanUpdate.retry_allowed = false;
    scanUpdate.err_msg = payload.err_msg
      ? payload.err_msg
      : 'The Code cannot be scanned';
    dispatch(addOfflineScan(scanUpdate));
    return {
      success: false,
      err_msg: scanUpdate.err_msg,
    };
  }
  if (payload.success === false) {
    const scanUpdate = cloneDeep(scan);
    scanUpdate.branch_matched = payload.branch_matched;
    scanUpdate.ctx_matched = payload.ctx_matched;
    scanUpdate.err_msg = payload.err_msg
      ? payload.err_msg
      : 'Unknown Error during scan...';
    dispatch(addOfflineScan(scanUpdate));
    return {
      success: false,
      err_msg: scanUpdate.err_msg,
    };
  }
  return {
    success: true,
  };
};
export const syncOfflineScans = scan => async (dispatch, getState) => {
  const { user } = getState();
  const { org } = user || {};
  const { offline_scans } = getState().tracktrace;
  if (!offline_scans || offline_scans.length === 0) {
    return;
  }
  const filteredScans = offline_scans.filter(
    val =>
      val.org_id === org.uuid &&
      (val.allow_ctx_mismatch ||
        val.ctx_matched === undefined ||
        val.ctx_matched === true),
  );
  for (let i = 0; i < filteredScans.length; i++) {
    const scan = filteredScans[`${i}`];
    const scanStatus = await syncOfflineScan(scan, dispatch);
    if (scanStatus.success) {
      dispatch(removeOfflineScan(scan.scan_code));
    }
  }
};
