import service from './index';
import { getState } from '../store';
import calcStat from '../helpers/shipment/stat';
import sortBy from '../helpers/common/sort-by';
const SHIPMENT_POD_NAME = 'shipment-1';
export const searchShipments = (name_term, exactSearch, page, size) => {
  const {
    user: { user_id, org, branch },
  } = getState();
  const payload = {
    name_term: name_term,
    branch_id: branch && branch.branch_id ? [branch.branch_id] : [],
    deleted: [false],
  };
  if (exactSearch === true) {
    payload.name = [name_term];
  }
  const data = {
    user_id,
    org_id: org.uuid,
    req_payload: payload,
    page: page || 0,
    size: size || 5,
    sort: [{ asc: false, column: 'id' }],
  };
  return service({
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ship/filter`,
    method: 'POST',
    data,
  });
};

export const fetchShipmentOptions = (name_term, exactSearch) => {
  return searchShipments(name_term, exactSearch).then(({ payload }) => payload);
};

export const fetchShipmentStats = () => {
  const {
    user: { org, branch },
  } = getState();
  const branchId = branch ? branch.branch_id : '';
  return service({
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ship/stat/${branchId}`,
  })
    .then(resp => {
      if (resp.status !== 200) {
        resp.payload = {
          status: {},
        };
      }
      return resp;
    })
    .then(({ payload: { status } }) => status);
};
export const calculateShipmentCtxItems = shipmentId => {
  const {
    user: { org },
  } = getState();
  return service({
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/shippingcontext/ship/calculate/${shipmentId}`,
    method: 'PUT',
    data: {},
  });
};
export const calculateShipmentStat = shipmentId => {
  const {
    user: { org },
  } = getState();
  return service({
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ship/stat/${shipmentId}`,
    method: 'PUT',
    data: {},
  });
};
export const fetchShipments = (page, size, filters) => {
  const {
    user: { user_id, org, branch },
  } = getState();
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  let name_term,
    customer = [],
    parent_order,
    context = [],
    code = [],
    status = [];

  if (filters.SEARCH) {
    name_term = filters.SEARCH.map(o => o.term)[0];
  }

  if (filters.CUSTOMER) {
    customer = filters.CUSTOMER.map(o => o.uuid);
  }

  if (filters.PARENT_ORDER_ID) {
    parent_order = filters.PARENT_ORDER_ID.map(o => o.uuid);
  }

  if (filters.ORDER_ID) {
    context = filters.ORDER_ID.map(o => ({
      shipping_context_type: 'ORDER',
      shipping_context_ref: o.uuid,
    }));
  }

  if (filters.SHIPMENT_ID) {
    code = filters.SHIPMENT_ID.map(o => o.code);
  }

  if (filters.SHIPMENT_STATUS && filters.SHIPMENT_STATUS.length) {
    status = filters.SHIPMENT_STATUS.map(o => o.value)
      .join(',')
      .split(',');
  }

  const data = {
    user_id,
    org_id: org.uuid,
    req_payload: {
      org_id: org.uuid,
      name_term,
      customer,
      context,
      parent_order,
      code,
      status,
      branch_id: [branchId],
      deleted: [false],
    },
    page: page - 1,
    size,
    sort: [{ asc: false, column: 'id' }],
  };
  return service({
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ship/filter`,
    method: 'POST',
    data,
  }).then(({ payload, total }) => ({ payload, total }));
};

export const fetchOrders = term => {
  const {
    user: { user_id, org, branch },
  } = getState();
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  const data = {
    user_id,
    org_id: org.uuid,
    req_payload: { term, branch_id: [branchId] },
    page: 0,
    size: 5,
  };
  return service({
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/filter`,
    method: 'POST',
    data,
  }).then(({ payload }) => payload);
};

export const fetchParentOrders = term => {
  const {
    user: { user_id, org, branch },
  } = getState();
  const branchId = branch && branch.branch_id ? branch.branch_id : '';
  const data = {
    user_id,
    org_id: org.uuid,
    req_payload: { term, has_children: true, branch_id: [branchId] },
    page: 0,
    size: 5,
  };
  return service({
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/filter`,
    method: 'POST',
    data,
  }).then(({ payload }) => payload);
};

export const createShipment = req_payload => {
  const {
    user: { user_id, org, branch },
  } = getState();
  if (req_payload) {
    req_payload.branch_id = branch ? branch.branch_id : null;
  }
  const data = {
    user_id,
    org_id: org.uuid,
    req_payload,
  };
  return service({
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/ship/create`,
    method: 'POST',
    data,
  });
};

export const fetchOrderCode = orders => {
  const {
    user: { user_id, org, branch },
  } = getState();
  if (orders && orders.length) {
    return service({
      url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/orders/filter`,
      method: 'POST',
      data: {
        user_id,
        org_id: org.uuid,
        req_payload: {
          branch_id: branch && branch.branch_id ? [branch.branch_id] : [],
          uuid: orders.map(o => o.shipping_context_ref),
        },
      },
    }).then(({ payload }) => {
      for (let i in orders) {
        const order = payload.find(
          o => o.uuid === orders[`${i}`].shipping_context_ref,
        );
        if (order) {
          orders[`${i}`].code = order.code;
          orders[`${i}`].ref_code = order.ref_code;
        }
      }
      return orders;
    });
  } else {
    return Promise.resolve([]);
  }
};

export const fetchShipmentById = async uuid => {
  const {
    user: { org },
  } = getState();
  return service({
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ship/id/${uuid}`,
    method: 'GET',
  });
};

export const fetchShipment = async (uuid, skipItems) => {
  const {
    user: { org },
  } = getState();
  const fetchItems = `fetch_items=${skipItems ? 'false' : 'true'}`;
  const result = await service({
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/ship/id/${uuid}?${fetchItems}`,
    method: 'GET',
  });
  if (result.status !== 200) {
    return new Promise((resolve, reject) => {
      reject(result?.err?.err);
    });
  }
  const payload = result.payload;
  const orders = await fetchOrderCode(payload.context);
  return [payload, orders];
};

export const linkOrderToShipment = (shipment, order) => {
  const {
    user: { user_id, org },
  } = getState();
  const data = {
    user_id,
    org_id: org.uuid,
    req_payload: {
      org_id: org.uuid,
      ship_id: shipment.uuid,
      shipping_context_ref: order.uuid,
      shipping_context_type: 'ORDER',
    },
  };
  return new Promise((resolve, reject) => {
    service({
      url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/shippingcontext/create`,
      method: 'POST',
      data,
    }).then(({ payload }) => {
      fetchOrderCode([payload]).then(orders => resolve(orders[0]), reject);
    }, reject);
  });
};

export const unlinkOrderToShipment = order => {
  const {
    user: { user_id, org },
  } = getState();
  return service({
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/shippingcontext/id/${order.uuid}?user_id=${user_id}`,
    method: 'DELETE',
  });
};

export const filterCustomer = search => {
  const {
    user: { user_id, org },
  } = getState();
  const data = {
    user_id,
    org_id: org.uuid,
    req_payload: {
      term: search,
    },
    page: 0,
    size: 5,
  };
  return service({
    url: `/naadi-app/api/naadi-entity-service/v1/naadi/entity/${org.uuid}/orgentity/filter`,
    method: 'POST',
    data,
  }).then(({ payload }) => payload);
};

export const linkParentOrderToShipment = (shipment, order) => {
  const {
    user: { user_id, org },
  } = getState();
  const data = {
    user_id,
    org_id: org.uuid,
    req_payload: {
      org_id: org.uuid,
      ship_id: shipment.uuid,
      shipping_context_ref: order.uuid,
      shipping_context_type: 'ORDER',
    },
  };
  return new Promise((resolve, reject) => {
    service({
      url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/shippingcontext/create/parentorder`,
      method: 'POST',
      data,
    }).then(({ payload }) => {
      fetchOrderCode(payload).then(resolve, reject);
    }, reject);
  });
};

export const linkCustomerToShipment = (shipment, customer) => {
  const {
    user: { user_id, org },
  } = getState();
  const data = {
    user_id,
    org_id: org.uuid,
    req_payload: {
      org_id: org.uuid,
      ship_id: shipment.uuid,
      shipping_context_ref: customer.uuid,
      shipping_context_type: 'ORDER',
    },
  };
  return new Promise((resolve, reject) => {
    service({
      url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/shippingcontext/create/customer`,
      method: 'POST',
      data,
    }).then(({ payload }) => {
      fetchOrderCode(payload).then(resolve, reject);
    }, reject);
  });
};

export const addLinkOrders = shipment => {
  const {
    user: { user_id, org },
  } = getState();
  const data = {
    user_id,
    org_id: org.uuid,
    req_payload: {
      org_id: org.uuid,
      ship_id: shipment.uuid,
      shipping_context_type: 'ORDER',
    },
  };
  return new Promise((resolve, reject) => {
    service({
      url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/shippingcontext/create/linkedorders`,
      method: 'POST',
      data,
    }).then(({ payload }) => {
      fetchOrderCode(payload).then(resolve, reject);
    }, reject);
  });
};

export const fetchOrderDetail = order => {
  const {
    user: { org },
  } = getState();
  return service({
    url: `/api/naadi-order-service/v1/naadi/order/${org.uuid}/shippingcontext/id/${order.uuid}?calculate_stat=true`,
    method: 'GET',
  });
};

export const fetchOrdersDetail = orders =>
  new Promise((resolve, reject) => {
    let pArr = [];
    for (const order of orders) {
      pArr.push(fetchOrderDetail(order));
    }
    Promise.all(pArr).then(arr => {
      resolve(arr.map(o => o.payload));
    }, reject);
  });

export const fetchShipmentDetails = shipment =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  new Promise((resolve, reject) => {
    const calcQty = (o, completed) => {
      const shipped_qty = o.shipped_qty || completed ? 1 : 0;
      const packed_qty = o.packed_qty || 0;
      const ctx_shipped_qty = o.ctx_shipped_qty || completed ? 1 : 0;
      const qty = (o.qty || 1) - packed_qty - (shipped_qty - ctx_shipped_qty);
      return {
        qty,
        shipped_qty: ctx_shipped_qty,
        checked: ctx_shipped_qty > 0 ? true : completed,
        completed: ctx_shipped_qty === qty,
      };
    };
    const create = {
      box: (o, completed) => ({
        type: 'BOX',
        completed,
        code: o.code,
        uuid: o.uuid,
        serialized: true,
        editable: o.editable === true,
        busy: false,
        item_name: o.item_name,
        box_index: o.box_index,
        ...calcQty(o, completed),
      }),
      item: (o, completed) => ({
        type: 'ITEM',
        completed,
        code: o.code,
        uuid: o.uuid,
        serialized: typeof o.serialized === 'boolean' ? o.serialized : true,
        busy: false,
        item_name: o.item_name,
        ...calcQty(o, completed),
      }),
    };
    fetchShipment(shipment.uuid).then(([data, orders]) => {
      fetchOrdersDetail(orders).then(arr => {
        for (let order of orders) {
          const detail = arr.find(o => order && o && o.uuid === order.uuid);
          if (detail) {
            order.detail = detail; // temp. will remove after end of development.
            order.boxes = [
              ...[
                ...detail.pending_box_list.map(o => create.box(o, false)),
                ...detail.shipped_box_list.map(o => create.box(o, true)),
              ].sort(sortBy('box_index')),
              ...[
                ...detail.pending_order_items.map(o => create.item(o, false)),
                ...detail.shipped_order_items.map(o => create.item(o, true)),
              ].sort(sortBy('code')),
            ];
          }
        }
        resolve(calcStat(data, orders));
      }, reject);
    }, reject);
  });

export const linkItemToBox = (
  scan_id,
  shipment,
  _order,
  box,
  qty,
  isManual,
) => {
  const {
    user: { org, user_id },
  } = getState();
  const currentStation = getState().shipmentstate?.current_station || {
    uuid: SHIPMENT_POD_NAME,
    instance_name: SHIPMENT_POD_NAME,
  };

  const data = {
    active: true,
    approved: true,
    deleted: false,
    user_id,
    org_id: org.uuid,
    scan_station: 'shipment',
    scan_id,
    scan_code: box.code,
    pod: currentStation.uuid,
    pod_name: currentStation.instance_name,
    manual_entry: isManual !== false,
    req_payload: {
      box_id: shipment.box.uuid,
      box_item_type: box.type === 'BOX' ? 'BOX' : 'ORDER',
      box_item_id: box.uuid,
      box: box.box,
      qty: qty,
      acknowledged: true,
      scan_record: {},
    },
  };
  return service({
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/boxitems/linktobox`,
    method: 'POST',
    data,
  });
};

export const deLinkItemFromBox = (scan_id, shipment, _order, box) => {
  const {
    user: { org, user_id },
  } = getState();
  const currentStation = getState().shipmentstate?.current_station || {
    uuid: SHIPMENT_POD_NAME,
    instance_name: SHIPMENT_POD_NAME,
  };
  const box_item = shipment.box?.box_item_list?.find(
    o => o.box_item_id === box.uuid,
  );
  if (box_item) {
    const data = {
      active: true,
      approved: true,
      deleted: false,
      user_id,
      org_id: org.uuid,
      scan_station: 'shipment',
      scan_id,
      scan_code: box.code,
      pod: currentStation.uuid,
      pod_name: currentStation.instance_name,
      manual_entry: true,
      req_payload: {
        uuid: box_item.uuid,
      },
    };
    return service({
      url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/boxitems/delinkfrombox`,
      method: 'POST',
      data,
    });
  } else {
    return Promise.reject('Shipment Item Not Found');
  }
};

export const closeShipment = shipment => {
  return createShipment({
    uuid: shipment.uuid,
    status: 'CLOSED',
  });
};
export const reopenShipment = shipment => {
  return createShipment({
    uuid: shipment.uuid,
    status: 'OPEN',
  });
};

export const cancelShipment = shipment => {
  const {
    user: { org, user_id },
  } = getState();
  return service({
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/ship/id/${shipment.uuid}?user_id=${user_id}`,
    method: 'DELETE',
  });
};

export const fetchOrderItem = uuid => {
  const {
    user: { org, branch },
  } = getState();
  const data = {
    org_id: org.uuid,
    req_payload: {
      uuid,
      branch_id: branch && branch.branch_id ? [branch.branch_id] : [],
    },
  };
  return service({
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/items/filter`,
    method: 'POST',
    data,
  }).then(({ payload, err, status }) => {
    return new Promise((resolve, reject) => {
      if (status !== 200) {
        reject(
          err && err.err ? err.err : 'Unable to fetch the Shipment Items.',
        );
        return;
      }
      resolve(payload);
    });
  });
};

export const fetchOrderItemBox = uuid_list => {
  const {
    user: { org, branch },
  } = getState();
  return new Promise((resolve, reject) => {
    service({
      url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/boxitems/filter`,
      method: 'POST',
      data: {
        org_id: org.uuid,
        page: 0,
        size: 1000,
        req_payload: {
          deleted: [false],
          org_id: org.uuid,
          box_id: uuid_list,
          branch_id: branch && branch.branch_id ? [branch.branch_id] : [],
        },
      },
    }).then(({ payload, status, err }) => {
      if (status !== 200) {
        reject(err && err.err ? err.err : 'Unable to fetch the Shipment Items');
        return;
      }
      fetchOrderItem(payload.map(o => o.box_item_id)).then(resp => {
        resolve(resp);
      }, reject);
    });
  });
};

export const generateShipmentSummary = (shipmentId, templateId, format) => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/ship/itemsummaryreport/${shipmentId}?template_id=${templateId}&format=${format}`,
  });
};

export const generateShipmentOrderSummary = (
  shipmentId,
  templateId,
  format,
) => {
  const {
    user: { org },
  } = getState();
  return service({
    method: 'GET',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/ship/ordersummaryreport/${shipmentId}/report?template_id=${templateId}&format=${format}`,
  });
};

const postProcessDigitalPodData = payload => {
  const processOrderItem = orderItem => {
    orderItem._operation_status = orderItem.operation_status || [];
    orderItem.operation_checklist = orderItem.operation_checklist || [];
    orderItem.item_field_config = {
      fields: [],
      display_fields: {},
    };
    Object.keys(orderItem.item_definition?.definition || {}).forEach(field => {
      if (field === 'REJECTION_REASONS' || field === 'REJECT_REASON_TEMPLATE') {
        return;
      }
      orderItem.item_field_config.display_fields[`${field}`] = true;
    });
  };
  const loopBox = box => {
    if (!box.box_items) {
      box.box_items = [];
    }
    box.box_items.forEach(bi => {
      if (bi.box_item_type === 'BOX') {
        loopBox(bi.box);
      } else if (bi.box_item_type === 'ORDER') {
        processOrderItem(bi.order_box_item);
      }
    });
  };
  payload.forEach(val => {
    if (val.type === 'BOX') {
      loopBox(val.box);
    } else if (val.type === 'ORDER_ITEM') {
      processOrderItem(val.order_item);
    }
  });
  return payload;
};

export const getDigitalPodData = async (
  ctxType,
  ctxId,
  featureList,
  operationCodes,
  skipCache,
) => {
  const {
    user: { org },
  } = getState();
  skipCache = skipCache === true ? true : false;
  const resp = await service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/ship/dpoddata?skip_cache=${skipCache}`,
    data: {
      req_payload: {
        ctx_type: ctxType,
        ctx_id: ctxId,
        feature_list: featureList,
        operation_codes: operationCodes,
      },
    },
  });
  if (resp.status !== 200) {
    return resp;
  }
  resp.payload = postProcessDigitalPodData(resp.payload);

  return resp;
};

export const getDigitalPodDataByOrderItem = async (
  orderItemId,
  featureList,
  operationCodes,
) => {
  const {
    user: { org },
  } = getState();
  const resp = await service({
    method: 'POST',
    url: `/api/naadi-order-service2/v1/naadi/order2/${org.uuid}/ship/dpoddata/orderitem/${orderItemId}`,
    data: {
      req_payload: {
        feature_list: featureList,
        operation_codes: operationCodes,
      },
    },
  });
  if (resp.status !== 200) {
    return resp;
  }
  resp.payload = postProcessDigitalPodData(resp.payload);

  return resp;
};
