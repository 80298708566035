/* eslint-disable prettier/prettier */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

export const NaadiIcon = memo(({ is_text_white, onClick }) => (
  <div className='cursor-pointer' onClick={onClick}>
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='196'
      height='30'
      fill='none'
      viewBox='0 0 329 46'
    >
      <path
        fill={is_text_white ? '#FFF' : '#000'}
        d='M108.884 33.84V7.992h.576l17.172 19.8v-19.8h2.556V33.84h-.576l-17.064-19.548V33.84h-2.664zm64.304-26.892l12.06 26.892h-2.772l-4.068-8.82h-11.16l-4.032 8.82h-2.88L172.54 6.948h.648zm-.324 5.724l-4.428 9.72h8.82l-4.392-9.72zm54.079-5.724l12.06 26.892h-2.772l-4.068-8.82h-11.16l-4.032 8.82h-2.88l12.204-26.892h.648zm-.324 5.724l-4.428 9.72h8.82l-4.392-9.72zm43.496 21.168V7.992h5.364c3.672 0 6.36.276 8.064.828 1.728.528 3.216 1.38 4.464 2.556 1.248 1.152 2.208 2.568 2.88 4.248.672 1.68 1.008 3.648 1.008 5.904 0 2.232-.528 4.344-1.584 6.336-1.032 1.968-2.448 3.456-4.248 4.464-1.776 1.008-4.344 1.512-7.704 1.512h-8.244zm2.484-2.448h2.988c3.12 0 5.376-.156 6.768-.468 1.416-.312 2.652-.924 3.708-1.836a9.208 9.208 0 002.412-3.384c.552-1.32.828-2.832.828-4.536 0-1.704-.324-3.276-.972-4.716-.624-1.44-1.524-2.628-2.7-3.564-1.176-.936-2.568-1.56-4.176-1.872-1.584-.336-3.924-.504-7.02-.504h-1.836v20.88zm51.052-23.4h2.592V33.84h-2.592V7.992z'
      ></path>
      <path
        fill='#007BFF'
        fillRule='evenodd'
        d='M37.266 28.529c2.397 0 4.477.86 6.174 2.557A8.671 8.671 0 0146 37.262c0 2.335-.91 4.53-2.56 6.179A8.682 8.682 0 0137.263 46a8.698 8.698 0 01-6.183-2.56c-1.672-1.669-2.554-3.905-2.554-6.466l.009-3.888h-11.07l.008 3.883c0 2.566-.88 4.8-2.553 6.472A8.697 8.697 0 018.738 46a8.682 8.682 0 01-6.178-2.56A8.678 8.678 0 010 37.263c0-2.333.91-4.526 2.56-6.175 1.697-1.696 3.777-2.558 6.178-2.558l4.172-.003V17.474l-4.174-.004c-2.4 0-4.48-.86-6.176-2.557A8.678 8.678 0 010 8.738c0-2.335.91-4.531 2.561-6.18A8.679 8.679 0 018.738 0c2.334 0 4.531.91 6.183 2.56 1.671 1.67 2.552 3.905 2.552 6.466l-.007 3.888h11.069l-.009-3.883c0-2.566.882-4.802 2.556-6.472A8.692 8.692 0 0137.262 0c2.335 0 4.53.91 6.18 2.56 3.405 3.403 3.405 8.946 0 12.352-1.7 1.697-3.779 2.558-6.178 2.558l-4.174.004v11.052l4.176.003zm-.062-23.794a4.057 4.057 0 00-2.876 1.189c-.794.798-1.18 1.827-1.18 3.153l.002 3.776h4.053c1.164 0 2.052-.367 2.873-1.188a4.06 4.06 0 00-2.872-6.93zM8.797 12.853c-1.163 0-2.05-.367-2.87-1.187a4.045 4.045 0 01-1.192-2.87c0-1.087.426-2.102 1.192-2.872a4.04 4.04 0 012.87-1.189c1.085 0 2.106.424 2.876 1.19.793.796 1.18 1.826 1.18 3.148l-.002 3.78H8.797zm0 28.412a4.055 4.055 0 002.875-1.189c.794-.798 1.18-1.827 1.18-3.153l-.001-3.776H8.799c-1.164 0-2.05.367-2.872 1.187a4.042 4.042 0 00-1.192 2.87 4.04 4.04 0 001.192 2.871 4.04 4.04 0 002.87 1.19zm19.615-12.853H17.588V17.588h10.824v10.824zm8.791 12.853a4.043 4.043 0 002.871-1.19 4.04 4.04 0 001.19-2.872c0-1.084-.425-2.1-1.19-2.869-.822-.82-1.708-1.187-2.87-1.187H33.15l-.003 3.78c0 1.322.388 2.352 1.18 3.148a4.055 4.055 0 002.876 1.19z'
        clipRule='evenodd'
      ></path>
    </svg>
  </div>
));
NaadiIcon.propTypes = {
  is_text_white: PropTypes.bool.isRequired,
};
export const HomeIcon = memo(({ fill }) => {
  const _fill = fill ? fill : '#fff';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      fill='none'
      viewBox='0 0 32 32'
    >
      <path
        fill={_fill}
        fillRule='evenodd'
        d='M14.062 6.56c.825-.747 2.051-.747 2.855 0l6.808 6.261a.93.93 0 01.275.641v9.188A2.332 2.332 0 0121.674 25h-3.848a.853.853 0 01-.846-.855v-5.341h-2.96v5.341c0 .47-.38.855-.846.855H9.326A2.331 2.331 0 017 22.65v-9.188c0-.256.106-.47.275-.64l6.787-6.261zm7.612 16.73c.36 0 .634-.277.634-.64l-.02-8.803-6.513-6.004a.42.42 0 00-.571 0l-6.512 6.004v8.803c0 .363.274.64.634.64h3.002v-5.34c0-.47.381-.855.846-.855h4.652c.465 0 .846.384.846.854v5.342h3.002z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
});
HomeIcon.propTypes = {
  fill: PropTypes.string,
};

export const PackingIcon = memo(({ fill }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='33'
    height='32'
    fill='none'
    viewBox='0 0 33 32'
  >
    <path
      fill={fill ? fill : '#fff'}
      fillRule='evenodd'
      d='M26.554 17.393l-1.571-3.25a.58.58 0 00-.344-.294l-4.615-1.613-.933.928 3.535 1.247-6.236 2.151-6.236-2.175 3.536-1.223-.933-.928-4.616 1.613a.676.676 0 00-.343.293l-1.572 3.251a.674.674 0 00-.024.49.774.774 0 00.344.341l1.178.416v5.573c0 .245.172.49.393.563l8.052 2.81c.074.025.123.025.197.025.073 0 .122 0 .196-.024l8.053-2.811a.603.603 0 00.392-.563v-5.597l1.179-.416a.485.485 0 00.344-.342.452.452 0 00.024-.465zM7.601 17.32l1.056-2.175 6.899 2.42L14.5 19.74 7.6 17.32zm1.35 6.453l6.85 2.396v-6.356l-.442.905a.589.589 0 01-.54.342c-.074 0-.123 0-.196-.024l-5.671-1.98v4.717zm14.878 0l-6.85 2.396v-6.356l.442.905c.099.22.32.342.54.342.074 0 .123 0 .197-.024l5.671-1.98v4.717zm-6.604-6.233l1.056 2.176 6.898-2.42-1.055-2.176-6.899 2.42z'
      clipRule='evenodd'
    ></path>
    <path
      fill={fill ? fill : '#fff'}
      d='M15.973 13.727c.123.122.27.17.417.17a.576.576 0 00.418-.17l2.651-2.616a.589.589 0 000-.855.595.595 0 00-.86 0l-1.62 1.613V4.976a.596.596 0 00-.589-.587c-.319 0-.589.269-.589.587v6.893l-1.62-1.613a.595.595 0 00-.86 0 .589.589 0 000 .855l2.652 2.616z'
    ></path>
  </svg>
));

export const ShipmentIcon = memo(({ fill }) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect width='32' height='32' fill='none' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M28.4445 14.7717C28.4417 14.5087 28.3179 14.2615 28.1085 14.1011C28.039 13.9758 27.9582 13.857 27.8669 13.7464L25.27 10.5138C24.7719 9.89496 24.0186 9.53502 23.2224 9.53534H19.6826V8.66262C19.681 7.21729 18.505 6.04602 17.054 6.04443H4.78732C4.30342 6.04443 3.91113 6.43517 3.91113 6.91716C3.91113 7.39915 4.30342 7.78989 4.78732 7.78989H17.054C17.5378 7.79018 17.9299 8.18074 17.9302 8.66262V20.8808H13.4248C13.0318 19.3408 11.6398 18.2626 10.0445 18.2626C8.44914 18.2626 7.05716 19.3408 6.66412 20.8808H4.78732C4.30342 20.8808 3.91113 21.2715 3.91113 21.7535C3.91113 22.2355 4.30342 22.6263 4.78732 22.6263H6.66412C7.05716 24.1663 8.44914 25.2444 10.0445 25.2444C11.6398 25.2444 13.0318 24.1663 13.4248 22.6263H19.807C20.209 24.1919 21.6401 25.276 23.2623 25.2437C24.8845 25.2115 26.2709 24.0713 26.6099 22.4909C27.6987 22.1491 28.4405 21.1451 28.4445 20.0081V15.3826C28.4432 15.2403 28.4306 15.0983 28.4067 14.958C28.4263 14.8976 28.439 14.835 28.4445 14.7717ZM23.2224 11.2808C23.4878 11.2812 23.7387 11.4016 23.9044 11.6081L25.7444 13.899H19.6826V11.2808H23.2224ZM8.29209 21.7535C8.29209 22.7175 9.07665 23.499 10.0445 23.499C11.0117 23.4977 11.7955 22.717 11.7968 21.7535C11.7968 20.7895 11.0123 20.0081 10.0445 20.0081C9.07665 20.0081 8.29209 20.7895 8.29209 21.7535ZM23.1873 23.499C22.2195 23.499 21.4349 22.7175 21.4349 21.7535C21.4349 20.7895 22.2195 20.0081 23.1873 20.0081C24.1551 20.0081 24.9397 20.7895 24.9397 21.7535C24.9384 22.717 24.1546 23.4977 23.1873 23.499ZM23.0242 18.2663C24.5575 18.1952 25.9571 19.1314 26.4713 20.5719C26.611 20.4168 26.6895 20.2165 26.6921 20.0081V15.6444H19.6826V20.8808H19.807C20.1846 19.3989 21.4909 18.3373 23.0242 18.2663Z'
      fill={fill ? fill : '#fff'}
    />
    <path
      d='M4.07614 11.2809H9.33328C9.81719 11.2809 10.2095 10.8901 10.2095 10.4081C10.2095 9.92613 9.81719 9.5354 9.33328 9.5354H4.07614C3.59224 9.5354 3.19995 9.92613 3.19995 10.4081C3.19995 10.8901 3.59224 11.2809 4.07614 11.2809Z'
      fill={fill ? fill : '#fff'}
    />
    <path
      d='M4.07614 14.7717H6.70471C7.18862 14.7717 7.5809 14.381 7.5809 13.899C7.5809 13.417 7.18862 13.0262 6.70471 13.0262H4.07614C3.59224 13.0262 3.19995 13.417 3.19995 13.899C3.19995 14.381 3.59224 14.7717 4.07614 14.7717Z'
      fill={fill ? fill : '#fff'}
    />
  </svg>
));

export const ItemIcon = memo(({ fill }) => {
  const _fill = fill ? fill : 'white';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="none">
    <path  fill={_fill} fillRule="evenodd" clipRule="evenodd" d="M17.4358 5.43645L13.0662 9.95885L8.69653 5.43645L13.0662 0.914062L17.4358 5.43645ZM0.200012 8.45148L4.20553 1.66789L8.21105 8.45148H0.200012ZM5.54071 6.94388L4.20898 4.68268L2.87036 6.94388H5.54071ZM13.7034 12.1573C11.4367 12.1573 9.60687 14.0511 9.60687 16.3971C9.60687 18.7431 11.4367 20.6368 13.7034 20.6368C15.9702 20.6368 17.8 18.7431 17.8 16.3971C17.8 14.0511 15.9702 12.1573 13.7034 12.1573ZM11.4276 16.397C11.4276 17.6972 12.4472 18.7525 13.7035 18.7525C14.9598 18.7525 15.9793 17.6972 15.9793 16.397C15.9793 15.0969 14.9598 14.0416 13.7035 14.0416C12.4472 14.0416 11.4276 15.0969 11.4276 16.397ZM0.503479 20.1655H7.78624V12.6281H0.503479V20.1655ZM1.95996 14.1358H6.32962V18.6582H1.95996V14.1358ZM13.0662 3.17502L10.8813 5.43622L13.0662 7.69741L15.251 5.43622L13.0662 3.17502Z" />
  </svg>
  );
});
ItemIcon.propTypes = {
  fill: PropTypes.string,
};
export const ReportIcon = memo(({ fill }) => {
  const _fill = fill ? fill : 'white';
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M27.0222 6.91566L20.1333 11.7842L13.1342 6.83338L4.97778 11.7842V8.57509L13.2444 3.55566L20.1333 8.42424L27.0222 3.55566V6.91566ZM27.0222 17.2699H4.97778V15.5633H27.0222V17.2699ZM27.0222 21.049H4.97778V22.7557H27.0222V21.049Z'
        fill={_fill}
      />
    </svg>
  );
});
ReportIcon.propTypes = {
  fill: PropTypes.string,
};
export const ItemIcons = memo(({ fill }) => {
  const _fill = fill ? fill : 'white';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="133" height="133" viewBox="0 0 18 21" fill="none">
    <path  fill={_fill} fillRule="evenodd" clipRule="evenodd" d="M17.4358 5.43645L13.0662 9.95885L8.69653 5.43645L13.0662 0.914062L17.4358 5.43645ZM0.200012 8.45148L4.20553 1.66789L8.21105 8.45148H0.200012ZM5.54071 6.94388L4.20898 4.68268L2.87036 6.94388H5.54071ZM13.7034 12.1573C11.4367 12.1573 9.60687 14.0511 9.60687 16.3971C9.60687 18.7431 11.4367 20.6368 13.7034 20.6368C15.9702 20.6368 17.8 18.7431 17.8 16.3971C17.8 14.0511 15.9702 12.1573 13.7034 12.1573ZM11.4276 16.397C11.4276 17.6972 12.4472 18.7525 13.7035 18.7525C14.9598 18.7525 15.9793 17.6972 15.9793 16.397C15.9793 15.0969 14.9598 14.0416 13.7035 14.0416C12.4472 14.0416 11.4276 15.0969 11.4276 16.397ZM0.503479 20.1655H7.78624V12.6281H0.503479V20.1655ZM1.95996 14.1358H6.32962V18.6582H1.95996V14.1358ZM13.0662 3.17502L10.8813 5.43622L13.0662 7.69741L15.251 5.43622L13.0662 3.17502Z" />
  </svg>
  );
});
ItemIcon.propTypes = {
  fill: PropTypes.string,
};
export const TrackTraceIcon = memo(({ fill }) => {
  const _fill = fill ? fill : 'white';
  return (
    <svg
      width='21'
      height='17'
      viewBox='0 0 21 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.5625 11.75C2.65625 12 2.90625 12.1562 3.1875 12.0938L9.9375 10.2812C10.2188 10.2188 10.375 9.9375 10.3125 9.65625L8.75 3.875C8.6875 3.59375 8.40625 3.4375 8.125 3.5L5.71875 4.15625L6.5 7.0625L4.5625 7.5625L3.78125 4.6875L1.375 5.3125C1.09375 5.40625 0.9375 5.6875 1.03125 5.9375L2.5625 11.75ZM14 0C12.9062 0 12 0.90625 12 2V11.125L1.1875 14.0625C1.0625 14.0938 0.96875 14.25 1 14.375L1.40625 15.8125C1.4375 15.9375 1.59375 16.0312 1.71875 16L14 12.625C14.0938 14.5938 15.75 16.125 17.7188 16C19.5938 15.875 21 14.1875 21 12.3125V0H14ZM17.5 14C16.6875 14 16 13.3125 16 12.5C16 11.6562 16.6875 11 17.5 11C18.3438 11 19 11.6562 19 12.5C19 13.3125 18.3438 14 17.5 14Z'
        fill={_fill}
      />
    </svg>
  );
});
TrackTraceIcon.propTypes = {
  fill: PropTypes.string,
};

export const DigitalPodIcon = memo(({ fill }) => {
  const _fill = fill ? fill : 'white';
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24 13.5227C24 13.1439 23.8909 12.803 23.7091 12.5L21.2727 8.29545C20.9091 7.68939 20.2909 7.34848 19.6364 7.34848H16.0364V3.2197C16.0364 2.99242 15.8909 2.84091 15.6727 2.84091H14.6545C14.4364 2.84091 14.2909 2.99242 14.2909 3.2197C14.2909 3.44697 14.4364 3.59848 14.6545 3.59848H15.3091V13.447H0.727273V3.59848H1.45455C1.67273 3.59848 1.81818 3.44697 1.81818 3.2197C1.81818 2.99242 1.67273 2.84091 1.45455 2.84091H0.363636C0.145455 2.84091 0 2.99242 0 3.2197C0 19.1667 0 21.4394 0 21.4394C0 21.6667 0.145455 21.8182 0.363636 21.8182H4.18182C4.03636 23.5227 5.30909 25 6.94545 25C8.58182 25 9.89091 23.5227 9.70909 21.8182H15.8909C15.7818 23.5227 17.0909 25 18.7273 25C20.3636 25 21.6727 23.5227 21.4909 21.8182H23.6364C23.8545 21.8182 24 21.6667 24 21.4394C24 14.0151 24 20.947 24 13.5227ZM19.6364 8.10606C20.0364 8.10606 20.4364 8.33333 20.6545 8.71212L23.0909 12.8788C23.2 13.0682 23.2727 13.2955 23.2727 13.5227V17.0076H16.0364V8.10606H19.6364ZM15.3091 14.2424V17.0076H0.727273V14.2424H15.3091ZM6.98182 24.2424C5.85455 24.2424 4.90909 23.2955 4.90909 22.0833C4.90909 20.9091 5.81818 19.9242 6.98182 19.9242C7.96364 19.9242 8.76364 20.6061 8.98182 21.553C9.01818 21.7045 9.05455 21.8939 9.05455 22.0833C9.01818 23.2955 8.10909 24.2424 6.98182 24.2424ZM18.7273 24.2424C17.6 24.2424 16.6545 23.2955 16.6545 22.0833C16.6545 20.9091 17.5636 19.9242 18.7273 19.9242C19.8545 19.9242 20.8 20.8712 20.8 22.0833C20.8 23.2955 19.8545 24.2424 18.7273 24.2424ZM23.2727 21.0606H21.3455C20.9455 19.9621 19.9273 19.2045 18.7273 19.2045C17.5273 19.2045 16.5091 19.9621 16.1091 21.0606H9.56364C9.16364 19.9621 8.14545 19.2045 6.94545 19.2045C5.74545 19.2045 4.72727 19.9621 4.32727 21.0606H0.727273V17.7652H23.2727V21.0606Z'
        fill={_fill}
      />
      <path
        d='M17.7455 13.5606H21.3818C21.6 13.5606 21.7455 13.4091 21.7455 13.1818C21.7455 13.0682 21.7091 12.9924 21.6364 12.9167L19.8545 9.92424C19.6727 9.62121 19.3455 9.43182 19.0182 9.43182H17.7091C17.4909 9.43182 17.3455 9.58334 17.3455 9.81061V13.1439C17.3818 13.3712 17.5273 13.5606 17.7455 13.5606ZM18.1091 10.2273H19.0545C19.1273 10.2273 19.2364 10.2652 19.2727 10.3409L20.7273 12.803H18.1091V10.2273Z'
        fill={_fill}
      />
      <path
        d='M2.65455 12.2348H13.5636C13.7818 12.2348 13.9273 12.0833 13.9273 11.8561V0.378788C13.9273 0.151515 13.7818 0 13.5636 0H2.65455C2.43636 0 2.29091 0.151515 2.29091 0.378788V11.8561C2.29091 12.0833 2.43636 12.2348 2.65455 12.2348ZM3.01818 0.757576H13.2V11.4773H3.01818V0.757576Z'
        fill={_fill}
      />
      <path
        d='M12.0727 1.47727H7.92727C7.70909 1.47727 7.56364 1.62879 7.56364 1.85606V6.13636C7.56364 6.36364 7.70909 6.51515 7.92727 6.51515H12.0727C12.2909 6.51515 12.4364 6.36364 12.4364 6.13636V1.85606C12.4364 1.62879 12.2909 1.47727 12.0727 1.47727ZM11.7091 5.75757H8.29091V2.23485H11.7091V5.75757Z'
        fill={_fill}
      />
      <path
        d='M3.92727 2.80303H6.69091C6.90909 2.80303 7.05455 2.65152 7.05455 2.42424C7.05455 2.19697 6.90909 2.04546 6.69091 2.04546H3.92727C3.70909 2.04546 3.56364 2.19697 3.56364 2.42424C3.56364 2.61364 3.70909 2.80303 3.92727 2.80303Z'
        fill={_fill}
      />
      <path
        d='M3.92727 4.65909H6.69091C6.90909 4.65909 7.05455 4.50758 7.05455 4.2803C7.05455 4.05303 6.90909 3.90152 6.69091 3.90152H3.92727C3.70909 3.90152 3.56364 4.05303 3.56364 4.2803C3.56364 4.4697 3.70909 4.65909 3.92727 4.65909Z'
        fill={_fill}
      />
      <path
        d='M3.92727 6.51515H6.69091C6.90909 6.51515 7.05455 6.36364 7.05455 6.13636C7.05455 5.90909 6.90909 5.75758 6.69091 5.75758H3.92727C3.70909 5.75758 3.56364 5.90909 3.56364 6.13636C3.56364 6.32576 3.70909 6.51515 3.92727 6.51515Z'
        fill={_fill}
      />
      <path
        d='M11.9273 7.57576H3.92727C3.70909 7.57576 3.56364 7.72727 3.56364 7.95454C3.56364 8.18182 3.70909 8.33333 3.92727 8.33333H11.9273C12.1455 8.33333 12.2909 8.18182 12.2909 7.95454C12.2909 7.76515 12.1091 7.57576 11.9273 7.57576Z'
        fill={_fill}
      />
      <path
        d='M11.9273 9.43182H3.92727C3.70909 9.43182 3.56364 9.58334 3.56364 9.81061C3.56364 10.0379 3.70909 10.1894 3.92727 10.1894H11.9273C12.1455 10.1894 12.2909 10.0379 12.2909 9.81061C12.2909 9.62121 12.1091 9.43182 11.9273 9.43182Z'
        fill={_fill}
      />
      <path
        d='M9.23636 5C9.30909 5.11364 9.38182 5.15152 9.49091 5.1894H9.52727C9.63636 5.1894 9.70909 5.15152 9.78182 5.07576L11.3818 3.44697C11.5273 3.29546 11.5273 3.06818 11.3818 2.91667C11.2364 2.76515 11.0182 2.76515 10.8727 2.91667L9.6 4.20455L9.2 3.52273C9.09091 3.33334 8.87273 3.29546 8.69091 3.40909C8.50909 3.52273 8.47273 3.75 8.58182 3.9394L9.23636 5Z'
        fill={_fill}
      />
    </svg>
  );
});
DigitalPodIcon.propTypes = {
  fill: PropTypes.string,
};
export const CutSmartIcon = memo(({ fill }) => {
  const _fill = fill ? fill : 'white';
  return (
    <svg
      width='21'
      height='17'
      viewBox='0 0 21 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 1.82143C0 0.815848 0.881836 0 1.96875 0H7.21875C8.30566 0 9.1875 0.815848 9.1875 1.82143V6.07143C9.1875 7.07701 8.30566 7.89286 7.21875 7.89286H1.96875C0.881836 7.89286 0 7.07701 0 6.07143V1.82143ZM0 10.9286C0 9.92299 0.881836 9.10714 1.96875 9.10714H7.21875C8.30566 9.10714 9.1875 9.92299 9.1875 10.9286V15.1786C9.1875 16.1842 8.30566 17 7.21875 17H1.96875C0.881836 17 0 16.1842 0 15.1786V10.9286ZM12.4688 0H19.0312C20.1182 0 21 0.815848 21 1.82143V3.03571C21 4.04129 20.1182 4.85714 19.0312 4.85714H12.4688C11.3818 4.85714 10.5 4.04129 10.5 3.03571V1.82143C10.5 0.815848 11.3818 0 12.4688 0ZM10.5 7.89286C10.5 6.88728 11.3818 6.07143 12.4688 6.07143H19.0312C20.1182 6.07143 21 6.88728 21 7.89286V9.10714C21 10.1127 20.1182 10.9286 19.0312 10.9286H12.4688C11.3818 10.9286 10.5 10.1127 10.5 9.10714V7.89286ZM12.4688 12.1429H19.0312C20.1182 12.1429 21 12.9587 21 13.9643V15.1786C21 16.1842 20.1182 17 19.0312 17H12.4688C11.3818 17 10.5 16.1842 10.5 15.1786V13.9643C10.5 12.9587 11.3818 12.1429 12.4688 12.1429Z'
        fill={_fill}
      />
    </svg>
  );
});
CutSmartIcon.propTypes = {
  fill: PropTypes.string,
};

export const CuttingGuideIcon = memo(({ fill }) => {
  const _fill = fill ? fill : 'white';
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8 7C8.53125 7 9 7.46875 9 8C9 8.5625 8.53125 9 8 9C7.4375 9 7 8.5625 7 8C7 7.46875 7.4375 7 8 7ZM5 8C5 9.65625 6.34375 11 8 11C9.65625 11 11 9.65625 11 8C11 6.34375 9.65625 5 8 5C6.34375 5 5 6.34375 5 8ZM3 7.5C3 6.40625 3.53125 5.28125 4.40625 4.4375C5.25 3.5625 6.40625 3 7.5 3C7.75 3 8 2.78125 8 2.5C8 2.25 7.75 2 7.5 2C6.0625 2 4.6875 2.71875 3.6875 3.71875C2.6875 4.71875 2 6.09375 2 7.5C2 7.78125 2.21875 8 2.5 8C2.75 8 3 7.78125 3 7.5Z'
        fill={_fill}
      />
    </svg>
  );
});
CuttingGuideIcon.propTypes = {
  fill: PropTypes.string,
};

export const ProfileIcon = memo(({ fill }) => {
  const _fill = fill ? fill : 'white';
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.375 9.9375L13.9062 9.09375C13.9688 8.75 14 8.375 14 8C14 7.625 13.9688 7.25 13.9062 6.90625L15.375 6.0625C15.5625 5.9375 15.6875 5.6875 15.5938 5.46875C15.25 4.4375 14.7188 3.5 14 2.6875C13.9062 2.59375 13.75 2.53125 13.625 2.53125C13.5312 2.53125 13.4375 2.5625 13.375 2.59375L11.9062 3.4375C11.3438 2.96875 10.7188 2.59375 10 2.34375V0.65625C10 0.4375 9.84375 0.21875 9.625 0.15625C9.09375 0.0625 8.5625 0 8 0C7.4375 0 6.9375 0.0625 6.40625 0.15625C6.1875 0.21875 6 0.4375 6 0.65625V2.34375C5.3125 2.59375 4.6875 2.96875 4.125 3.4375L2.65625 2.59375C2.59375 2.5625 2.5 2.53125 2.40625 2.53125C2.25 2.53125 2.125 2.59375 2.03125 2.6875C1.3125 3.5 0.78125 4.4375 0.4375 5.46875C0.34375 5.6875 0.46875 5.9375 0.65625 6.0625L2.125 6.90625C2.0625 7.25 2 7.625 2 8C2 8.375 2.0625 8.75 2.125 9.09375L0.65625 9.9375C0.46875 10.0625 0.34375 10.3125 0.4375 10.5312C0.78125 11.5625 1.3125 12.5 2.03125 13.3125C2.125 13.4062 2.25 13.4688 2.40625 13.4688C2.5 13.4688 2.59375 13.4375 2.65625 13.4062L4.125 12.5625C4.6875 13.0312 5.3125 13.4062 6 13.6562V15.3438C6 15.5625 6.1875 15.7812 6.40625 15.8438C6.9375 15.9375 7.46875 16 8 16C8.53125 16 9.09375 15.9375 9.625 15.8438C9.84375 15.7812 10 15.5625 10 15.3438V13.6562C10.7188 13.4062 11.3438 13.0312 11.9062 12.5625L13.375 13.4062C13.4375 13.4375 13.5312 13.4688 13.625 13.4688C13.75 13.4688 13.9062 13.4062 14 13.3125C14.7188 12.5 15.25 11.5625 15.5938 10.5312C15.6875 10.3125 15.5625 10.0625 15.375 9.9375ZM8 10.5C6.625 10.5 5.5 9.375 5.5 8C5.5 6.625 6.625 5.5 8 5.5C9.375 5.5 10.5 6.625 10.5 8C10.5 9.375 9.40625 10.5 8 10.5Z'
        fill={_fill}
      />
    </svg>
  );
});
ProfileIcon.propTypes = {
  fill: PropTypes.string,
};
export const CloseMenuIcon = memo(({ fill }) => {
  if (!fill || fill === '') {
    fill = '#fff';
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill={fill}
        fillRule='evenodd'
        d='M3 6v2h13V6H3zm10 7H3v-2h10v2zm3 5H3v-2h13v2zm1.42-6L21 15.59 19.59 17l-5-5 5-5L21 8.41 17.42 12z'
        clipRule='evenodd'
      ></path>
    </svg>
  );
});

CloseMenuIcon.propTypes = {
  fill: PropTypes.string,
};

export const CancelIcon = memo(({ fill }) => {
  if (!fill || fill === '') {
    fill = '#000';
  }

  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 1.87002L12.59 0.460022L7 6.05002L1.41 0.460022L0 1.87002L5.59 7.46002L0 13.05L1.41 14.46L7 8.87002L12.59 14.46L14 13.05L8.41 7.46002L14 1.87002Z'
        fill={fill}
      />
    </svg>
  );
});
CancelIcon.propTypes = {
  fill: PropTypes.string,
};

export const OrdersIcon = memo(({ fill }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='33'
    height='32'
    fill='none'
    viewBox='0 0 33 32'
  >
    <path
      fill={fill ? fill : '#fff'}
      fillRule='evenodd'
      d='M8.569 8.178h10.666a.711.711 0 010 1.422H8.57a.711.711 0 010-1.422zm15.783 10.871a.723.723 0 00-1.06 0l-3.346 3.524-1.287-1.355a.723.723 0 00-1.06 0 .82.82 0 000 1.116l1.817 1.914a.729.729 0 00.53.23.728.728 0 00.53-.23l3.876-4.083a.819.819 0 000-1.116zm-5.117-6.96H8.57a.711.711 0 000 1.422h10.666a.711.711 0 100-1.422zM8.57 15.645h6.044a.711.711 0 110 1.422H8.569a.711.711 0 010-1.422zm6.044 3.555H8.569a.711.711 0 000 1.422h6.044a.711.711 0 100-1.422z'
      clipRule='evenodd'
    ></path>
  </svg>
));

export const OrderProgressIcon = memo(({ fill }) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.564702 0.000377117C0.412486 0.00190475 0.267137 0.064282 0.160992 0.173502C0.0551 0.282727 -0.00293528 0.429631 0.000114398 0.58188V31.4318C0.00113259 31.7468 0.257461 32.0011 0.572333 31.9998H31.4171C31.5703 32.0034 31.7187 31.9448 31.8284 31.8374C31.9381 31.7302 32 31.5831 32 31.4295C32 31.276 31.9381 31.1291 31.8284 31.0219C31.7187 30.9145 31.5703 30.8559 31.4171 30.8592H1.14122V0.581541C1.14427 0.427252 1.08445 0.278315 0.975758 0.168581C0.867064 0.0591023 0.718666 -0.00174764 0.56441 3.82297e-05L0.564702 0.000377117ZM27.7635 15.4847H27.7633C27.748 15.4857 27.7327 15.487 27.7177 15.4892H22.8177C22.5077 15.4961 22.26 15.7494 22.26 16.0596C22.26 16.3697 22.5077 16.623 22.8177 16.6299H26.418L18.4915 24.6708L12.3164 20.2754C12.1247 20.1389 11.8691 20.1341 11.6726 20.2632C11.6568 20.2736 11.6416 20.2845 11.6271 20.2965H11.6258L4.02832 26.5353C3.82239 26.6888 3.74424 26.961 3.83766 27.2003C3.93083 27.4393 4.17264 27.5868 4.42821 27.56C4.46588 27.556 4.5033 27.5483 4.53944 27.5369C4.55752 27.5313 4.57533 27.5249 4.5929 27.5178C4.61046 27.5101 4.62752 27.5015 4.64406 27.492C4.66086 27.4834 4.67741 27.4737 4.69319 27.4633C4.71381 27.4485 4.73366 27.4325 4.75225 27.4154L12.0135 21.455L18.2221 25.8794L18.2219 25.8792C18.4504 26.0442 18.7653 26.0172 18.9626 25.8158L27.2078 17.4515V21.0205C27.2045 21.174 27.263 21.3222 27.3702 21.432C27.4776 21.5417 27.6245 21.6036 27.778 21.6036C27.9315 21.6036 28.0783 21.5417 28.1858 21.432C28.2929 21.3222 28.3517 21.174 28.3482 21.0205V16.1362C28.3723 15.9687 28.3212 15.7994 28.2082 15.6736C28.0954 15.5478 27.9325 15.4786 27.7635 15.4845L27.7635 15.4847Z'
      fill={fill ? fill : '#fff'}
    />
  </svg>
));

export const OrgIcon = memo(({ fill }) => (
  <div style={{ padding: '4px' }}>
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.2707 4.57522C9.38396 4.25807 8.73783 3.37793 8.73783 2.35362C8.73783 1.06376 9.76026 0 11 0C12.2397 0 13.2622 1.06376 13.2622 2.35362C13.2622 3.37793 12.616 4.25807 11.7293 4.57522V8.0442C12.8377 8.2703 13.7756 9.00173 14.3123 9.9972L17.4802 8.92738C17.4977 7.95163 18.1045 7.03962 19.0423 6.72247H19.0452C20.2237 6.3264 21.5072 7.00776 21.8908 8.23388C22.2729 9.46001 21.6195 10.7984 20.441 11.1975C19.5032 11.5132 18.4983 11.1475 17.9324 10.369L14.7659 11.4388C14.7849 11.5951 14.7951 11.7545 14.7951 11.9153C14.7951 12.9487 14.4071 13.8941 13.7741 14.5982L15.7358 17.4056C16.0684 17.2994 16.4199 17.2766 16.7568 17.3327C17.4146 17.4799 17.8974 17.7895 18.2328 18.269C18.9606 19.3131 18.7345 20.7972 17.7311 21.5559C16.7276 22.3131 15.3012 22.0779 14.5719 21.0339C13.9943 20.2053 14.0177 19.0991 14.5544 18.2979L12.5956 15.4935C12.1114 15.7303 11.5688 15.8623 11 15.8623C10.4312 15.8623 9.88861 15.7303 9.40438 15.4935L7.44558 18.2979C7.98232 19.0991 8.00565 20.2053 7.42808 21.0339C6.69882 22.0779 5.27238 22.3131 4.26892 21.5559C3.26545 20.7972 3.03938 19.3131 3.76718 18.269C4.13181 17.747 4.67001 17.4268 5.24321 17.3327C5.61659 17.2918 5.95497 17.3176 6.26126 17.4086L8.22589 14.5982C7.59289 13.8941 7.20492 12.9487 7.20492 11.9153C7.20492 11.7545 7.21513 11.5951 7.23409 11.4388L4.06764 10.369C3.50173 11.1475 2.49681 11.5132 1.55898 11.1975C0.380492 10.7984 -0.272926 9.46001 0.109207 8.23388C0.492798 7.00776 1.7763 6.3264 2.95479 6.72247H2.9577C3.89553 7.03962 4.50228 7.95163 4.51978 8.92738L7.68769 9.9972C8.22443 9.00173 9.16372 8.2703 10.2707 8.0442V4.57522V4.57522Z'
        fill={fill ? fill : '#fff'}
      />
    </svg>
  </div>
));

export const SideMenuCollapseIcon = memo(({ fill, onClick }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 6V8H16V6H3ZM13 13H3V11H13V13ZM16 18H3V16H16V18ZM17.42 12L21 15.59L19.59 17L14.59 12L19.59 7L21 8.41L17.42 12Z'
        fill={fill}
      />
    </svg>
  );
});

SideMenuCollapseIcon.propTypes = {
  fill: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export const PaginationPrev = ({ disabled }) => {
  const fill = disabled === true ? '#b8b8b8' : '#323232';
  return (
    <svg
      width='6'
      height='10'
      viewBox='0 0 6 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.46875 8.82812C5.66406 8.67188 5.68359 8.35938 5.50781 8.18359L2.57812 5L5.50781 1.81641C5.68359 1.64062 5.66406 1.32812 5.46875 1.17188L5 0.742188C4.80469 0.566406 4.51172 0.585938 4.33594 0.78125L0.722656 4.72656C0.585938 4.88281 0.585938 5.11719 0.722656 5.27344L4.33594 9.21875C4.51172 9.41406 4.80469 9.43359 5 9.25781L5.46875 8.82812Z'
        fill={fill}
      />
    </svg>
  );
};
PaginationPrev.propTypes = {
  disabled: PropTypes.bool,
};
export const PaginationNext = ({ disabled }) => {
  const fill = disabled === true ? '#b8b8b8' : '#323232';
  return (
    <svg
      width='6'
      height='10'
      viewBox='0 0 6 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.53125 1.17188C0.335938 1.32812 0.316406 1.64062 0.492188 1.81641L3.42188 5L0.492188 8.18359C0.316406 8.35938 0.335938 8.67188 0.53125 8.82812L1 9.25781C1.19531 9.43359 1.48828 9.41406 1.66406 9.21875L5.27734 5.27344C5.41406 5.11719 5.41406 4.88281 5.27734 4.72656L1.66406 0.78125C1.48828 0.585938 1.19531 0.566406 1 0.742188L0.53125 1.17188Z'
        fill={fill}
      />
    </svg>
  );
};
PaginationNext.propTypes = {
  disabled: PropTypes.bool,
};

export const ExpandLess = () => {
  const fill = '#323232';
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.645 17.9783L14 12.6349L19.355 17.9783L21 16.3333L14 9.33327L7 16.3333L8.645 17.9783Z'
        fill={fill}
      />
    </svg>
  );
};
ExpandLess.propTypes = {};
export const ExpandMore = ({ fill, opacity }) => {
  const _fill = fill ? fill : '#323232';
  const _opacity = opacity ? opacity : 1;
  return (
    <svg
      width='28'
      height='29'
      viewBox='0 0 28 29'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity={_opacity}>
        <path
          d='M19.355 10.5217L14 15.8651L8.645 10.5217L7 12.1667L14 19.1667L21 12.1667L19.355 10.5217Z'
          fill={_fill}
        />
      </g>
    </svg>
  );
};
ExpandMore.propTypes = {
  fill: PropTypes.string,
  opacity: PropTypes.number,
};

export const NumberDecrease = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 0.5C3.84375 0.5 0.5 3.84375 0.5 8C0.5 12.1562 3.84375 15.5 8 15.5C12.1562 15.5 15.5 12.1562 15.5 8C15.5 3.84375 12.1562 0.5 8 0.5ZM8 14C4.6875 14 2 11.3125 2 8C2 4.6875 4.6875 2 8 2C11.3125 2 14 4.6875 14 8C14 11.3125 11.3125 14 8 14ZM11 7.25H5C4.59375 7.25 4.25 7.59375 4.25 8C4.25 8.40625 4.59375 8.75 5 8.75H11C11.4062 8.75 11.75 8.40625 11.75 8C11.75 7.59375 11.4062 7.25 11 7.25Z'
        fill='#001714'
      />
    </svg>
  );
};
NumberDecrease.propTypes = {};

export const NumberIncrease = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 0.5C3.84375 0.5 0.5 3.84375 0.5 8C0.5 12.1562 3.84375 15.5 8 15.5C12.1562 15.5 15.5 12.1562 15.5 8C15.5 3.84375 12.1562 0.5 8 0.5ZM8 14C4.6875 14 2 11.3125 2 8C2 4.6875 4.6875 2 8 2C11.3125 2 14 4.6875 14 8C14 11.3125 11.3125 14 8 14ZM11 7.25H8.75V5C8.75 4.59375 8.40625 4.25 8 4.25C7.59375 4.25 7.25 4.59375 7.25 5V7.25H5C4.59375 7.25 4.25 7.59375 4.25 8C4.25 8.40625 4.59375 8.75 5 8.75H7.25V11C7.25 11.4062 7.59375 11.75 8 11.75C8.40625 11.75 8.75 11.4062 8.75 11V8.75H11C11.4062 8.75 11.75 8.40625 11.75 8C11.75 7.59375 11.4062 7.25 11 7.25Z'
        fill='#001714'
      />
    </svg>
  );
};
NumberIncrease.propTypes = {};
export const RefreshIcon = ({ fill, opacity, onClick }) => {
  const _fill = fill ? fill : 'black';
  return (
    <div className='standard-icon-wrp' onClick={onClick}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity='0.365932'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12 6C8.69 6 6 8.69 6 12H9L5 16L1 12H4C4 7.58 7.58 4 12 4C13.57 4 15.03 4.46 16.26 5.24L14.8 6.7C13.97 6.25 13.01 6 12 6ZM15 12L19 8L23 12H20C20 16.42 16.42 20 12 20C10.43 20 8.97 19.54 7.74 18.76L9.2 17.3C10.03 17.75 10.99 18 12 18C15.31 18 18 15.31 18 12H15Z'
            fill={_fill}
          />
        </g>
      </svg>
    </div>
  );
};
RefreshIcon.propTypes = {
  fill: PropTypes.string,
  opacity: PropTypes.number,
};
export const MenuChevronUpIcon = ({ fill }) => {
  return (
    <svg
      width='16'
      height='10'
      viewBox='0 0 16 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.875 8.75C2.125 9.0625 2.625 9.09375 2.90625 8.8125L8 4.125L13.0938 8.8125C13.375 9.09375 13.875 9.0625 14.125 8.75L14.8125 8C15.0938 7.6875 15.0625 7.21875 14.75 6.9375L8.4375 1.15625C8.1875 0.9375 7.8125 0.9375 7.5625 1.15625L1.25 6.9375C0.9375 7.21875 0.90625 7.6875 1.1875 8L1.875 8.75Z'
        fill={fill ? fill : 'white'}
      />
    </svg>
  );
};

export const MenuChevronDownIcon = memo(({ fill }) => {
  return (
    <svg
      width='16'
      height='10'
      viewBox='0 0 16 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.125 1.25C13.875 0.9375 13.375 0.90625 13.0938 1.1875L8 5.875L2.90625 1.1875C2.625 0.90625 2.125 0.9375 1.875 1.25L1.1875 2C0.90625 2.3125 0.9375 2.78125 1.25 3.0625L7.5625 8.84375C7.8125 9.0625 8.1875 9.0625 8.4375 8.84375L14.75 3.0625C15.0625 2.78125 15.0938 2.3125 14.8125 2L14.125 1.25Z'
        fill={fill ? fill : 'white'}
      />
    </svg>
  );
});
export const NaadiDownloadIcon = memo(() => {
  return (
    <svg
      width='12'
      height='14'
      viewBox='0 0 12 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.28125 9.71875C5.5 9.90625 5.75 10 6 10C6.25 10 6.5 9.90625 6.71875 9.71875L10.8438 5.5625C11.0625 5.375 11.0625 5.0625 10.8438 4.84375L10.1562 4.15625C9.9375 3.9375 9.625 3.9375 9.4375 4.15625L7 6.59375V0.5C7 0.21875 6.78125 0 6.5 0H5.5C5.21875 0 5 0.21875 5 0.5V6.59375L2.5625 4.15625C2.375 3.9375 2.0625 3.9375 1.84375 4.15625L1.15625 4.84375C0.9375 5.0625 0.9375 5.375 1.15625 5.5625L5.28125 9.71875ZM0.5 14H11.5C11.7812 14 12 13.7812 12 13.5V12.5C12 12.2188 11.7812 12 11.5 12H0.5C0.21875 12 0 12.2188 0 12.5V13.5C0 13.7812 0.21875 14 0.5 14Z'
        fill='#006EED'
      />
    </svg>
  );
});

export const NaadiRetryIcon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.5 0.5C13.9375 0.5 13.5 0.9375 13.5 1.5V2.90625C12.0938 1.40625 10.125 0.5 8 0.5C3.875 0.5 0.5 3.875 0.5 8C0.5 12.125 3.875 15.5 8 15.5C9.625 15.5 11.1875 14.9688 12.5 14C13.0625 13.5938 13.1562 12.8125 12.75 12.25C12.3438 11.6875 11.5625 11.5938 11 12C10.125 12.6562 9.09375 13 8 13C5.25 13 3 10.75 3 8C3 5.25 5.25 3 8 3C9.59375 3 11.0625 3.75 12 5H10C9.4375 5 9 5.4375 9 6C9 6.5625 9.4375 7 10 7H14.5C14.7812 7 15.0312 6.875 15.2188 6.71875C15.4062 6.5625 15.5 6.28125 15.5 6V1.5C15.5 0.9375 15.0625 0.5 14.5 0.5Z'
        fill='#006EED'
      />
    </svg>
  );
};

export const NaadiDeleteIcon = () => {
  return (
    <svg
      width='14'
      height='16'
      viewBox='0 0 14 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 14.5C1 15.3125 1.6875 16 2.5 16H11.5C12.3125 16 13 15.3125 13 14.5V4H1V14.5ZM9.5 6.5C9.5 6.21875 9.71875 6 10 6C10.2812 6 10.5 6.21875 10.5 6.5V13.5C10.5 13.7812 10.2812 14 10 14C9.71875 14 9.5 13.7812 9.5 13.5V6.5ZM6.5 6.5C6.5 6.21875 6.71875 6 7 6C7.28125 6 7.5 6.21875 7.5 6.5V13.5C7.5 13.7812 7.28125 14 7 14C6.71875 14 6.5 13.7812 6.5 13.5V6.5ZM3.5 6.5C3.5 6.21875 3.71875 6 4 6C4.28125 6 4.5 6.21875 4.5 6.5V13.5C4.5 13.7812 4.28125 14 4 14C3.71875 14 3.5 13.7812 3.5 13.5V6.5ZM13.5 1H10L9.625 0.28125C9.5625 0.09375 9.375 0 9.1875 0H4.8125C4.625 0 4.4375 0.09375 4.375 0.28125L4 1H0.5C0.21875 1 0 1.21875 0 1.5V2.5C0 2.78125 0.21875 3 0.5 3H13.5C13.7812 3 14 2.78125 14 2.5V1.5C14 1.21875 13.7812 1 13.5 1Z'
        fill='#006EED'
      />
    </svg>
  );
};
