import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import { trackTraceMultiScan } from '../../../helpers/tracktrace/orderItemHelper';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@naadi/framework';
import {
  toastError,
  toastSuccess,
} from '../../../helpers/packing/packingHelper';
import { cloneDeep, isEqual } from 'lodash';
import { handlePrinting } from '../../../components/tracktrace/container';
import {
  offlinePrinter,
  PrinterSelection,
} from '../../../views/item/labelprint/PrintLabelSelector';
export const getGroupCompletion = group => {
  if (!group) {
    return 0;
  }
  const total = group.pending + group.rejected + group.success;
  if (total === 0) {
    return 0;
  }
  return Math.round(
    (group.success * 100) / (group.success + group.pending + group.rejected),
  );
};
/* eslint-disable sonarjs/cognitive-complexity */
export const GroupItemModal = ({
  group,
  showModal,
  onCloseCb,
  showRejectModalCb,
  scannedItems,
  appendScannedItemsCb,
  syncCb,
  isMultiCheck,
  setIsMultiCheck,
}) => {
  const dispatch = useDispatch();
  const [completion, setCompletion] = useState(0);
  const [items, setItems] = useState([]);
  const [multiCheck, setMultiCheck] = useState([]);
  const [search, setSearch] = useState('');
  const filteredItems = items.filter(v =>
    v.code.toLowerCase().includes(search.toLowerCase()),
  );
  const { enable_item_print } = useSelector(
    state => state.stationstate.current_station,
  );
  const { printers } = useSelector(state => state.connectedPrinters);
  const [availablePrinters, setAvailablePrinters] = useState([]);
  useEffect(() => {
    const _offlinePrinter = [cloneDeep(offlinePrinter)];
    const _printers = printers.filter(
      val => val.printer_id !== 'OFFLINE_PDF_PRINTER',
    );
    _offlinePrinter.selected =
      printers.filter(
        val =>
          val.printer_id === 'OFFLINE_PDF_PRINTER' && val.selected === true,
      ).length > 0;
    const _availablePrinters = [..._offlinePrinter, ..._printers];
    if (!isEqual(availablePrinters, _availablePrinters)) {
      setAvailablePrinters(_availablePrinters);
    }
  }, [printers, setAvailablePrinters, availablePrinters]);
  useEffect(() => {
    setCompletion(getGroupCompletion(group));
    if (!group || !group.items) {
      setItems([]);
      return;
    }
    const _items = Object.keys(group.items)
      .map(key => group.items[`${key}`])
      .filter(val => val.status !== 'NO_OPERATION');
    _items.sort((a, b) => {
      return a.code.localeCompare(b.code);
    });
    setItems(_items);
  }, [group, setCompletion, setItems]);

  const handleRejectAll = useCallback(
    async items => {
      await showRejectModalCb(null, true, items);
    },
    [showRejectModalCb],
  );
  const handleScanAll = useCallback(
    async items => {
      try {
        dispatch(actions.busy.add('TRACK_TRACE_MANUAL_CHECK'));
        const multiScanResp = await trackTraceMultiScan(
          items,
          true,
          { rejected: false },
          [],
          true,
          dispatch,
          scannedItems,
          appendScannedItemsCb,
          syncCb,
        );
        if (multiScanResp.success === false) {
          toastError(
            multiScanResp.error.map((val, i) => (
              <div key={i}>{`${val.code} - ${val.err}`}</div>
            )),
          );
        } else if (multiScanResp.success === true) {
          toastSuccess(`Updated ${multiScanResp.successCount} items`);
        }
      } finally {
        dispatch(actions.busy.remove('TRACK_TRACE_MANUAL_CHECK'));
      }
      setIsMultiCheck(false);
    },
    [setIsMultiCheck, dispatch, scannedItems, appendScannedItemsCb, syncCb],
  );

  const handlePrintAll = useCallback(async () => {
    try {
      dispatch(actions.busy.add('TRACK_TRACE_MANUAL_PRINT_CHECK'));
      const orderItems = [];
      items.forEach(b => {
        orderItems.push({
          uuid: b.uuid,
          selected: true,
        });
      });
      if (orderItems.length === 0) {
        return;
      }
      return await handlePrinting(printers, dispatch, orderItems);
    } finally {
      dispatch(actions.busy.remove('TRACK_TRACE_MANUAL_PRINT_CHECK'));
    }
  }, [dispatch, printers, items]);
  const onCloseFnc = () => {
    onCloseCb();
    setIsMultiCheck(false);
  };
  return (
    <Fragment>
      <Modal
        size={'lg'}
        show={showModal}
        onHide={onCloseFnc}
        dialogClassName='track-trace-dialog'
      >
        <Modal.Header closeButton style={{ borderBottom: '0px' }}>
          {enable_item_print && (
            <div
              className='d-flex'
              style={{
                color: '#006EED',
                cursor: 'pointer',
                width: '-webkit-fill-available',
              }}
            >
              <div
                onClick={handlePrintAll}
                className='col-10'
                style={{ paddingTop: '7px', textAlign: 'right' }}
              >
                Print all Label
              </div>
              <div className='text-primary'>
                <PrinterSelection
                  printers={availablePrinters}
                  module={'Track-N-trace-group-select'}
                />
              </div>
            </div>
          )}
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: '0px',
            paddingRight: '16px',
            paddingLeft: '16px',
          }}
        >
          {group && (
            <div>
              <div>
                <div className='d-flex justify-content-between'>
                  <div className='font-weight-bold'>
                    {group ? group.group : ''}
                  </div>
                  <div className='track-trace-group-card-status-group-count text-right'>
                    {group.success}/
                    {group.success + group.pending + group.rejected}
                  </div>
                </div>
                <div className='track-trace-group-card-status-completion'>
                  <div
                    className='percentage'
                    style={{ width: completion + '%' }}
                  ></div>
                </div>
              </div>
              <div
                style={{ borderBottom: '1px solid gray' }}
                className='d-flex justify-content-between pt-3 pb-2'
              >
                <div className='d-flex input'>
                  <input
                    type='checkbox'
                    className='main-box-checkbox cursor-pointer'
                    value={true}
                    disabled={false}
                    onChange={() => {
                      setMultiCheck(() => {
                        const updatedMap = cloneDeep(items);
                        updatedMap.forEach(v => (v.check = !isMultiCheck));
                        setItems(updatedMap);
                        setIsMultiCheck(!isMultiCheck);
                        return updatedMap.filter(
                          v => v.check && v.check === true,
                        );
                      });
                    }}
                    checked={isMultiCheck}
                  />
                  <p className='ps-4 my-auto'>Select All</p>
                </div>
                <div
                  style={{ backgroundColor: '#FAFAFA' }}
                  className='ms-5 p-2 ps-5 w-50 my-auto align-items-center border border-2 rounded-pill'
                >
                  <input
                    type='search'
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    placeholder='Tap here to search'
                    className='form-control '
                  />
                </div>
              </div>

              {filteredItems.map(item => {
                return (
                  <div
                    key={item.uuid}
                    className='track-trace-group-item-input-rows'
                  >
                    <div className='d-flex justify-content-end'>
                      <div className='checkbox'>
                        <input
                          type='checkbox'
                          className='main-box-checkbox cursor-pointer'
                          value={true}
                          disabled={false}
                          onChange={() => {
                            setMultiCheck(() => {
                              const updatedMap = cloneDeep(filteredItems);
                              updatedMap.forEach(v => {
                                if (v.uuid === item.uuid) {
                                  v.check = !v.check;
                                }
                              });
                              setItems(updatedMap);
                              return updatedMap.filter(
                                v => v.check && v.check === true,
                              );
                            });
                          }}
                          checked={item.check === true}
                        />
                      </div>
                      <div
                        className={`col ${
                          item.status === 'SUCCESS' ? 'font-bold' : ''
                        }`}
                      >
                        {item.code}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <div
              className='btn btn-outline-primary'
              onClick={() => handleRejectAll(multiCheck)}
            >
              Reject
            </div>
            <div
              className='btn btn-outline-primary ms-2'
              onClick={() => handleScanAll(multiCheck)}
            >
              Scan
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

GroupItemModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onCloseCb: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  scannedItems: PropTypes.array.isRequired,
  appendScannedItemsCb: PropTypes.func.isRequired,
};

export default GroupItemModal;
