import React, { useState, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import qs from 'qs';
import Dropdown from 'react-bootstrap/Dropdown';
import { ChevronDownIcon, SearchIcon } from '../../listColumnFilter/icon';
import {
  setPageFilterParams,
  formatFiltersObj,
  fetchCustomers,
  FETCHING_CUSTOMERS,
} from '../../../../store/order/ordersList/actions';
import { cloneDeep, union } from 'lodash';
import Form from 'react-bootstrap/Form';

const filterableParams = {
  code_term: {
    name: 'Order',
    key: 'code_term',
    type: 'array',
  },
  parent_order_id_term: {
    name: 'Parent Order',
    key: 'parent_order_id_term',
    type: 'array',
  },
  secondary_customer_term: {
    name: 'Secondary Customer',
    key: 'secondary_customer_term',
    type: 'array',
  },
};

// eslint-disable-next-line sonarjs/cognitive-complexity
const PageColumnFilter = () => {
  //eslint-disable-line
  const input = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [selected_column, setColumnToBeFiltered] = useState('');
  const [is_dropdown_open, setDropdownState] = useState(false);
  const busy = useSelector(state => state.busy);
  const is_fetching_entities = busy.includes(FETCHING_CUSTOMERS);
  const entities = useSelector(state => state.ordersList.entities);
  const filter_params = useSelector(
    state => state.ordersList.filterParams || {},
  );
  const CustomToggle = React.forwardRef(
    (
      { children, onClick },
      ref, //eslint-disable-line
    ) => (
      <div //eslint-disable-line
        className={`title-sm cursor-pointer page-size-display ${
          is_dropdown_open ? 'active' : ''
        }`}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ),
  );

  const handleSearch = useCallback(
    term => {
      dispatch(fetchCustomers(dispatch, { term, deleted: [false] }));
    },
    [dispatch],
  );
  const onDropdownSelect = useCallback(
    data => {
      setColumnToBeFiltered(data);
      dispatch(setPageFilterParams({ page_search_param: data }));
    },
    [dispatch],
  );

  const onDropdownToggle = useCallback(
    status => {
      setDropdownState(status);
    },
    [setDropdownState],
  );

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      const data = new FormData(event.target);
      const form_values = data.get(selected_column);
      const clone_filter_params = cloneDeep(filter_params);
      const type = filterableParams[`${selected_column}`].type;
      if (type === 'string') {
        dispatch(
          setPageFilterParams(
            formatFiltersObj({
              ...clone_filter_params,
              [selected_column]: form_values,
            }),
          ),
        );
        const stringified_params = qs.stringify(
          formatFiltersObj({
            ...clone_filter_params,
            [selected_column]: form_values,
          }),
        );
        history.push(`/app/orders/pssummary?${stringified_params}`);
      }
      if (type === 'array') {
        let changed_array = [];
        if (
          clone_filter_params[`${selected_column}`] &&
          clone_filter_params[`${selected_column}`].length
        ) {
          changed_array = clone_filter_params[`${selected_column}`];
        }
        const new_filters = {
          ...clone_filter_params,
          [selected_column]: union(changed_array, [form_values]),
        };
        dispatch(setPageFilterParams(formatFiltersObj(new_filters)));
        const stringified_params = qs.stringify(formatFiltersObj(new_filters));
        history.push(`/app/orders/pssummary?${stringified_params}`);
      }
      if (input) {
        input.current.value = null;
      }
    },
    [dispatch, filter_params, history, selected_column],
  );

  const onCustomerSelect = useCallback(
    data => {
      const clone_filter_params = cloneDeep(filter_params);
      let changed_array = [];
      if (
        clone_filter_params[`${selected_column}`] &&
        clone_filter_params[`${selected_column}`].length
      ) {
        changed_array = clone_filter_params[`${selected_column}`];
      }
      const new_filters = {
        ...clone_filter_params,
        [selected_column]: union(changed_array, [data.uuid]),
      };
      dispatch(setPageFilterParams(formatFiltersObj(new_filters)));
      const stringified_params = qs.stringify(formatFiltersObj(new_filters));
      history.push(`/app/orders/pssummary?${stringified_params}`);
    },
    [history, filter_params, dispatch, selected_column],
  );

  const getFilterComponent = useMemo(() => {
    const type = (filterableParams[`${selected_column}`] || {}).type;
    switch (type) {
      case 'string': {
        return (
          <div className='d-flex justify-content-center align-items-center'>
            <Form.Control
              type='text'
              className='me-3 table-search-input rounded-0'
              name={selected_column}
              placeholder={`Enter ${
                filterableParams[selected_column + ''].name
              }`}
              autoComplete='off'
              ref={input}
            />
            <SearchIcon is_active />
          </div>
        );
      }
      case 'array': {
        return (
          <div className='align-items-center d-flex justify-content-center'>
            <Form.Control
              type='text'
              className='me-3 table-search-input rounded-0'
              name={selected_column}
              placeholder={`Enter ${
                filterableParams[selected_column + ''].name
              }`}
              autoComplete='off'
              ref={input}
            />
            <SearchIcon is_active />
          </div>
        );
      }
      case 'entity': {
        return (
          <div className='align-items-center d-flex justify-content-center'>
            <AsyncTypeahead
              id='select_entity_typeahead'
              labelKey='name'
              onSearch={handleSearch}
              autoComplete='off'
              onChange={data => {
                if (data.length) {
                  onCustomerSelect(data[0]);
                }
              }}
              options={entities}
              placeholder='Enter Customer Name'
              isLoading={is_fetching_entities}
            />
            <SearchIcon is_active />
          </div>
        );
      }
      default:
        return <div></div>;
    }
  }, [
    entities,
    handleSearch,
    is_fetching_entities,
    onCustomerSelect,
    selected_column,
  ]);

  return (
    <div>
      <div className='d-flex mt-1'>
        <div className='position-relative'>
          <Dropdown
            className='page-size-selector'
            onToggle={onDropdownToggle}
            flip={'false'}
            drop={'down'}
          >
            <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
              <div>
                <span
                  className={`btn-label ${
                    selected_column ? '' : 'text-primary'
                  } pe-2`}
                >
                  {selected_column
                    ? filterableParams[`${selected_column}`].name
                    : 'Select'}
                </span>
                <ChevronDownIcon />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu align={{ sm: 'right' }}>
              {Object.values(filterableParams).map(filter_param => (
                <Dropdown.Item
                  onClick={() => onDropdownSelect(filter_param.key)}
                  active={filter_param.key === selected_column}
                  key={filter_param.key}
                  eventKey={filter_param.key}
                  className='text-uppercase'
                >
                  {filter_param.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className='ps-3'>
          <Form onSubmit={handleSubmit}>
            {filterableParams[`${selected_column}`] ? (
              getFilterComponent
            ) : (
              <SearchIcon />
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

PageColumnFilter.propTypes = {};

export default PageColumnFilter;
